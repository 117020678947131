//---------
// Indicadores nivel de servicio
.card-fill-rate {
  border-radius: 16px !important;
  box-shadow: none !important;
  height: 210px !important;
  width: 100% !important;
  border: 2px solid transparent;

  @include tablet {
    width: 100%;
    height: 180px !important;
    font-size: 14px !important;
  }

  @include phone {
    width: 100%;
    min-height: 200px !important;
    font-size: 14px !important;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .div-icon-title {
      display: flex;
      gap: 10px;
      align-items: center;

      .icon-button {
        color: $color-primary !important;
        background-color: #f3f0ff !important;
        cursor: auto !important;
        width: 35px;
        height: 35px;

        .icon {
          width: 20px;
        }
      }
    }

    .button-fill-rate {
      margin-top: 2rem;
      background-color: $color-primary !important;
      color: $white-color !important;
      font-size: $font-size-button !important;
      border-radius: 8px !important;
      font-weight: $weight-bold !important;
      padding: 2px 10px !important;

      @include tablet {
        font-size: $font-size-button-mobile !important;
      }

      @include phone {
        width: 100%;
        height: 40px;
        font-size: 14px !important;
      }
    }
  }

  @include tablet {
    height: auto;
    width: auto;
  }
}

.text-fill-rate {
  margin-bottom: 3%;
}

.card-fill-rate:hover {
  border: 2px solid $artyclick_sky_blue;
}

.text-fill {
  height: 80px !important;

  @include tablet {
    height: 55px !important;
  }

  @include phone {
    min-height: 60px !important;
    flex-grow: 1;
  }
}

.container-indicator {
  justify-content: start !important;
  @include tablet {
    justify-content: center !important;
  }
}
