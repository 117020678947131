.modal {
  &__form {
    &-select {
      border: 1px solid $grey-color !important;
      border-radius: 8px !important;
      height: 3.7rem;
      width: 22rem;
    }
  }
  &__selectIcon {
    @include tablet {
      width: 650px !important;
      top: 50% !important;
    }
    @include phone {
      width: 350px !important;
    }
  }
  &__responsive {
    @include phone {
      width: 80% !important;
    }
  }
  &__notification {
    height: 200 !important;
    width: 500 !important;
    border-radius: "12px";
    background-color: "white";
    padding: 2rem !important;
  }
  &__support{
    height: 800 !important;
    width: 800 !important;
    border-radius: "12px";
    background-color: "white";
   &__body{
    @media (min-width: 1920px) {
      gap: 56px !important;
  }
   }
  }
  &-subtitle{
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    color:#111827 !important;
    margin-top: 2rem !important;
  }
  &-title{
      line-height: 32px !important;
      font-size: 28px !important;
      font-weight: 700 !important;
      color: #553AB6 !important;
  }
  &__decision {
    height: 376 !important;
    width: 500 !important;
    border-radius: "12px";
    background-color: "white";
    padding: 1rem !important;
  }
}
.modal-notification {
  &__notification {
    height: 200 !important;
    width: 500 !important;
    border-radius: "12px";
    background-color: "white";
    padding: 2rem !important;
  }
}
.MuiDialog-paper {
  border-radius: 16px !important;
  overflow-y: hidden !important;
}

// .MuiDialog-paperScrollPaper {
//   height: 592px !important;
//   width: 749px !important ;
//   max-width: 749px !important;
// }

.permissionBox {
  border: 1px solid $text-color-disabled;
  border-radius: 15px;
  margin-top: 1%;
  padding: 20px;

  @extend .col-start;
  @include phone {
    width: 23rem !important;
  }
}

.scroll-in-y {
  @include tablet {
    overflow-y: scroll;
    scroll-behavior: smooth;
    height: 400px;
  }

  @include phone {
    overflow-y: scroll;
    scroll-behavior: smooth;
    height: 360px;
  }
}

.companiesBox {
  border: 1px solid $text-color-disabled;
  border-radius: 15px;
  margin-top: 3%;
  margin-bottom: 3%;
  padding: 1% 0% 2.5% 3%;
}

.addPermission {
  margin-top: 2.4% !important;

  width: fit-content;
  &__button {
    @extend .btn__filled__blue;
    border-radius: 8px !important;
  }

  &__delete {
    border-radius: 8px !important;
    color: $primary-color-one !important;
    background-color: rgba(85, 58, 182, 0.05) !important;
  }

  &__disabled {
    border-radius: 8px !important;
    color: $text-color-disabled !important;
    background-color: rgba(85, 58, 182, 0.05) !important;
  }
}

.modal-mapping__container {
  width: 100% !important;
  height: 100% !important;
  max-width: 1030px !important;
  box-shadow: none;
  border-radius: 22px !important;
  padding: 1rem !important;

  @include tablet {
    max-height: 1000px !important;
  }
}
.modal-title {
  white-space: normal !important;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  max-width: 100% !important;
  color: black;
  font-weight: 400;
  font-size: 16px;
}

.modal-info-title {
  @extend .heading-secondary;
  font-weight: 500 !important;
  @include tablet {
    font-size: 24px !important;
  }

  @include phone {
    font-size: $font-size-lg !important;
  }
}

.modal-editMonths {
  &__title {
    font-size: $font-size-x-large !important;
    font-weight: $weight-medium !important;
    @include tablet {
      font-size: 2.4rem !important;
    }
  }
  &__filterContainer {
    @include phone {
      width: 100% !important;
    }
  }

  &__filterField {
    padding: 1rem 1.5rem 1rem 0;
    @include phone {
      padding: 1rem 0px;
    }
  }
}
