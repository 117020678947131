.recoverPassword {
  &__wrap {
    display: flex;
    flex-direction: row;
    min-width: 100%;
    min-height: 100%;
    box-shadow: 0px 14px 14px 2px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    overflow: hidden; // Esto previene el scroll.

    @include tablet {
      align-items: center;
      min-height: 100% !important;
    }
    @media (min-width: 1920px) {
      min-height: 100% !important;
    }
  }

  &__rightSide {
    width: 50%;
    max-height: 100%;
    background: #fff !important;
    display: flex;
    align-items: start !important;
    justify-content: center;
    background-color: $background-color;
    padding: 4rem !important;

    @include tablet {
      align-self: start !important;
      padding: 10rem 5rem !important;
      width: 100%;
      height: 100%;
      border-bottom-right-radius: 0;
    }
    @include phone {
      width: 100%;
      padding: 5rem 2rem !important;
      height: 100%;
      padding: 4rem 2rem !important;
    }
    @media (min-width: 1367px) {
      padding: 8.5rem 7rem !important;
    }
  }

  &__leftSide {
    width: 50%;
    max-height: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: space-between;
    padding: 4rem;
    background-color: $login-background-color;
    background-image: url("../../assets/common/gridImage.svg");

    @include tablet {
      display: none;
    }
  }

  &__head-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: start !important;
    width: 100%;
    padding-bottom: 3rem !important;
    @include tablet {
      justify-content: space-between !important;
      padding: 0 2rem;
      padding-bottom: 18rem;
    }
    @include phone {
      padding: 0 1rem;
      padding-bottom: 6rem;
    }
    @media (min-width: 1367px) {
      padding-bottom: 4.8rem !important;
    }
  }

  &__title {
    text-align: left;
    padding-bottom: 1.6rem !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    @media (min-width: 1367px) {
      padding-bottom: 1.6rem !important;
      font-size: $font-size-x-large !important;
      line-height: 44px;
    }
    @include tablet {
      font-size: 47px !important;
      line-height: 58px;
      padding-bottom: 3rem !important;
    }
    @include phone {
      line-height: 31px;
      font-size: 32px !important;
      padding: 0 1rem 3rem 1rem !important;
    }
  }

  &__subtitle {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: $grey;
    padding-bottom: 3rem;
    line-height: 19px !important;
    @include tablet {
      line-height: 32px !important;
      font-size: 20px;
      padding-bottom: 4rem;
    }
    @include phone {
      font-size: $font-size-sm;
      line-height: 20px !important;
      padding: 0 1rem 3rem 1rem !important;
    }
    @media (min-width: 1367px) {
      font-size: $font-size-medium !important;
      line-height: 28px !important;
      font-weight: 400;
      padding-bottom: 3rem !important;
    }
  }

  &__instructions {
    text-align: left;
    font-size: $font-size-default !important;
    font-weight: 400;
    color: $very_dark_grayish_blue;
    padding-bottom: 0.8rem;
    line-height: 24px !important;
    @include tablet {
      line-height: 32px !important;
      font-size: 16px;
      padding-bottom: 4rem;
    }
    @include phone {
      font-size: $font-size-sm;
      line-height: 20px !important;
      padding-bottom: 2rem;
    }
  }

  &__form {
    padding: 1rem;
    justify-content: center !important;
    align-items: center;
    text-align: center;
    width: 80% !important;

    @media (min-width: 1920px) {
      width: 60% !important;
    }

    @include tablet {
      width: 70% !important;
    }

    @include phone {
      width: 100% !important;
    }
    &-responsive-box {
      display: flex;
      text-align: left;
    }

    &-responsive--label {
      display: none;

      text-align: left;
      width: 263px;
    }

    &-errorText {
      color: $errorColor;
      text-align: left;
      font-size: $font-size-sm;
      padding: 0 1.5rem;
      @include tablet {
        padding: 0 !important;
      }
    }

    &-input {
      &:not(:last-child) {
        margin-bottom: 1.5rem !important;
      }

      text-align: left;
      align-content: center !important;
      align-self: center !important;
      transition: all 0.2s !important;
      background-color: white !important;
      padding: 2.4rem 0.5rem !important;
      height: 42px !important;
      width: 95% !important;
      border: 1.5px solid $grey !important;
      border-radius: 8px !important;
      margin: 0.8rem 0 0.5rem 0 !important;
      font-size: $font-size-sm !important;
      width: 100% !important;
      &:focus {
        border: 1px solid $color-primary !important;
      }
      @include tablet {
        width: 100% !important;
        padding: 2.6rem 0.5rem !important;
      }
      @include phone {
        width: 100% !important;
        margin: 2rem 0 0rem 0 !important;
        padding: 0rem 0.2rem !important;
        margin: 1.8rem 0 0.5rem 1 !important;
      }
      @media (min-width: 1920px) {
        height: 45px;
        width: 90%;
        margin: 1.8rem 0 0rem 0 !important;
        padding-bottom: 3rem !important;
      }

      &--error {
        @extend .login__form-input;
        border-color: $errorColor !important;
      }
      &--focus {
        @extend .login__form-input;
        border-color: #553ab6 !important;
      }
    }

    &-backText {
      font-family: $font-family-text;
      font-weight: $weight-bold;
      line-height: $line-height-small;
      font-size: $font-size-default !important;
      color: $text-info_blue !important;
      @include tablet {
        font-size: $font-size-sm;
      }
    }

    &-button {
      width: 69%;
      height: 43px;
      background: $moderate_blue;
      padding: 6px 16px;
      margin: 0rem;
      font-family: $font-family-text;
      color: white;
      font-size: 14px;
      font-weight: bold;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.4s;
      border: 2px solid transparent;
      @include tablet {
        height: 48px !important;
        width: 70% !important;
        margin: 1rem;
      }
      @include phone {
        width: 85% !important;
        height: 42 !important;
        margin: 0.5rem 0 !important;
      }
      @media (min-width: 1920px) {
        width: 64% !important;
      }
      &--disabled {
        @extend .recoverPassword__form-button;
        background: $grey-disable;
        color: $grey;
        cursor: default;
      }
    }
  }
}

.recoverPasswordImage {
  display: flex;
  flex-direction: column !important;
  justify-self: end !important;
  margin-top: 1.5rem;
  width: 75%;

  &_title {
    font-family: $font-family-text !important;
    text-align: center;
    color: white !important;
    padding-bottom: 5rem !important;
    font-size: 28px;
    font-weight: 700;
    @media (min-width: 1367px) {
      font-size: 42px !important;
    }
  }

  &_title-container {
    @extend .subHeading-primary;
    height: 100%;
    align-items: end !important;
    text-align: center;
    width: 30rem;
    @media (min-width: 1920px) {
      width: 50rem;
    }
  }

  &_instruction {
    @extend .default-text_black__bold;
    margin-top: 1.5rem;
  }

  &_img {
    width: 100%;
    z-index: 2;
    @include tablet {
      width: 65%;
    }

    @include phone {
      width: 100%;
    }
    @media (min-width: 1920px) {
      width: 100%;
    }

    @media (max-height: 768px) {
      width: 80%;
    }
  }

  &_img-container {
    display: flex;
    width: 100%;
  }
}

.back-containerPassword {
  padding: 0 !important;

  @include phone {
    margin-top: 5% !important;
    margin-bottom: 2% !important;
    padding: 0 1rem 1rem 1rem !important;
  }
}
