.generalcat-container {
  justify-content: center;
  align-items: flex-start;

  @include phone {
    align-items: flex-end !important;
  }
}

.titlecat-container {
  @include phone {
    align-items: center !important;
    justify-content: center !important;
  }
}
.titlecat {
  @include phone {
    font-size: $font-size-md !important;
    align-items: center;
    justify-content: center;
    text-align: center !important;
  }
}

.template-container {
  display: flex;
  align-items: center !important;
  justify-content: flex-end;
  gap: 2%;
  margin-bottom: 1.5% !important;

  @include tablet {
    justify-content: center;
  }
}

.guide-aligment {
  align-items: flex-end;

  &__no-catalogues {
    align-items: flex-start;
  }

  @include tablet {
    justify-content: center !important;
    align-items: center !important;
  }

  @include phone {
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
  }
}

.template-button {
  @include tablet {
    margin-top: 3% !important;
    margin-bottom: 1.5% !important;
    width: 100%;
  }
}

.prev-refresh-container {
  display: flex;
  align-items: center !important;
  justify-content: flex-end;
  gap: 2%;
  margin-top: 1.5% !important;

  @include tablet {
    margin-top: 3% !important;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.refresh-other {
  @extend .btn__outlined__blue;
  @include tablet {
    width: 75% !important;
  }

  @include phone {
    width: 100% !important;
  }
}

.btn-template {
  @extend .btn__outlined__blue;
  width: 100% !important;
  @include tablet {
    width: 1000px !important;
  }

  @include phone {
    width: 100% !important;
    min-width: none;
  }
}

.prev-refresh-buttons {
  width: 144px;
  height: 48px;
  @include tablet {
    width: 49%;
  }
  @include phone {
    margin-top: 4% !important;
    width: 100%;
  }
  &__tree {
    width: 279px;
    height: 48px;
    @include tablet {
      width: 49%;
    }
    @include phone {
      margin-top: 4% !important;
      width: 100%;
    }
  }
}

.table-title-container {
  @extend .heading;
  color: $color-primary;
  font-size: 20px;
  margin-top: 4% !important;

  @include phone {
    margin-top: 15% !important;
    margin-bottom: 4% !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

.chips-container {
  display: flex;
  align-items: center !important;
  justify-content: flex-start;
  gap: 2%;
  margin-top: 1% !important;
}

.formfilt-container {
  justify-content: flex-start;
  align-items: center;

  @include phone {
    justify-content: center !important;
  }
}

.buttonfilt-container {
  justify-content: flex-end;
  align-items: center;

  @include tablet {
    padding-top: 5%;
  }

  @include phone {
    justify-content: center !important;
  }
}

.filter-buttons {
  @include phone {
    width: 100%;
  }
}

.drag-label {
  @extend .label-Primary;

  @include tablet {
    font-size: $font-size-default;
  }
}

.just-align {
  font-weight: bold !important;
  text-align: center !important;
}

.preview-modal {
  &__general-container {
    min-height: 40rem;
    max-height: 40rem;
    min-width: 73rem;
    max-width: 73rem;
    overflow-y: scroll;
    scrollbar-color: $color-primary !important;
    border: 2px solid $very_light_gray;
    border-radius: 10px;
    padding: 2%;
    margin-top: 2%;

    @include tablet {
      min-width: 70rem;
      max-width: 70rem;
    }
    @include phone {
      min-width: 30rem;
      max-width: 30rem;
    }
  }
  &__firstLevel {
    @extend .label-Primary;
    color: $color-primary !important;
    font-weight: 400 !important;
    font-size: $font-size-default;
    max-height: none !important;
  }

  &__secondLevel {
    @extend .preview-modal__firstLevel;
    color: $text-color !important;
    border-left: 1px dashed #5572b7;
    line-height: 24px;
  }

  &__arrows {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-primary !important;
  }

  &__noCatalogue {
    @extend .default-text;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    color: $grey;
  }
}

.accordions {
  border: 0 !important;
}

.download-explanation {
  @extend .label-Primary;
  color: $blue_500 !important;
  font-weight: 400 !important;
  text-align: right !important;
  @include tablet {
    text-align: left !important;
  }

  @include phone {
    text-align: left !important;
  }
  &__externalFiles {
    @extend .label-Primary;
    font-size: 15px !important;
    color: $blue_500 !important;
    font-weight: 400 !important;
    text-align: right !important;
    @include tablet {
      padding-left: 2px !important;
      font-size: 11.5px !important;
      text-align: left !important;
    }
    @include phone {
      padding-left: 1.5rem !important;
      padding-top: 2rem !important;
      font-size: 10.5px !important;
      text-align: center !important;
      line-height: 19px;
    }
  }
  &__catalogue {
    @extend .label-Primary;
    color: $blue_500 !important;
    font-weight: 400 !important;
    text-align: right !important;
    @include tablet {
      text-align: left !important;
    }

    @include phone {
      text-align: left !important;
    }
  }
}
.download-explanation-trader {
  @extend .label-Primary;
  color: #5572b7 !important;
  font-weight: 400 !important;
  text-align: left !important;
  @include tablet {
    text-align: left !important;
  }

  @include phone {
    text-align: left !important;
  }
}

.col-start {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
}

.col-between {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
}

.col-end {
  display: flex;
  justify-content: flex-end !important;
  align-items: center !important;
}

.col-flex {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.downloadBox {
  @extend .col-flex;
  background: linear-gradient(
      0deg,
      rgba(255, 211, 125, 0.12),
      rgba(255, 211, 125, 0.12)
    ),
    #ffffff !important;
  border-radius: 16px;
  border: 1px solid $tertiary-color-two;
  min-height: 15.2rem !important;

  &__title-box {
    margin-bottom: 2% !important;

    @include tablet {
      margin-bottom: 3% !important;
    }

    @include phone {
      width: 60% !important;
    }
  }

  &__description {
    @extend .subHeading-secondary;
    color: #2e3043;
    @include phone {
      text-align: center !important;
    }
  }

  &__button {
    width: 145px;

    @include tablet {
      height: 48px !important;
    }
  }
}

.download-middle {
  width: 100% !important;
  margin-top: 3% !important;

  &__title {
    margin-right: 25% !important;

    @include tablet {
      margin-right: 4% !important;
    }
  }

  &__descrip {
    @extend .col-end;
    margin-right: 1% !important;
    @include tablet {
      display: flex;
      justify-content: center;
      width: 50% !important;
    }

    @include phone {
      width: 100% !important;
    }
  }

  &__button {
    width: 100px !important;
    @include phone {
      width: 240px;
      margin-top: 5% !important;
    }
  }

  @include phone {
    justify-content: center !important;
    align-items: center !important;
  }

  &__container {
    width: 42%;
    @include tablet {
      width: 30%;
    }
  }
}

// .download-table {
//   margin-top: 2% !important;
// }

// .state-colors {
//   &__pending {
//     color: $orange !important;
//   }

//   &__process {
//     color: #2e3043 !important;
//   }

//   &__finished {
//     color: $moderate_lime_green !important;
//   }

//   &__error {
//     color: $red !important;
//   }
// }

.help-text-container {
  text-align: right !important;
  @include tablet {
    text-align: left !important;
  }
  @include phone {
    text-align: left !important;
  }
}

.template-container {
  padding-top: 2.9% !important;
  text-align: end !important;
  @include tablet {
    padding-top: 3.7% !important;
    width: 45%;
    text-align: end;
  }

  @include phone {
    width: 100%;
    text-align: center;
  }

  &__button {
    @extend .col-flex;

    @include phone {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100% !important;
    }
  }

  &__general {
    @extend .col-end;

    @include phone {
      display: flex;
      justify-content: center !important;
      align-items: center;
      text-align: center;
    }
  }
}

.upload-file {
  width: 100%;

  @include phone {
    width: 99.9% !important;
  }
}

.guide-file {
  @extend .col-end;

  @include phone {
    text-align: end;
    width: 99.9% !important;
  }
}

.table-title {
  margin-top: 1% !important;

  @include tablet {
    margin-bottom: 2% !important;
  }

  @include phone {
    margin-bottom: 5% !important;
  }
}

// Corregir alineacion celular

.catalogeOptContainer {
  display: flex;
  align-items: center;
}

.catalogueText {
  @include phone {
    width: 90% !important;
  }
}

//Formulario del filtro

.filter__form__container {
  width: 100% !important;
}

.filter__company__container {
  width: 100% !important;
}
//Table

.download-table {
  max-width: 100% !important;
}

//Campos de filtro grupo empresarial

.filter_company {
  @include phone {
    padding: 1rem !important;
  }
}

//Botones de filtro

.confirm__button__container {
  @include phone {
    padding-top: 1rem !important;
    height: auto !important;
  }
}

//Capas BI

.biLayers_container {
  padding: 2% !important;
  border-radius: 8px;
  @include tablet {
    padding: 4% !important;
  }
  @include phone {
    padding: 7% !important;
  }
}

.BILayers_info_container {
  width: 100%;
  height: 50.24rem !important;
  background-color: $white;
  border-radius: 16px;
  padding: 1% 2%;
  margin-bottom: 5%;
  @include tablet {
    height: 100%;
    padding: 1% 5%;
  }
  @include phone {
    padding: 4%;
  }

  &__noResults {
    @extend .BILayers_info_container;
    display: flex;
    align-items: center !important ;
  }
}

.table-BILayers {
  margin: 0px;
  padding-top: 2%;
  @include tablet {
    padding-top: 3%;
  }
  @include phone {
    padding-top: 5%;
  }

  &__header {
    margin-bottom: 1% !important;
    margin-left: 0px !important;
    margin-top: 0px !important;
    width: 100% !important;
  }

  &__base {
    padding: 1rem;
    @include tablet {
      padding: 1rem 0;
    }
  }

  &__state {
    margin: 0 20%;
    @include tablet {
      margin: 0;
    }
    @include phone {
      margin: 0;
    }
  }
}
