.titlee-section {
  margin-top: 2% !important;
}

.title-padding {
  padding-left: 2.5% !important;
}

.filter-fields {
  background-color: white !important;
  border: 1px solid #543ab4 !important;
  border-radius: 8px !important;
  width: 32rem !important;

  @include tablet {
    width: 20rem !important;
  }

  &__datepicker {
    @extend .filter-fields;
    width: 20rem !important;
    &__error {
      @extend .filter-fields__datepicker;
      border: 1px solid $errorColor !important;
    }
  }

  &__dateType {
    @extend .filter-fields;
    width: 20rem !important;
    &__error {
      @extend .filter-fields__dateType;
      border: 1px solid $errorColor !important;
    }

    @include tablet {
      width: 19rem !important;
    }
  }

  &__error {
    @extend .filter-fields;
    border: 1px solid $errorColor !important;
  }
}

.filter-fields-reload {
  background-color: white !important;
  border: 1px solid #543ab4 !important;
  border-radius: 8px !important;
  width: 100% !important;

  @include tablet {
    width: 100% !important;
  }

  @include phone {
    width: 100% !important;
  }

  &__datepicker {
    @extend .filter-fields;
    width: 100% !important;
    &__error {
      @extend .filter-fields__datepicker;
      border: 1px solid $errorColor !important;
    }
  }

  &__dateType {
    @extend .filter-fields;
    width: 100% !important;
    &__error {
      @extend .filter-fields__dateType;
      border: 1px solid $errorColor !important;
    }

    @include tablet {
      width: 100% !important;
    }
  }

  &__error {
    @extend .filter-fields;
    border: 1px solid $errorColor !important;
  }
}

.icon-button-causal {
  padding: 0; /* Elimina el padding */
  margin-left: 2rem;
}

.icon-button-custom:hover {
  color: #8c8d9d; /* Cambia el color cuando se hace hover */
}

// Filters request
.filters {
  background-color: white !important;
  border: 1px solid #543ab4 !important;
  border-radius: 8px !important;

  width: 100%;
  height: 4.2rem;

  &__error {
    @extend .filters;
    border: 1px solid $errorColor !important;
  }

  &__not-filled {
    @extend .filters;
    border: 1px solid $text-color-grey !important;
  }

  &__disabled {
    @extend .filters;
    border: 1px solid $grey-disable !important;
  }

  &__icon-btn {
    color: #543ab4;
    &__error {
      color: 1px solid $errorColor !important;
    }

    &__not-filled {
      color: 1px solid $text-color-grey !important;
    }

    &__disabled__icon-btn {
      color: 1px solid $grey-disable !important;
    }
  }
}

.container-causal {
  display: flex;
  flex-wrap: nowrap;
}

.container-autocomplete {
  margin-right: 0 !important;
  margin-left: auto;
}

.filters-causal {
  background-color: white !important;
  border: 1px solid #7f7e84 !important;
  border-radius: 8px !important;
  // width: 18rem !important;
  width: 150%;
  height: 3.5rem;

  @include tablet {
    width: 120% !important;
    margin-bottom: 3rem !important;
  }

  @include phone {
    width: 120% !important;
    margin-left: 10px !important;
    margin-bottom: 0rem !important;
  }

  &__error {
    @extend .filters;
    border: 1px solid $errorColor !important;
  }
}

.filters-load-mapping {
  background-color: white !important;
  border: 1px solid #7f7e84 !important;
  border-radius: 12.5px !important;
  padding: 5px !important;
  width: 100%;
  height: 100%;
}

.filters-load-mapping-edit {
  background-color: white !important;
  border: 1px solid #7f7e84 !important;
  border-radius: 12.5px !important;
  padding: 5px !important;
  width: 100%;
  height: 100%;

  @include phone {
    width: 90% !important;
  }
}

.filters-causal-total {
  background-color: white !important;
  border: 1px solid #7f7e84 !important;
  border-radius: 8px !important;
  width: 100%;
  height: 3.5rem;
  margin-left: auto;
  margin-right: 0 !important;
  padding-left: 1rem;

  @include phone {
    margin-left: 0px !important;
  }

  @include tablet {
    width: 100% !important;
  }

  &__error {
    @extend .filters;
    border: 1px solid $errorColor !important;
  }
}

.tooltip-causales {
  margin-left: 0;
  margin-right: 0;

  @include tablet {
    margin-left: 2rem !important;
    margin-top: 5px !important;
  }
}

.filters-width {
  width: 30rem !important;
  height: 4.5rem !important;

  @include phone {
    width: 22rem !important;
    height: 4rem !important;
  }
}

.filter-container-adminSpecial {
  @include phone {
    margin-bottom: 7%;
  }
}

.create-btn-container {
  align-items: center;
  justify-content: flex-end;

  @include phone {
    justify-content: center;
    padding: 2%;
  }
}
