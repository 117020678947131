.full-width {
  width: 100% !important;
  @include phone {
    max-width: 290px;
  }
}

.full-width-allies {
  width: 100% !important;
  @include phone {
    width: 215px !important;
    align-items: left !important;
  }
}

.full-height {
  height: 100% !important;
}

.u-padding {
  padding-top: 45px;
}

.u-fullPadding {
  padding-top: 45px;
  padding-left: 50px;
}

.u-marginL {
  margin-left: 1.5rem !important;
}

.u-marginT {
  margin-top: 1.5rem !important;
}

.u-marginB {
  margin-bottom: 1.5rem !important;
  &__responsive {
    @include tablet {
      margin-bottom: 4rem !important;
    }
  }
}

.u-marginL-XL {
  margin-left: 2rem !important;

  @include phone {
    margin: 0 2rem 2rem 2rem !important;
  }
}

.u-box-padding {
  padding: 10rem !important;
  &__sm {
    padding: 2rem !important;
  }
}

.wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.container__page {
  background-color: $background-color;
}

.label-invisible {
  min-height: 3px !important;
  color: $background-color !important;
}

.css-wh8s2b-MuiGrid-root {
  margin-left: 0px;
}

.grid {
  &__container {
    padding-top: 3rem;
    width: 100%;
    background-color: $white;
    &__layout {
      padding-left: 7rem !important;
      @include tablet {
        padding-left: 10rem !important;
      }
    }
    &__filled {
      background-color: $white;
      &__rounded {
        @extend .grid__container__filled;
        border-radius: 16px;
        padding: 1.5rem;
        width: 100% !important;
        margin-left: 0% !important;
        margin-top: 1.5rem !important;
        @include phone {
          padding: 0 !important;
        }
      }
      &__soft-rounded {
        @extend .grid__container__filled;
        border-radius: 16px;
        padding: 1.2rem;
      }
    }
    &__dotted {
      border: 1.5px dashed #5c40ff;
      margin-top: 2rem !important;
      padding: 3rem;
      border-radius: 25px;
      background-color: $background-color;
    }
    &__file {
      margin-top: 3rem !important;
      margin-left: 1rem !important;
      text-align: center;
      padding: 2rem;
      box-shadow: 0px 14px 14px 2px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
    }
    &__fullW {
      width: 100% !important;
    }
    &__outlined {
      width: 100%;
      height: 340px;
      border: 1px solid #c0c1d8;
      box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      padding: 2rem;
      overflow-y: scroll;
      justify-content: space-evenly !important;
    }
    &__boxShadow {
      box-shadow: 0px 14px 14px 2px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 3rem 2rem 3rem 2rem;
    }
    &__fixed {
      &__salesForce {
        @extend .grid__container;
        height: 312px !important;
      }
    }
  }
}

.MuiBackdrop-root {
  background-color: rgba(85, 114, 183, 0.2) !important;
}

.MuiAccordionDetails-root {
  background: #f8f8f8 !important;
  padding: 3rem !important;
  border: 1px dashed #c0c1d8 !important;
  border-radius: 0px 0px 8px 8px !important;
}

.MuiPaper-elevation8.MuiMenu-paper {
  height: auto !important;
  max-height: 160px !important;
}

.select {
  &__filters {
    width: 200px;
    height: 39px;

    background-color: white !important;
    border: 1px solid #543ab4 !important;
    border-radius: 8px !important;

    @include tablet {
      width: 300px !important;
    }
    @include phone {
      width: 200px !important;
    }

    &__unfocus {
      @extend .select__filters;
      color: $grey !important;
      border: 1.5px solid $grey !important;
    }

    &__xxs {
      @extend .select__filters;
      width: 17.5rem !important;

      @include tablet {
        width: 30rem !important;
      }
      @include phone {
        width: 200px !important;
      }
    }

    &__xs {
      @extend .select__filters;
      width: 20.5rem !important;

      @include tablet {
        width: 30rem !important;
      }
      @include phone {
        width: 200px !important;
      }
    }

    &__sm {
      @extend .select__filters;
      width: 24.7rem !important;

      @include tablet {
        width: 30rem !important;
      }
      @include phone {
        width: 200px !important;
      }

      &__unfocus {
        @extend .select__filters__unfocus;
        border: 1px solid $grey !important;
        @include tablet {
          width: 30rem !important;
        }
        @include phone {
          width: 200px !important;
        }
      }
    }

    &__md {
      @extend .select__filters;
      width: 35.3rem !important;
      @include tablet {
        width: 30rem !important;
      }
      @include phone {
        width: 19rem !important;
      }
    }

    &__medium {
      @extend .select__filters;
      width: 32.2rem !important;
      @include tablet {
        width: 24rem !important;
      }
      @include phone {
        width: 19rem !important;
      }
    }

    &__large {
      @extend .select__filters;
      width: 35.2rem !important;
      @include tablet {
        width: 24rem !important;
      }
      @include phone {
        width: 19rem !important;
      }
    }

    &__full {
      @extend .select__filters;
      width: 100% !important;

      @include tablet {
        width: 100% !important;
      }
      @include phone {
        width: 100% !important;
      }
    }

    &__insight {
      @extend .select__filters;
      width: 24.7rem;

      @media (min-width: 1300px) {
        width: 19rem !important;
      }

      @include tablet {
        width: 30rem !important;
      }
      @include phone {
        width: 200px !important;
      }
    }

    &__errors {
      @extend .select__filters;
      border: 1px solid $errorColor !important;

      &__medium {
        @extend .select__filters__errors;
        width: 32.2rem !important;
        @include tablet {
          width: 24rem !important;
        }
        @include phone {
          width: 19rem !important;
        }
      }

      &__large {
        @extend .select__filters;
        width: 3.2rem !important;
        @include tablet {
          width: 24rem !important;
        }
        @include phone {
          width: 19rem !important;
        }
      }

      &__md {
        @extend .select__filters__errors;
        width: 35.3rem !important;
        @include tablet {
          width: 30rem !important;
        }
        @include phone {
          width: 19rem !important;
        }
      }

      &__sm {
        @extend .select__filters__errors;
        width: 24.7rem !important;
        @include tablet {
          width: 30rem !important;
        }
        @include phone {
          width: 200px !important;
        }
      }

      &__xs {
        @extend .select__filters__errors;
        width: 21rem !important;

        @include tablet {
          width: 30rem !important;
        }
        @include phone {
          width: 200px !important;
        }
      }
      &__xxs {
        @extend .select__filters__errors;
        width: 17.5rem !important;

        @include tablet {
          width: 30rem !important;
        }
        @include phone {
          width: 200px !important;
        }
      }

      &__full {
        @extend .select__filters__errors;

        @include tablet {
          width: 100% !important;
        }
        @include phone {
          width: 100% !important;
        }
      }

      &__insight {
        @extend .select__filters__errors;
        width: 24.7rem !important;
  
        @media (min-width: 1920px) {
          min-height: 100% !important;
        } 
  
        @media (min-width: 1336px) {
          min-height: 100% !important;
        } 
  
        @include tablet {
          width: 30rem !important;
        }
        @include phone {
          width: 200px !important;
        }
      }
    }
  }
}

.size-select {
  width: 100% !important;
  &__borders {
    @include phone {
      margin-left: 2rem !important;
      padding: 2rem 0px !important;
    }
  }
}

.select-contactOne {
  &__filters {
    width: 100% !important;
    height: 39px;
    background-color: white !important;
    border: 1px solid #543ab4 !important;
    border-radius: 8px !important;
    @include tablet {
      width: 100% !important;
    }
  }
}

.MuiInputBase-input.MuiOutlinedInput-input.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  margin-top: 2px;
  border-radius: 8px;
  padding: 8px 4px 7px 14px !important;
}

.input-component__label {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px !important;
  transform-origin: -45px -5px !important;
  translate: 2px -7px !important;
  color: #8c8d9d !important;
}

.labelMaster {
  font-size: 10px !important;
  margin-left: 1rem !important;
  &__text-grey {
    @extend .labelMaster;
    color: $text-color-grey;
  }
  &__text-grey-one{
    color: $text-color-grey;
    font-size: 10px !important;
    margin-left: 25rem !important;
    margin-right: 1rem !important;
    margin-top: 3rem !important;
    @include tablet {
      margin-left: 0rem !important;
      margin-top: 2rem !important;
      font-size: 10px !important;
    }
    @include phone {
      font-size: 20px !important;
      margin-top: 2rem !important;
      padding-right: 0.5rem !important;
      padding-bottom: 3rem;
    }
    @media(min-width: 1920px) { 
      margin-left: 0rem !important;
      margin-top: 1.5rem !important;
      font-size: 18px !important;
    }
  }
}

.select-contact-support {
  &__filters {
    width: 430px !important;
    height: 39px;
    background-color: white !important;
    border: 1px solid #543ab4 !important;
    border-radius: 8px !important;

    @include phone {
        width: 330px !important;
      }
    &__errors {
      @extend .select-contact__filters;
      border: 1px solid $errorColor !important;
      &-one{
        padding-bottom: 1rem;
      }
    }

  }
}

.filters-support {
    width: 430px !important;
    height: 39px;
    background-color: white !important;
    border: 1px solid #543ab4 !important;
    border-radius: 8px !important;
    @include phone {
        width: 320px !important;
    }
    &__error {
        @extend .filters-support;
        border: 1px solid $errorColor !important;
        width: 430px !important;
        height: 39px;
        background-color: white !important;
        border-radius: 8px !important;
        @include phone {
            width: 320px !important;
        }
        }

    &__not-filled {
        @extend .filters-support;
        border: 1px solid $text-color-grey !important;
        width: 430px !important;
        height: 39px;
        background-color: white !important;
        border-radius: 8px !important;
        @include phone {
            width: 320px !important;
        }
        }  
    &__disabled {
        @extend .filters-support;
        border: 1px solid $grey-disable !important;
        width: 430px !important;
        height: 39px;
        background-color: white !important;
        border-radius: 8px !important;
        @include phone {
            width: 320px !important;
            }
        }
  }
.select-contact-date {
  &__filters {
    width: 250px !important;
    height: 39px;
    background-color: white !important;
    border: 1px solid #543ab4 !important;
    border-radius: 8px !important;
    padding-right: 2rem;

    @include phone {
        width: 330px !important;
      }
    &__errors {
      @extend .select-contact__filters;
      border: 1px solid $errorColor !important;
      &-one{
        padding-bottom: 1rem;
      }
    }

  }
}
.select-contact {
  &__filters {
    width: 100% !important;
    height: 39px;

    background-color: white !important;
    border: 1px solid #543ab4 !important;
    border-radius: 8px !important;

    @include tablet {
      width: 100% !important;
    }
    &-One{
      @extend .select-contact__filters;
      padding-top: 10rem;
    }
    &__grey {
      @extend .select-contact__filters;
      border: 1px solid #c5c7d0 !important;
    }
    &-two{
      @extend .select-contact__filters;
      background-color: white !important;
      border: 1px solid #543ab4 !important;
      border-radius: 8px !important;
    }
    &__sm {
      @extend .select-contact__filters;
      width: 24.7rem !important;

      @include tablet {
        width: 100% !important;
      }
      @include phone {
        width: 100% !important;
      }
    }

    &__xs {
      @extend .select-contact__filters;
      width: 100% !important;

      @include tablet {
        width: 100% !important;
      }
      @include phone {
        width: 100% !important;
      }
    }

    &__errors {
      @extend .select-contact__filters;
      border: 1px solid $errorColor !important;
      &-one{
        padding-bottom: 1rem;
      }

      &__medium {
        @extend .select-contact__filters__errors;
        width: 32.2rem !important;
        @include tablet {
          width: 24rem !important;
        }
        @include phone {
          width: 19rem !important;
        }
      }

      &__md {
        @extend .select-contact__filters__errors;
        width: 35.3rem !important;
        @include tablet {
          width: 30rem !important;
        }
        @include phone {
          width: 19rem !important;
        }
      }

      &__sm {
        @extend .select-contact__filters__errors;
        width: 24.7rem !important;
        @include tablet {
          width: 100% !important;
        }
        @include phone {
          width: 100% !important;
        }
      }

      &__xs {
        @extend .select-contact__filters__errors;
        width: 90% !important;

        @include tablet {
          width: 100% !important;
        }
        @include phone {
          width: 100% !important;
        }
      }
      &__xxs {
        @extend .select-contact__filters__errors;
        width: 17.5rem !important;

        @include tablet {
          width: 30rem !important;
        }
        @include phone {
          width: 200px !important;
        }
      }
    }

    &__full {
      @extend .select-contact__filters__grey;
      width: 100% !important;

      @include tablet {
        width: 100% !important;
      }
      @include phone {
        width: 100% !important;
      }
    }
  }
}

.select-load-mapping {
  &__filters {
    width: 100% !important;
    height: 39px;

    background-color: white !important;
    border: 1px solid #543ab4 !important;
    border-radius: 8px !important;

    &__input {
      @extend .select-load-mapping__filters;
      max-width: 28.4rem !important;
    }
    &__errors {
      @extend .select__filters;
      border: 1px solid $errorColor !important;
    }
    &__grey {
      @extend .select-load-mapping__filters;
      border: 1px solid $grey !important;
    }
  }
}

.icon {
  &__searchBar {
    width: 250px !important;
    height: 39px !important;
    background-color: white !important;
    border: 1px solid #8c8d9d !important;
    border-radius: 8px !important;
  }
  &__container {
    cursor: pointer;
    border: 1px solid #c0c1d8;
    border-radius: 4px;
    width: 101px;
    height: 120px;
    margin: 1rem !important;
    &__selected {
      @extend .icon__container;
      border: 2px solid $color-primary !important;
    }
  }
  &__color {
    filter: invert(20%) sepia(34%) saturate(3871%) hue-rotate(238deg)
      brightness(109%) contrast(96%);
  }
  &__selected {
    position: relative;
    top: -10px;
    left: 85px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $color-primary;
  }
}

.MuiOutlinedInput-input {
  font-size: 12px !important;
}

.text-upload-file {
  text-align: center;
}

.helperText {
  p {
    font-size: 11px !important;
  }
}

.d-none__phone {
  @include phone {
    display: none;
  }
}

.text-center {
  text-align: center !important;
}

.text-pasword {
  font-size: 30px;
  text-align: center;
  line-height: 1.2;
  @include tablet {
    font-size: 46px !important;
    text-align: left !important;
  }
  @include phone {
    font-size: 16px !important;
    text-align: center !important;
  }
  @media (min-width: 1920px) {
    font-size: 38px !important;
    text-align: center;
    line-height: 1.2;
  }
}

.text-center-bigger {
  color: #8c8d9d;
  font-size: 25px;
  margin-top: 15px;
  text-align: justify;
  line-height: 1.2 !important;
  &__primary-one {
    @extend .display-x-large-home;
    color: $primary-color-one;
  }
  @media (max-width: 1340px) {
    font-size: 20px;
  }
  @media (max-width: 770px) {
    font-size: 22px;
  }
  @media (max-width: 380px) {
    font-size: 15px;
  }
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-justify {
  text-align: justify !important;
}

.text-transform {
  &__capitalize {
    text-transform: capitalize;
  }
  &__uppercase {
    text-transform: uppercase;
  }
  &__lowercase {
    text-transform: lowercase;
  }
}

.d-block {
  display: block !important;
}

.linkStyle {
  text-decoration: underline !important;
  cursor: pointer !important;
}

.overflow {
  &__x {
    &__hidden {
      overflow-x: hidden !important;
    }
  }
}

// State colors
.state-colors {
  border-radius: 3px;
  font-weight: $weight-bold;
  padding: 2px 4px;
  &__orange {
    @extend .state-colors;
    color: $orange;
    padding: 0px 0px;
  }
  &__success {
    @extend .state-colors;
    color: $successColor;
    background: rgba(60, 213, 129, 0.08);
  }
  &__error {
    @extend .state-colors;
    color: $errorColor;
    background: rgba(243, 108, 112, 0.08);
  }
  &__parcial {
    @extend .state-colors;
    color: $green_dark;
    background: rgba(40, 152, 88, 0.08);
  }
  &__very_dark_grayish_blue {
    @extend .state-colors;
    color: $very_dark_grayish_blue;
    background: rgba(46, 48, 67, 0.08);
  }
  &__primary-color-one {
    @extend .state-colors;
    background: rgba(85, 58, 182, 0.08);
    color: $primary-color-one;
  }
  &__primary-color-two {
    color: $primary-color-two;
  }
  &__secondary-color-one {
    color: $secondary-color-one;
  }
  &__secondary-color-two {
    @extend .state-colors;
    color: $secondary-color-two;
  }
  &__secondary-color-three {
    @extend .state-colors;
    color: $secondary-color-three;
    background: rgba(217, 47, 210, 0.08);
  }
  &__tertiary-color-one {
    @extend .state-colors;
    color: $tertiary-color-one;
    background: rgba(255, 168, 54, 0.08);
  }
  &__tertiary-color-two {
    color: $tertiary-color-two;
  }
  &__tertiary-color-third {
    color: $tertiary-color-third;
  }
}

.typedoc-colors {
  border-radius: 3px;
  font-weight: $weight-bold;
  padding: 2px 4px;
  &__sales-invetories {
    @extend .typedoc-colors;
    color: #ffa836;
    background: rgba(255, 168, 54, 0.08);
  }
  &__sales {
    @extend .typedoc-colors;
    color: #d92fd2;
    background: rgba(217, 47, 210, 0.08);
  }
  &__inventories {
    @extend .typedoc-colors;
    color: #0fbbf1;
    background: rgba(15, 187, 241, 0.08);
  }
}

.border-radius {
  border-radius: 12px !important;
  box-shadow: none !important;
  &__default {
    border-radius: 16px !important;
  }
}

.word-break {
  &__keep {
    word-break: keep-all !important;
  }
}

.control-filters {
  max-height: 38rem !important;
  @include tablet {
    max-height: 100% !important;
  }
}

.container-home-buttons {
  width: 100% !important;
  @include tablet {
    width: 100% !important;
  }
}

.bar-width {
  width: 50%;
  @include phone {
    width: 99%;
  }
}

.grid-funnel {
  //height: 27rem;
  background-image: linear-gradient(
    to bottom,
    rgb(203 213 225) 0.1px,
    transparent 1px
  );
  background-size: 6rem 6rem;
  background-position: center center;
}

.grey-border {
  border: 1px solid #c5c7d0 !important;
}

.table-text {
  font-size: 12px !important;
  color: #9ca3af;
  font-weight: 500;
  text-decoration: underline;
}

.info-filter {
  width: auto;
  word-break: keep-all !important;
  font-size: $font-size-default;
  border-radius: 4px;
  font-weight: 400;
  padding: 4px 8px;
  text-transform: capitalize;
  background: $tonal;
  color: $moderate_blue;

  @include tablet {
    font-size: $font-size-sm;
  }

  @include phone {
    font-size: $font-size-sm;
  }

  &__container {
    padding-top: 2rem;
    display: flex;
    // justify-content: center;
  }

  &__tableButton {
    @extend .info-filter;
    border-radius: 4px;
    background-color: $grey-disable;
    color: $grey;
    padding: 1px;
    margin: 0px 3px !important  ;
    text-decoration: none !important;
    cursor: pointer;
  }
}

.column-header-tooltip {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.info-filter__BILayers {
  @extend .info-filter;
  text-transform: none !important;
}
