.modalEditRoles {
  &-principalBox {
    display: flex;
    justify-content: flex-end !important;
    width: 100% !important;
  }

  &-stepper {
    color: "#543AB4" !important;
  }

  &-principalButton {
    width: 125px;
    background: $color-primary !important;
    padding: 8px 24px;
    font-family: $font-family-text;
    color: white !important;
    font-size: 14px;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.4s;
    text-transform: none !important;

    @include tablet {
      width: 25rem !important;
    }
  }

  &__form {
    align-items: left;
    text-align: left;
    margin-top: 2%;

    &-errorText {
      color: $errorColor;
      text-align: left;
      font-size: $font-size-sm;
    }

    &-title {
      color: $color-primary;
      font-size: $font-size-md !important;
      font-family: $font-family-text;
      font-weight: bolder !important;
      text-align: left;
      padding-top: 1.5rem;
      padding-bottom: 3rem;

      @include tablet {
        text-align: center;
      }

      @include phone {
        font-size: 20px;
        text-align: center;
      }
    }

    &-subtitle {
      color: $grey-color;
      font-size: $font-size-sm;
    }

    &-paragraph {
      margin-top: 0.5rem;
      color: $color-primary !important;
      font-size: 13.5px;
    }

    &-box {
      margin-top: 2rem !important;
    }

    &-input {
      border: 1px solid $grey-color !important;
      border-radius: 8px !important;
      height: 4rem;
      width: 25rem;
      transition: all 0.2s !important;
      margin: 1.5rem 2rem 1.5rem 0 !important;
      font-size: $font-size-xs !important;

      &--error {
        @extend .modalEditRoles__form-input;
        border-color: $errorColor !important;
      }

      @include tablet {
        width: 20rem;
        margin: 0.5rem 4rem 1.5rem 0 !important;
      }
    }

    :disabled {
      background-color: #e6e9ef !important;
      color: black !important;
    }

    &-description {
      border: 1px solid $grey-color !important;
      border-radius: 8px !important;
      height: 4rem;
      width: 40rem;
      transition: all 0.2s !important;
      margin: 1.5rem 2rem 1.5rem 0 !important;
      font-size: $font-size-xs !important;

      &--error {
        @extend .modalCreateOptions__form-description;
        border-color: $errorColor !important;
      }

      @include tablet {
        width: 20rem;
        margin: 0.5rem 4rem 1.5rem 0 !important;
      }
    }

    &__actions {
      text-align: center !important;
      padding-top: 2.4rem;
      :disabled {
        background-color: #c0c1d8 !important;
        color: white !important;
      }

      &-buttonFilled {
        width: 125px;
        background: $color-primary !important;
        padding: 8px 24px;
        font-family: $font-family-text;
        color: white !important;
        font-size: 12px !important;
        font-weight: bold;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.4s;
        text-transform: none !important;

        @include tablet {
          width: 20rem;
          margin: 0rem 4rem 1.5rem 0 !important;
        }
      }

      &-buttonOutline {
        width: 125px;
        background: transparent !important;
        padding: 8px 24px;
        font-family: $font-family-text;
        color: $color-primary !important;
        font-size: 12px !important;
        font-weight: bold;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.4s;
        border: 1px solid $color-primary !important;
        margin-right: 1rem !important;
        text-transform: none !important;

        @include tablet {
          width: 20rem;
          margin: 0.5rem 4rem 1.5rem 0 !important;
        }
      }
    }
  }
}
