*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

html,
body {
  height: 100%;
  background-color: $background-color !important;
}

body > #root > div {
  height: 100vh;
}

html {
  /* Root font size, defines what 1rem is */
  font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%
}

body {
  box-sizing: border-box;
}

p {
  margin-bottom: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

// Distribucion de contenedor de contenido al 100%

.main_content_container {
  max-width: 100% !important;
}
