.download {
  &__margin {
    margin: 20px 0;
  }

  @include tablet {
    &__description {
      width: 70%;
    }
  }

  @include phone {
    &__description {
      width: 100%;
    }

    &__container {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-grid-button {
        width: 100% !important;
      }
    }
  }
}
