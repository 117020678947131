.modalCreateUsers {
  &-principalBox {
    display: flex;
    justify-content: flex-end !important;
    width: 100% !important;
  }

  &-principalButton {
    width: 17rem;
    height: 3.5rem;
    background: $color-primary !important;
    padding: 8px 24px;
    font-family: $font-family-text;
    color: $white !important;
    font-size: 14px !important;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.4s;
    border: 1px solid $color-primary !important;
    text-transform: none !important;

    @include phone {
      margin-bottom: 0.5rem !important;
      width: 21.5rem !important;
      padding: 8px 24px !important;
    }
  }

  &__tooltip {
    width: 100%;
    color: #553ab6;
    background-color: #cfc7ed;
    font-size: $font-size-subheading;
    border-radius: 4px;
  }

  &__form {
    align-items: left;
    text-align: left;
    margin-top: 0.5rem;

    @include tablet {
      align-items: center;
      text-align: center;
    }

    &-errorText {
      color: $errorColor;
      text-align: left;
      font-size: $font-size-sm;
    }

    &-title {
      color: $color-primary;
      font-size: $font-size-md !important;
      font-family: $font-family-text;
      font-weight: bolder !important;
      text-align: left;
      padding-top: 1.5rem;
      padding-bottom: 3rem;

      @include tablet {
        text-align: center;
        font-size: 2rem !important;
      }
    }

    &-subtitle {
      color: $grey-color;
      font-size: $font-size-sm;
    }

    &-paragraph {
      width: 90%;
      color: $primary-color-one;
      background-color: rgba(15, 187, 241, 0.05);
      font-size: $font-size-subheading;
      margin-bottom: 2rem;

      @include tablet {
        text-align: center;
      }

      @include phone {
        text-align: center;
      }
    }

    &-input {
      transition: all 0.2s !important;
      border: 1px solid $grey-color !important;
      border-radius: 8px !important;
      height: 4rem;
      width: 23rem;

      margin: 1.8rem 0.8rem 1rem 0 !important;

      &__dynamicReport {
        @extend .modalCreateUsers__form-input;
        margin-right: 0.8rem !important;
      }

      &__permissions {
        @extend .modalCreateUsers__form-input;
      }

      &__large {
        @extend .modalCreateUsers__form-input;
        width: 35rem !important;
        &__dynamicReport {
          @extend .modalCreateUsers__form-input__large;
          margin-right: 0.8rem !important;
        }
      }

      &__medium {
        @extend .modalCreateUsers__form-input;
        background-color: white;
        width: 32.2rem !important;
      }
      &__small {
        @extend .modalCreateUsers__form-input;
        background-color: white;
        width: 19.9rem !important;
        @include tablet {
          width: 32.2rem !important;
        }
      }

      @include tablet {
        margin: 1.5rem 4rem 1.5rem 0 !important;
      }

      @include phone {
        width: 20rem !important;
      }

      &--error {
        @extend .modalCreateUsers__form-input;
        border-color: $errorColor !important;

        &__dynamicReport {
          @extend .modalCreateUsers__form-input;
          margin-right: 0.8rem !important;
        }

        &__large {
          @extend .modalCreateUsers__form-input--error;
          width: 35rem;
          &__dynamicReport {
            @extend .modalCreateUsers__form-input--error__large;
            margin-right: 0.8rem !important;
          }
        }

        &__medium {
          @extend .modalCreateUsers__form-input--error;
          background-color: white;
          width: 32.2rem !important;
        }
        &__small {
          @extend .modalCreateUsers__form-input--error;
          background-color: white;
          width: 19.9rem !important;
          @include tablet {
            width: 32.2rem !important;
          }
        }
      }
    }

    :disabled {
      background-color: #e6e9ef !important;
      color: black !important;
    }

    &__actions {
      text-align: center !important;
      padding-top: 3rem;

      &-buttonFilled {
        width: 15rem;
        height: 3.5rem;
        background: $color-primary !important;
        padding: 8px 24px;
        font-family: $font-family-text;
        color: $white !important;
        font-size: 12px !important;
        font-weight: bold;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.4s;
        border: 1px solid $color-primary !important;
        text-transform: none !important;

        &__shaking {
          @extend .modalCreateUsers__form__actions-buttonFilled;

          outline: 0;
          border-color: red;
          animation-name: bounce;
          animation-duration: 0.5s;
          animation-delay: 0.05s;
          @include keyframes(bounce) {
            0% {
              transform: translateX(0px);
              timing-function: ease-in;
            }
            37% {
              transform: translateX(5px);
              animation-timing-function: ease-out;
            }
            55% {
              transform: translateX(-5px);
              animation-timing-function: ease-in;
            }
            73% {
              transform: translateX(4px);
              animation-timing-function: ease-out;
            }
            82% {
              transform: translateX(-4px);
              animation-timing-function: ease-in;
            }
            91% {
              transform: translateX(2px);
              animation-timing-function: ease-out;
            }
            96% {
              transform: translateX(-2px);
              animation-timing-function: ease-in;
            }
            100% {
              transform: translateX(0px);
              animation-timing-function: ease-in;
            }
          }
        }

        @include tablet {
          width: 20rem;
          margin: 0rem 4rem 1.5rem 0 !important;
        }
      }

      &-buttonOutline {
        width: 15rem;
        height: 3.5rem;
        background: transparent !important;
        padding: 8px 24px;
        font-family: $font-family-text;
        color: $color-primary !important;
        font-size: 14px !important;
        font-weight: bold;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.4s;
        border: 1px solid $color-primary !important;
        margin-right: 1rem !important;
        text-transform: none !important;

        @include tablet {
          width: 20rem;
          margin: 0.5rem 4rem 1.5rem 0 !important;
        }
      }
    }

    .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
      color: rgba(0, 0, 0, 0.6) !important;
    }

    &__inputLabel {
      font-size: 13px !important;
      margin: 14px 0 0 -10px;
      padding: 0 0 0 5px !important;
      color: $grey !important;

      &__permissions {
        @extend .modalCreateUsers__form__inputLabel;
        font-size: 9px !important;
        margin: -13px 0 0px -17px;
      }
    }

    &__options {
      font-family: $font-family-text !important;
      height: 100px !important;
    }
  }
}

option {
  background-color: white;
  font-size: 15px;
  padding: 2% !important;
}

select::after {
  background-color: #f3f0ff !important;
  color: $primary-color-one !important;
}

.mobile-layout {
  &___container {
    @extend .col-flex;

    @include phone {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__add {
    @extend .btn__filled__blue;

    @include tablet {
      width: 25rem;
    }
    @include phone {
      width: 19rem !important;
      margin-bottom: 5% !important;
    }
  }

  &__delete {
    @extend .btn__filled__gradient;

    @include tablet {
      width: 25rem;
    }
    @include phone {
      width: 19rem !important;
    }
  }

  &__input {
    @extend .modalCreateUsers__form-input;
    width: 50rem;

    @include phone {
      width: 19rem !important;
    }

    &--error {
      @extend .modalCreateUsers__form-input--error;
      width: 50rem;
    }
  }

  &__slide {
    @extend .btn__filled__gradient;
    border-radius: 8px !important;
  }
}
