.modalPreviewMenu {
  &-principalBox {
    display: flex;
    justify-content: flex-end !important;
    width: 100% !important;

    @include tablet {
      width: 20rem;
      margin: 5rem 4rem 1.5rem 0 !important;
    }

    @include phone {
      display: block;
      text-align: center !important;
      align-items: center !important;
      margin: 5rem 0 1.5rem 0 !important;
      width: 100% !important;
    }
  }

  &-principalButton {
    width: 15rem !important;
    height: 3.5rem;
    background: transparent !important;
    padding: 8px 24px;
    font-family: $font-family-text;
    color: $color-primary !important;
    font-size: 14px !important;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.4s;
    border: 1px solid $color-primary !important;
    margin-right: 1rem !important;
    text-transform: none !important;

    @include phone {
      width: 21.5rem !important;
      padding: 8px 24px !important;
    }
  }

  &__form {
    align-items: left;
    text-align: left;
    margin-top: 2%;

    &-responsive--label {
      display: none;

      text-align: left;
      width: 263px;
    }

    &-errorText {
      color: $errorColor;
      text-align: left;
      font-size: $font-size-sm;
    }

    &-title {
      color: $color-primary;
      font-size: $font-size-md !important;
      font-family: $font-family-text;
      font-weight: bolder !important;
      text-align: left;
      padding-top: 1.5rem;
      padding-bottom: 3rem;

      @include phone {
        font-size: 20px;
      }
    }

    &-box {
      display: flex;
      flex-direction: row;
      margin-top: 0.5rem;

      &-level1 {
        color: $color-primary;
        font-size: $font-size-default !important;
        margin-left: 1rem;

        @include phone {
          font-size: 20px;
        }
      }

      &-level2 {
        color: $text-color;
        font-size: $font-size-default !important;
        margin-top: 0.5rem;
        margin-left: 3rem;

        @include phone {
          font-size: 20px;
          margin-left: 1rem;
        }
      }

      &-level3 {
        font-size: $font-size-default !important;
        margin-left: 3rem;

        @include phone {
          font-size: 20px;
          margin-left: 1rem;
        }
      }
    }

    &__actions {
      text-align: center !important;
      padding: 2rem 0 1rem 0;
      @include phone {
        padding: 1rem 0 0 0;
      }

      &-buttonFilled {
        width: 5rem !important;
        height: 44px !important;
        background: $color-primary !important;
        padding: 8px 24px;
        font-family: $font-family-text;
        color: white !important;
        font-size: 12px !important;
        font-weight: bold;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.4s;
        text-transform: none !important;
        margin-top: auto;

        @include tablet {
          width: 20rem;
          margin: 0.5rem 0 1.5rem 0 !important;
        }
        @include phone {
          margin-bottom: 0.5rem 0 0.5rem 0 !important;
        }
      }
    }
  }
  &__levelLabel {
    color: #8c8d9d !important;
    font-weight: 500;
    &__white {
      color: white;
    }
  }
  &__accordion {
    padding: 0 !important;
    &__summary {
      flex-direction: row-reverse;
      padding-left: "0px";
      text-align: left !important;
    }
    &__detail {
      background-color: white !important;
      border: none !important;
      padding: 0 !important;
    }
  }
  &__list {
    border-left: 1px dashed #5572b7;
  }
}

.labels_content {
  font-size: $font-size-default !important;
  @include phone {
    font-size: $font-size-small-mobile !important;
  }
}
