.notfound-container{
    display:flex;
    align-items: center;
    justify-content: center;
}

.back-button{
    background: #543ab4 !important;
    text-transform: none !important;
    color: white !important;
    font-size: $font-size-sm !important;

    @include phone{
        font-size: $font-size-xs !important;
    }
}

.notfound-text{
    @extend .label-Primary;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: $font-size-md !important;

    @include tablet{
        font-size: 22px !important;
    }

    @include phone{
        font-size: $font-size-xxs !important;
    }
}


