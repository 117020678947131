// .MuiFormControlLabel-label {
//   font-family: $font-family-text !important;
//   font-size: 14px !important;
// }
.Mui-checked {
  color: $color-primary !important;
}
// .MuiFormControlLabel-label {
//   color: #818181 !important;
//   font-weight: 500 !important;
//   line-height: 16px !important;
//   font-family: $font-family-text !important;
// }

// .MuiFormControlLabel-label {
//   color: #818181 !important;
//   font-size: $font-size-subheading !important;
//   font-family: $font-family-text !important;
//   font-weight: $weight-medium !important;
//   line-height: $line-height-subheading !important;
// }
