.companies_info_container {
  width: 100%;
  background-color: $white;
  border-radius: 16px;
  padding: 1% 2%;
  margin-bottom: 5%;

  @include phone {
    padding: 4%;
  }
}

.companies_button_container {
  width: 100%;
}

.filter-container {
  max-height: 275px;
  min-height: 80px;

  &__specialFiles {
    @extend .filter-container;
    max-height: 250px;
  }

  @include tablet {
    max-height: 900px !important;
    margin-bottom: 2% !important;
    width: 99%;
  }

  @include phone {
    max-height: 900px !important;
    min-height: 0px !important;
    margin-bottom: 2% !important;
    width: 99%;
  }
}

.side-line {
  border-right: 1px solid $primary-color-one;
  @include tablet {
    padding: 6%;
  }
  @include phone {
    border-right: none;
    padding-bottom: 2rem !important;
    border-bottom: 1px solid $primary-color-one;
  }
  &__bottomAlign {
    @extend .side-line;
    padding-bottom: 1.3%;

    @include phone {
      padding-bottom: 70% !important;
    }
  }
}

.side-line-adminclient {
  position: relative; // Asegura la posición relativa para el pseudo-elemento

  @include tablet {
    border-right: 1px solid $primary-color-one;
    padding: 6%;
  }
  @include phone {
    padding-bottom: 2rem !important;
    border-bottom: 1px solid $primary-color-one;
  }

  &::after {
    content: "";
    position: absolute;
    right: 55px; // Desplaza el borde hacia la izquierda
    top: 0;
    bottom: 0;
    width: 1px; // El ancho del borde
    background: $primary-color-one; // Color del borde
  }

  &__bottomAlign {
    @extend .side-line;
    padding-bottom: 1.3%;

    @include phone {
      padding-bottom: 10% !important;
    }
  }
}

@media (max-width: 1400px) {
  // Cambia cuando la pantalla es menor a 1400px
  .side-line-adminclient::after {
    right: 25px; // Nuevo valor para el right
  }
}

@media (max-width: 800px) {
  // Ajusta según el breakpoint para teléfonos
  .side-line-adminclient {
    &::after {
      display: none; // Oculta el pseudo-elemento en teléfonos
    }
  }
}

.side-line-contact {
  border-right: 1px solid $primary-color-one;
  @include tablet {
    padding: 6%;
  }
  @include phone {
    border-right: 1px solid $primary-color-one;
  }

  @include phone-xs {
    padding: 0% !important;

    border-right: none;
    border-bottom: 1px solid $primary-color-one;
  }

  &__verticalPadding {
    @extend .side-line-contact;
    padding: 2rem 0 !important;
  }

  &__lineVertical {
    @extend .side-line-contact;
    padding: 14rem 0 !important;

    @include phone {
      border-bottom: 1px solid $primary-color-one;
      padding: 2rem 0 !important;
      width: 100%;
    }

    &__two {
      @extend .side-line-contact;
      padding: 3rem 0 !important;
    }
  }

  &__bottomAlign {
    @extend .side-line-contact;
    padding-bottom: 1.3%;

    @include phone {
      padding-bottom: 10% !important;
    }
  }
}

.confirmation-buttons {
  @include tablet {
    margin-top: 10%;
  }
}

.datatable-style {
  width: 100%;

  @include tablet {
    width: 100%;
  }
}

.fake-button {
  text-transform: none !important;
  background-color: white !important;
  font-family: "Roboto" !important;
  font-size: 12px !important;
  color: black !important;
  font-weight: normal !important;
}

.tooltip-gen-info {
  background-color: #553ab60d;
  border-radius: 8px;
  padding: 1% !important;
  margin-top: 2% !important;
  margin-left: 1.5% !important;

  @include phone {
    margin-top: 15% !important;
  }
}

.freq-summ {
  margin-left: 1% !important;
  @include phone {
    margin-left: 0% !important;
  }
}

.second-tooltip-info {
  margin-top: 0.5% !important;
}

.label-margin {
  margin-top: -15px !important;
}

.generation-col-one {
  margin-right: -20px !important;

  @include tablet {
    margin-right: 0px;
  }
}
.generation-day {
  margin-left: -46px !important;
  @include tablet {
    margin-left: 0px !important;
  }
}

.type-of-group {
  margin-right: -30px !important;
  @include tablet {
    margin-right: 0px !important;
  }
}

.info-periods-tooltip {
  margin-left: -40px !important;
  padding-top: 2.5% !important;

  @include tablet {
    padding-top: 0% !important;
  }
}

.state-gen {
  margin-left: -10px !important;
  @include tablet {
    margin-left: 0px !important;
  }
}

//Botones de cofirmación

.filter-button {
  &__container {
    @include phone {
      padding: 1rem 0 !important;
      align-items: center !important;
    }
  }
  &__apply {
    @include tablet {
      max-width: 180px !important;
      height: 48px;
    }
  }
  &__clear {
    margin-left: 0.5rem !important;
    @include tablet {
      align-content: center;
      max-width: 60px;
      height: 48px;
    }
    @include phone {
      margin-left: 0 !important;
    }
  }
}
.apply__filter__button {
  @include tablet {
    width: 100% !important;
  }
  @include phone {
    width: 100% !important;
  }
}

.filter-tag {
  @include phone {
    padding: 0.5rem;
    margin-bottom: 1rem !important;
  }
}

.editCompany {
  &__container {
    @include tablet {
      margin-right: 0.6rem !important;
    }

    @include phone {
      margin-right: 0 !important;
    }
  }
  &__button {
    @include tablet {
      width: 200px !important;
    }

    @include phone {
      width: 100% !important;
    }
  }
}

.buttons-container {
  @include tablet {
    margin-left: 0px !important;
  }
  @include phone {
    margin-left: -16px !important;
  }
}

.editInsigth-form {
  &__container {
    @include tablet {
      width: 100% !important;
    }
  }
}

.modalEditInsight {
  align-self: center !important;
  max-height: 575px;
  @include tablet {
    max-height: 639px !important;
  }
  @include phone {
    max-height: 575px !important;
  }
}

.biLayers_info_container {
  width: 100%;
  background-color: $white;
  // border-radius: 16p;
  // padding: 1% 2%;
  padding: 0 0 3% 0 !important;

  @include tablet {
    padding: 0 !important;
  }
  @include phone {
    padding: 2%;
  }
}
