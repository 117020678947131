.modalCreateOptions {
  &-principalButton {
    width: 17rem !important;
    height: 3.5rem;
    background: $color-primary !important;
    padding: 8px 24px;
    font-family: $font-family-text;
    color: $white !important;
    font-size: 14px !important;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.4s;
    border: 1px solid $color-primary !important;
    margin-right: 1rem !important;
    text-transform: none !important;

    @include phone {
      margin-top: 0.5rem !important;
      width: 21.5rem !important;
      padding: 8px 24px !important;
    }
  }

  &__form {
    align-items: left;
    text-align: left;

    @include tablet {
      align-items: center;
      text-align: center;
    }

    &-responsive-box {
      display: flex;
      text-align: left;
    }

    &-responsive--label {
      display: none;

      text-align: left;
      width: 263px;
    }

    &-errorText {
      color: $errorColor;
      text-align: left;
      font-size: $font-size-sm;
    }

    &-title {
      color: $color-primary;
      font-size: $font-size-md !important;
      font-family: $font-family-text;
      font-weight: bolder !important;
      text-align: left;
      padding-top: 1.5rem;
      padding-bottom: 3rem;

      @include tablet {
        text-align: center;
        font-size: 2rem !important;
      }
    }

    &-subtitle {
      color: $grey-color;
      font-size: $font-size-sm;
    }

    &-input {
      transition: all 0.2s !important;
      border: 1px solid $grey-color !important;
      border-radius: 8px !important;
      height: 4rem;
      width: 100%;

      margin: 1rem !important;

      margin-left: 0 !important;

      @include tablet {
        width: 90% !important;
      }

      &--error {
        @extend .modalCreateOptions__form-input;
        border-color: $errorColor !important;
      }
    }

    &-description {
      transition: all 0.2s !important;
      border: 1px solid $grey-color !important;
      border-radius: 8px !important;
      height: 4rem;
      width: 100%;
      margin: 1rem !important;
      margin-left: 0 !important;

      font-size: $font-size-xs !important;

      @include tablet {
        width: 90% !important;
      }

      &--error {
        @extend .modalCreateOptions__form-description;
        border-color: $errorColor !important;
      }
    }

    &__actions {
      text-align: center !important;
      padding-top: 2rem !important;

      &-buttonFilled {
        width: 15rem;
        height: 3.5rem;
        background: $color-primary !important;
        padding: 8px 24px;
        font-family: $font-family-text;
        color: $white !important;
        font-size: 12px !important;
        font-weight: bold;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.4s;
        border: 1px solid $color-primary !important;
        text-transform: none !important;

        @include tablet {
          width: 20rem;
          margin: 0rem 4rem 1.5rem 0 !important;
        }
      }

      &-buttonOutline {
        width: 15rem;
        height: 3.5rem;
        background: transparent !important;
        padding: 8px 24px;
        font-family: $font-family-text;
        color: $color-primary !important;
        font-size: 14px !important;
        font-weight: bold;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.4s;
        border: 1px solid $color-primary !important;
        margin-right: 1rem !important;
        text-transform: none !important;

        @include tablet {
          width: 20rem;
          margin: 0.5rem 4rem 1.5rem 0 !important;
        }
      }
    }

    .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
      color: rgba(0, 0, 0, 0.6) !important;
    }

    &__inputLabel {
      font-size: 13px !important;
      margin: 10px 0 0 -10px;
      padding: 0 0 0 5px !important;
      color: $grey !important;

      @include tablet {
        margin-top: 0px;
      }
    }
  }
}

// Modal crear Recargas de aliados
.modal-createConfig {
  max-width: 1500px !important;
  max-height: 600px !important;
  width: 95%;
  height: 75%;
  box-shadow: none;
  border-radius: 22px;
  @include tablet {
    max-height: 860px !important;
  }
  @include phone {
    width: 100%;
    max-height: 575px !important;
    height: 20%;
  }

  &__edit {
    @extend .modal-createConfig;
    @include tablet {
      max-height: 800px !important;
    }
    @include phone {
      width: 100%;
      max-height: 575px !important;
      height: 20%;
    }
  }
}

.title-container {
  padding-top: 48px !important;
  @include tablet {
    padding-top: 24px !important;
  }
}
