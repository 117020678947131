@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
.changePasswordFT {
  &__wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background: #fff;

    @include tablet {
      display: flex;
      flex-wrap: wrap !important;
      flex-direction: column-reverse;
      justify-content: start !important;
      padding: 4rem 2rem;
    }
    @include phone {
      flex-direction: column-reverse;
      flex-wrap: nowrap !important    ;
      width: 100% !important;
      padding: 2rem 1rem;
      overflow: scroll;
    }
  }
  &__leftSide {
    width: 50%;
    max-height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: space-between;
    padding: 4rem 5rem !important;
    background-color: $login-background-color;
    background-image: url("../../assets/common/gridImage.svg");

    @include tablet {
      width: 100%;
      height: 67%;
      border-bottom-right-radius: 0;
    }
    @include phone {
      width: 85%;
      height: 70%;
      align-self: center;
      margin-bottom: 7rem;
      border-radius: 25px;
    }

    &-instructions {
      color: $heading-color;
      font-size: $font-size-default;
      font-family: $font-family-text;
      text-decoration: none !important;
      line-height: 32px;
      width: 100% !important;
      padding-left: 0px !important;

      @include tablet {
        padding: 0px;
      }
      &--mainTitle {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 1.5rem;
      }

      &--instructionSet {
        width: 100% !important;
        padding: 0;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }

    &-required {
      display: flex;
      align-items: center;
      font-size: 28.75px;
      padding: 0px 8px 0px 0px;
      color: $bright_orange !important;
    }

    &-check {
      display: flex;
      align-items: center;
      font-size: 28.75px;
      padding: 0px 8px 0px 0px;
      color: $green-500 !important;
    }
  }

  &__rightSide {
    width: 50%;
    max-height: 100%;
    background: #fff !important;
    display: flex;
    align-items: start !important;
    justify-content: center;
    background-color: $background-color;
    padding: 5rem !important;

    @include tablet {
      align-self: start !important;
      padding: 5rem 0rem !important;
      width: 90%;
      height: 50% !important;
      margin: 0% 5%;
      border-bottom-right-radius: 0;
    }
    @include phone {
      width: 95%;
      margin: 0 2.5%;
      padding: 1rem 2rem !important;
      height: 50% !important;
    }
    @media (min-width: 1367px) {
      padding: 8.5rem 7rem !important;
    }
  }

  &__head-container {
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    align-self: start !important;
    width: 100%;
    padding-bottom: 3rem !important;
    @include tablet {
      justify-content: space-between !important;
      padding: 0 2rem;
      padding-bottom: 18rem;
    }
    @include phone {
      padding: 0;
      padding-bottom: 3rem;
    }
    @media (min-width: 1367px) {
      padding-bottom: 3.6rem !important;
    }
  }

  &__title {
    text-align: left;
    padding-top: 0rem;
    padding-bottom: 3.6rem !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    @media (min-width: 1367px) {
      padding-bottom: 5.6rem !important;
      font-size: 42px !important;
    }
    @include tablet {
      font-size: 47px !important;
      line-height: 58px;
      padding-bottom: 1.5rem !important;
    }
    @include phone {
      line-height: 32px;
      font-size: 28px !important;
      padding-bottom: 1rem !important;
    }
  }

  &__instructions {
    text-align: left;
    font-size: $font-size-heading3 !important;
    font-weight: 400;
    color: black;
    line-height: 24px !important;
    padding-left: 0px !important;
    @include tablet {
      line-height: 24px !important;
      font-size: 16px;
      padding-bottom: 1rem;
    }
    @include phone {
      font-size: 14px;
      line-height: 20px !important;
      padding-bottom: 1rem !important;
    }
  }

  &__form {
    padding: 0 1rem 1rem 1rem !important;
    justify-content: center !important;
    align-items: center;
    justify-self: center !important;
    width: 90% !important;

    @media (min-width: 1367px) {
      width: 65% !important;
    }

    @include tablet {
      width: 80% !important;
    }

    @include phone {
      width: 100% !important;
      padding: 1rem 0 !important;
    }

    &-container {
      width: 100% !important;
      @include phone {
        padding-left: 0px !important;
        padding-top: 0px !important;
      }
      @media (min-width: 1367px) {
        padding: 0px !important;
      }
    }
    &-responsive-box {
      display: flex;
      text-align: left;
    }
    &-responsive--label {
      display: none;

      text-align: left;
      width: 263px;
    }
    &-errorText {
      color: $errorColor;
      font-weight: 400;
      text-align: left;
      font-size: $font-size-xs;
      padding-left: 1rem;
    }

    &-input {
      text-align: left;
      align-content: center;
      background-color: white !important;
      height: 100% !important;
      width: 100% !important;
      border: 1.5px solid $grey !important;
      border-radius: 8px !important;
      margin: 1rem 0 0.5rem 0 !important;
      font-size: $font-size-sm !important;
      &:focus {
        border: 1px solid $color-primary !important;
      }
      @include tablet {
        width: 100% !important;
        height: 44px !important;
        padding: 1.6 0.5rem !important;
      }
      @include phone {
        width: 100% !important;
        margin: 2rem 0 0rem 0 !important;
        height: 44px !important;
        padding: 1.6rem 0.2rem !important;
        margin: 1rem 0 0.5rem 1 !important;
      }
      @media (min-width: 1367px) {
        height: 45px;
        width: 100%;
      }

      &--error {
        @extend .changePasswordFT__form-input;
        border-color: $errorColor !important;
        margin-top: 0px !important;
        @include tablet {
          margin-top: 0px !important;
        }
        @include phone {
          margin-top: 0px !important;
        }
      }
      &--focus {
        @extend .changePasswordFT__form-input;
        border-color: #553ab6 !important;
      }

      &__container {
        margin-bottom: 1rem !important;
        align-items: center !important;

        @include tablet {
          margin: 1rem 0rem !important;
        }

        @media (min-width: 1367px) {
          margin-bottom: 2rem !important;
          width: 100% !important;
          height: 44px !important;
        }
      }
    }
    &-button {
      width: 294px;
      height: 48px !important;
      background: $color-primary;
      padding: 8px 24px;
      font-family: $font-family-text;
      color: white;
      font-size: 14px;
      font-weight: bold;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.4s;
      border: 2px solid transparent;
      margin-left: 1rem !important;

      @media (min-width: 1920px) {
        width: 60%;
      }

      @include tablet {
        width: 56% !important;
      }

      @include phone {
        margin: 0px !important;
        width: 100% !important;
      }
      &--disabled {
        @extend .changePasswordFT__form-button;
        background: $grey-disable;
        color: $grey;
        cursor: default;
      }
    }
  }
}

.display-large-changePassword {
  font-family: $font-family-text !important;
  font-weight: $weight-medium !important;
  font-size: 15.5px !important;
  line-height: $line-height-large !important;
  text-align: center;

  @include tablet {
    width: 100%;
    line-height: $line-height-large-mobile;
    padding-bottom: 2rem;
    font-size: 15.5px !important;
    text-align: center !important;
  }
  @include phone {
    font-size: 12px !important;
    font-weight: 100 !important;
    padding-right: 0rem !important;
    padding-bottom: 0rem !important;
    font-size: 15.5px !important;
    text-align: center;
  }

  &__text-white {
    @extend .display-large-changePassword;
    color: $text-color-white;
    @include tablet {
      text-align: center !important;
    }
    @include phone {
    }
  }
}

.display-small-changePassword {
  font-family: $font-family-text;
  font-weight: bold !important;
  font-size: $font-size-small;
  line-height: $line-height-small;

  &__text-white {
    @extend .display-small-changePassword;
    color: $text-color-white;
  }
  @include phone {
    font-weight: 100 !important;
    font-size: 12px !important;
  }
}
.changePasswordFT_one {
  &__form {
    padding: 1rem;
    align-items: center;
    text-align: center;

    &-responsive-box {
      display: flex;
      text-align: left;
    }
    &-responsive--label {
      display: none;

      text-align: left;
      width: 263px;
    }
    &-errorText {
      color: $errorColor;
      text-align: left;
      font-size: $font-size-xs;
      font-weight: 400;
      @include phone {
        font-size: 10.5px !important;
      }
    }
  }
}

.accept-terms {
  font-family: $font-family-text !important;
  font-size: $font-size-default !important;
  font-weight: $weight-medium !important;
  line-height: $line-height-heading !important;
  position: relative;
  padding-left: 0px !important;

  @include tablet {
    font-size: $font-size-sm !important;
    line-height: 24px !important;
  }
  @include phone {
    font-size: $font-size-sm !important;
    line-height: 20px !important;
  }
}

.approved-container {
  display: flex;
  justify-content: start;
  height: 24px !important;
  margin: 1rem 0px !important;
  // width: 100% !important;

  &__text {
    border-radius: 3px;
    font-weight: $weight-bold;
    font-size: $font-size-sm;
    line-height: 20px;
    padding: 2px 4px;
    color: $successColor;
    background: rgba(60, 213, 129, 0.08);
  }
}

.password-labels {
  @extend .form__label;
  font-family: Roboto !important;
  font-size: 12px !important;
  translate: 0px -6px;
  align-items: center;
  padding: 4px;
  transform-origin: -45px -40px;
  color: $grey;
  margin-bottom: -8px;
}
