.noMatchImage {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  margin-top: 1.5rem;

  &_title {
    @extend .heading-secondary;
    text-align: center;
    padding-bottom: 1.5rem !important;
  }

  &_description {
    @extend .subHeading-primary;
    text-align: center;
    font-weight: 200;
    width: 25rem;
    @include phone {
      width: 21rem;
      font-size: 14px;
    }
  }

  &_instruction {
    @extend .default-text_black__bold;
    margin-top: 1.5rem;
  }

  &_img {
    width: 35%;

    @include tablet {
      width: 65%;
    }

    @include phone {
      width: 100%;
    }
  }
}
