.choose-icons-insight {
  background-color: $white-color;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  border-radius: 12px;
  padding: 20px;
}
