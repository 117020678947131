.navbar {
  padding: 1.5rem;
  box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: white !important;
  &__text {
    font-size: $font-size-sm;
    font-family: $font-family-text;
    color: $color-primary;
    font-style: normal;
    font-weight: 500;
  }

  &__mobile {
    @extend .navbar;
    padding: 0 !important;
    box-shadow: none !important;
    text-align: start !important;
    font-size: $font-size-sm;
    font-family: $font-family-text;
    color: $color-primary;
    font-style: normal;
    font-weight: 500;
  }

  &__description {
    font-size: $font-size-xxs;
    font-family: $font-family-text;
    color: #8c8d9d !important;
  }

  &__logo {
    margin-left: 5rem;
    margin-bottom: 1.5rem;
  }

  &__logo-top {
    margin-left: 1.1rem;
    width: 17rem;
  }

  &__menu-icon {
    display: none;

    @include tablet {
      display: block;
    }
  }
}

.css-1sh1eby-MuiGrid-root {
  @include tablet {
    justify-content: space-between;
  }
}

.MuiButton-containedPrimary {
  background-color: inherit !important;
  font-size: $font-size-sm !important;
  font-family: $font-family-text !important;
  color: $color-primary !important;
  font-style: normal !important;
  font-weight: 500 !important;
  text-transform: none !important;
  box-shadow: none !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: $color-primary !important;
}
.logout_container {
  padding: 1.5rem;

  &__textTitle {
    color: $primary-color-one;
    font-family: $font-family-text !important;
    font-weight: 500 !important;
    font-size: $font-size-sm;
  }

  &__text {
    color: $text-color-grey;
    font-family: $font-family-text !important;
    font-weight: 400 !important;
    font-size: $font-size-xs;
    &__two {
      @extend .logout_container__text;
      color: $text-color-default;
      font-weight: 700 !important;
    }
  }

  &__textLogout {
    font-family: $font-family-text !important;
    font-weight: 500 !important;
    font-size: $font-size-sm;
    color: $color-primary;
  }
}
.MuiPopover-paper {
  width: 220px !important;
  height: 134px !important;
}

.hamburguer-menu {
  color: $color-primary !important;
}
