.modal-box-seeCompanies {
  background-color: $white-color;
  border-radius: 12px;
  left: 50%;
  padding: 30px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  border: 1px solid $primary-color-one;

  @include tablet {
    width: 50%;
  }

  @include phone {
    width: 70%;
  }
}

.box-list {
  border: 1px solid $grey-color;
  border-radius: 12px;
  padding: 12px;
  background-color: #ffff !important;
  width: 28rem;
} 

.popover > * {
  width: 28rem !important ;
  box-shadow: none !important;
  background-color: transparent !important;
}