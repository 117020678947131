.load-container {
  @extend .col-end;
  column-gap: 3% !important;
}

.geo-title-cont {
  margin-bottom: 2% !important;
}

.geo-filter-cont {
  margin-bottom: 2% !important;

  @include phone {
    width: 100%;
  }
}

.geo-upload-btn {
  @include phone {
    width: 100%;
  }
}

.geo-upload-text {
  @include phone {
    display: flex;
    justify-content: flex-end !important;
  }
}

.load-geo-cont {
  max-height: 5rem !important;
}

.filter-fields {
  background-color: white !important;
  width: 296px !important;
  height: 44px !important;
  border-radius: 5px;
  border: 1px solid $grey !important;

  &-errorText {
    @extend .filter-fields;
    border-color: $errorColor !important;
  }
}

.table-buttons {
  &__background {
    background-color: #f4f6fa !important;
  }

  &__primary {
    color: $color-primary;
  }
}

.proccessing {
  @extend .col-flex;
  margin-top: 6%;
}

.back-container {
  padding: 0 !important;
  margin-top: 1% !important;

  @include phone {
    margin-top: 5% !important;
    margin-bottom: 2% !important;
  }
}

.scroll-table {
  @include tablet {
    display: flex;
    width: auto;
    overflow-x: auto;
    scrollbar-color: $color-primary !important;
  }

  @include phone {
    display: flex;
    width: 31rem;
    overflow-x: scroll;
    scrollbar-color: $color-primary !important;
  }

  &__nocontent {
  }
}

.no-scroll-table {
  overflow-x: hidden !important; // Evita el scroll horizontal
  overflow-y: hidden !important;
  @include tablet {
    display: flex;
    overflow-x: auto !important; // Evita el scroll horizontal
    overflow-y: auto !important;
  }

  @include phone {
    display: flex;
    width: 31rem;
    overflow-x: hidden !important; // Evita el scroll horizontal
    overflow-y: auto !important;
    }
}

.scroll-table-contacts {
  @include tablet {
    display: flex;
    width: 100% !important;
    overflow-x: auto;
    scrollbar-color: $color-primary !important;
  }

  @include phone {
    display: flex;
    width: 100% !important;
    overflow-x: scroll;
    scrollbar-color: $color-primary !important;
  }
}

.scroll-table::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.scroll-table::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.scroll-table::-webkit-scrollbar-thumb {
  background: #dfdfdf;

  border-radius: 10px;
}
