.autoComplete {
  &__downloadSalesForce {
    border: 1px solid $color-primary !important;
    padding: 0 !important;
    width: 500px !important;
    border-radius: 8px !important;
    @include tablet {
      width: 200px !important;
      height: auto;
    }
  }
  &__downloadTrader {
    border: 1px solid $color-primary !important;
    padding: 0 !important;
    width: 100% !important;
    border-radius: 8px !important;
    @include tablet {
      width: 100% !important;
      height: auto;
    }
    @include phone {
      width: 100% !important;
      padding: 0 !important;
      height: auto;
    }
  }
  &__deleteAndReload {
    border: 1px solid $color-primary !important;
    border-radius: 8px !important;
    width: 100%;
    height: 44px;
    background-color: white !important;
    padding-bottom: 5px !important;
    &__error {
      @extend .autoComplete__deleteAndReload;
      border: 1px solid $errorColor !important;
    }
    @include tablet {
      width: 100% !important;
    }
    @include phone {
      width: 100% !important;
    }
  }
}

.MuiAutocomplete-tag {
  background-color: #f3f0ff !important;
  border-radius: 8px !important;
  font-size: $font-size-xs !important;
  height: 2.5rem !important;
  padding: 5px !important;
}

.MuiAutocomplete-tag-contact {
  background-color: #f3f0ff !important;
  border-radius: 8px !important;
  font-size: $font-size-xs !important;
  height: 2.5rem !important;
  padding: 5px !important;
  width: 30% !important;
  @include tablet {
    width: 40% !important;
  }
  @include phone {
    width: 50% !important;
  }
}

.MuiAutocomplete-inputRoot {
  padding-top: 5px !important;
}

.MuiChip-deleteIcon {
  color: #553ab6 !important;
}

//Ancho de filtros
.autoComplete__filter {
  @include tablet {
    width: 100% !important;
  }
  @include phone {
    width: 100% !important;
  }
}

.field_container {
  @include tablet {
    width: 100% !important;
  }
  @include phone {
    width: 100% !important;
  }
  &__block {
    @include tablet {
      justify-content: center !important;
      width: 100% !important;
      margin: 0% !important;
    }
    @include phone {
      padding: 2rem 0 0 0 !important;
      width: 100% !important;
      margin: 0%;
    }
  }
}

.form__container {
  @include tablet {
    width: 65% !important;
  }
  @include phone {
    margin: 0 !important;
    width: 100% !important;
  }
}

.reloadsForm {
  &__field {
    @include tablet {
      padding: 1rem 1rem 0 1rem !important;
    }
    @include phone {
      padding-right: 0 !important;
    }
  }
  &__block {
    @include tablet {
      padding-top: 1rem !important;
    }
  }
}

.filter-BILayers {
  &__container {
    @include phone {
      margin-left: -8px !important;
    }
  }
  &__field-container {
    padding: 1rem 1rem 1rem 1.5rem !important;
    @include tablet {
      padding: 1rem 1rem 1rem 1.5rem !important;
    }
    @include phone {
      margin: 0 !important;
      padding: 1rem 0 !important;
    }
  }
}
