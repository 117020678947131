.login {
  &__wrap {
    display: flex;
    flex-direction: row;
    min-width: 100%;
    min-height: 100%;
    box-shadow: 0px 14px 14px 2px rgba(0, 0, 0, 0.1);
    border-radius: 32px;
    background-color: #fff;
    @include tablet {
      align-items: center;
      min-height: 100% !important;
    }
    @media (min-width: 1920px) {
      min-height: 100% !important;
    }
  }
  &__leftSide-support {
    width: 50%;
    max-height: 50% !important;

    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: space-between;
    padding: 4rem;
    background-color: #1d0d58;
    background-image: url("../../assets/common/gridImage.svg");
    @include tablet {
      display: none;
    }
  }

  &__leftSide {
    width: 50%;
    max-height: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: space-between;
    padding: 4rem 2rem;
    background-color: $login-background-color;
    background-image: url("../../assets/common/gridImage.svg");
    @include tablet {
      display: none;
    }
  }

  &__rigthSide {
    width: 50%;
    max-height: 100%;
    background: #fff !important;
    display: flex;
    align-items: start !important;
    justify-content: center;
    background-color: $background-color;
    padding: 6rem 4.5rem;

    @include tablet {
      width: 100%;
      height: 100%;
      border-bottom-right-radius: 0;
    }
    @include phone {
      width: 100%;
      height: 100%;
      padding: 3rem;
    }
    &__support {
      @extend .login__rigthSide;
      padding: 7.7rem 8rem !important;
      @include tablet {
        padding: 2rem 5rem !important;
      }
      @include phone {
        padding: 1rem 3rem !important;
      }
    }
  }
  &__head-container-pasword {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
    padding: 0 2rem;
    padding-bottom: 0rem;
    position: absolute;
    top: 3rem;
    left: 2rem;

    @include tablet {
      .login__logo {
        display: none !important;
      }
    }

    @include phone {
      .login__logo {
        display: none !important;
      }
    }
    @media (min-width: 1920px) {
      padding-bottom: 15rem;
    }
  }
  &__head-container-support {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0 2rem;
    padding-bottom: 0rem;
    @include tablet {
      justify-content: space-between !important;
      padding: 0 2rem;
      padding-bottom: 2rem;
    }
    @include phone {
      padding: 0 2rem;
      padding-bottom: 6rem;
    }
    @media (min-width: 1920px) {
      padding-top: -15rem;
      padding-bottom: 0rem;
    }
  }

  &__logo {
    height: 36px !important;
  }

  &__head-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: start !important;
    width: 100%;
    padding-bottom: 3.6rem;
    @include tablet {
      justify-content: space-between !important;
      padding: 0 2rem;
      padding-bottom: 18rem;
    }
    @include phone {
      padding: 0 2rem;
      padding-bottom: 6rem;
    }
    @media (min-width: 1920px) {
      padding-bottom: 15rem;
    }
  }

  &__title {
    text-align: left;
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    @include tablet {
      font-size: 47px !important;
      line-height: 58px;
      padding-bottom: 3rem !important;
    }
    @include phone {
      line-height: 31px;
      font-size: 28px !important;
      padding-top: 0px;
      padding-bottom: 1rem !important;
    }
    @media (min-width: 1367px) {
      font-size: 48px;
      font-weight: 700;
      line-height: 58px;
    }
    &-support {
      @extend .login__title;
      text-align: left;
      padding-top: 0.5rem;
      padding-bottom: 1.5rem;
      font-size: 21px;
      font-weight: 800;
      line-height: 23px;
      @include tablet {
        font-size: 47px !important;
        line-height: 58px;
        padding-bottom: 1.5rem !important;
      }
      @include phone {
        line-height: 31px;
        font-size: 28px !important;
        padding-top: 0px;
        padding-bottom: 1rem !important;
      }
      @media (min-width: 1920px) {
        font-size: 42px !important;
        line-height: 59px !important;
        padding-bottom: 1.5rem !important;
        padding-top: 3.5rem;
      }
    }
  }
  &__subtitle {
    text-align: left;
    font-size: 16x;
    font-weight: 400;
    color: $grey;
    padding-bottom: 2rem;
    line-height: 19px !important;
    @include tablet {
      line-height: 32px !important;
      font-size: 20px;
      padding-bottom: 4rem;
    }
    @include phone {
      font-size: $font-size-sm;
      line-height: 20px !important;
      padding-bottom: 3rem;
    }
    @media (min-width: 1920px) {
      font-size: 21px !important;
      line-height: 32px !important;
      font-weight: 500;
      padding-bottom: 6rem;
    }
    &-support {
      @extend .login__subtitle;
      font-size: 19.5px;
      line-height: 26px !important;
      @include tablet {
        line-height: 32px !important;
        font-size: 20px;
        padding-bottom: 4rem;
      }
      @include phone {
        font-size: $font-size-sm;
        line-height: 20px !important;
        padding-bottom: 3rem;
      }
      @media (min-width: 1920px) {
        font-size: 20px !important;
        line-height: 30px !important;
        font-weight: 400;
        padding-bottom: 2rem;
      }
    }
  }

  &__form {
    padding: 0rem;
    align-items: center;
    text-align: center;

    &-responsive-box {
      display: flex;
      text-align: left;
    }
    &-responsive--label {
      display: none;

      text-align: left;
      width: 263px;
    }
    &--forgotBox {
      display: flex;
      text-align: left;
    }
    &--forgotLabel {
      display: none;
      width: 263px;
    }
    &-input {
      text-align: left;
      align-content: center;
      transition: all 0.2s !important;
      background-color: white !important;
      padding: 2rem 1.2rem !important;
      height: 42px !important;
      width: 95% !important;
      border: 1.5px solid $grey !important;
      border-radius: 8px !important;
      margin: 1.8rem 0 0.5rem 0 !important;
      font-size: $font-size-sm !important;
      &-support {
        width: 100%;
      }
      &:focus {
        border: 1px solid $color-primary !important;
      }
      @include tablet {
        width: 100% !important;
        padding: 2.6rem 0.5rem !important;
      }
      @include phone {
        width: 100% !important;
        margin: 2rem 0 0rem 0 !important;
        padding: 2.6rem 0.2rem !important;
        margin: 1.8rem 0 0.5rem 1 !important;
      }
      @media (min-width: 1367) {
        height: 45px;
        width: 90%;
        margin: 1.8rem 0 0rem 0 !important;
        padding: 2.5rem 1.2rem !important;
      }

      &--error {
        @extend .login__form-input;
        border-color: $errorColor !important;
        &-support {
          width: 100%;
        }
      }
      &--focus {
        @extend .login__form-input;
        border-color: #553ab6 !important;
        &-support {
          width: 100%;
        }
      }
    }
    &-button {
      width: 294px;
      height: 44px;
      background: $moderate_blue;
      padding: 6px 16px;
      margin: 0rem;
      font-family: $font-family-text;
      color: white;
      font-size: 14px;
      font-weight: bold;
      border-radius: 8px;
      cursor: pointer;
      transition: 0.4s;
      border: 2px solid transparent;
      @include tablet {
        height: 48px !important;
        width: 70% !important;
        margin: 1rem;
      }
      @include phone {
        width: 85% !important;
        height: 42 !important;
        margin: 0.5rem 0 !important;
      }
      @media (min-width: 1920px) {
        width: 64% !important;
        margin-top: 2rem;
      }
      &--support {
        @extend .login__form-button;
        width: 90px !important;
        height: 40px !important;
        @include tablet {
          height: 48px !important;
          width: 90px !important;
          margin: 1rem;
        }
        @include phone {
          width: 220px !important;
          height: 42px !important;
          margin-top: 2rem !important;
        }
        @media (min-width: 1920px) {
          width: 101px !important;
          padding: 14px 24px 14px 24px;
          height: 48px !important;
          margin-top: 0rem !important;
        }
        &_cancel {
          @extend .login__form-button;
          width: 90px !important;
          height: 40px !important;
          color: #553ab6 !important;
          margin-right: 3rem;
          background-color: rgba(161, 137, 249, 0.15) !important;
          @include tablet {
            height: 48px !important;
            width: 90px !important;
            margin: 1rem;
            margin-right: 2rem;
          }
          @include phone {
            width: 220px !important;
            height: 42px !important;
            margin-top: 2rem !important;
          }
          @media (min-width: 1920px) {
            width: 104px !important;
            height: 48px !important;
            margin-top: 0rem !important;
            margin-right: 3rem;
            padding: 14px 24px 14px 24px;
          }
        }
        &_one {
          @extend .login__form-button;
          border-radius: 10px;
          width: 310px;
          height: 40.5px;

          @include phone {
            width: 270px !important;
            height: 42 !important;
            margin: 0.5rem 0 !important;
          }
          @media (min-width: 1920px) {
            width: 330px !important;
            padding-top: 1rem !important;
            height: 40.5px !important;
          }
        }
      }
      &--disabled {
        @extend .login__form-button;
        background: $grey-disable;
        color: $grey;
        cursor: default;
        &-one {
          @extend .login__form-button--disabled;
          width: 310px;
          height: 40.5px;
          border-radius: 8px;
          @include phone {
            width: 270px !important;
            height: 42px !important;
          }
          @media (min-width: 1920px) {
            width: 330px !important;
            padding-top: 1rem !important;
            height: 40.5px !important;
          }
        }
        &-support {
          @extend .login__form-button--disabled;
          width: 90px;
          height: 40px;
          @media (min-width: 1920px) {
            width: 101px !important;
            padding: 14px 24px 14px 24px;
            height: 48px !important;
            margin-top: 0rem !important;
          }
        }
      }
    }
    &-buttontwo {
      width: 424px;
      background: $color-primary;
      padding: 8px 24px;
      font-family: $font-family-text;
      color: white;
      font-size: 14px;
      font-weight: bold;
      border-radius: 4px;
      cursor: pointer;
      transition: 0.4s;

      border: 4px solid transparent;
    }
  }
  &__helpBox {
    position: absolute;
    top: 95%;
    left: 92%;
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
  &__text {
    align-items: center;
    text-align: center;
    width: 424px !important;

    @include tablet {
      width: 60% !important;
    }
  }
}

.logo-CENAnalíticos {
  height: 36px !important;
  @include tablet {
    height: 45px !important;
  }
  @include phone {
    height: 28.62px !important;
  }
  @media (min-width: 1920px) {
    height: 52px !important;
  }
  &__support {
    @extend .logo-CENAnalíticos;
    @media (min-width: 1920px) {
      width: 234px;
      height: 36px;
    }
  }
}

iframe {
  transform: scale(0.9);
  transform-origin: 0 0;

  @include phone {
    transform: scale(0.7);
    transform-origin: 0 0;
  }
}
.recaptcha_container > div > div > div > div {
  padding-left: 2rem;
  padding-top: 0rem;
  @include phone {
    padding-left: 0.9rem; // Elimina cualquier padding en la vista móvil
    max-height: 100% !important;
    max-width: 100% !important;
    margin: 0 auto; // Centra el componente horizontalmente
    display: block;
  }
}

.login__container {
  padding: 0 2rem !important;
  justify-content: center;
  width: 500px !important;
  &__divider {
    padding-top: 1rem !important;
  }
  &__message {
    padding: 0 1.5rem !important;
    font-size: 12px !important;
    @include tablet {
      font-size: 16px !important;
      justify-content: center;
      padding: 0 0 2rem 0 !important;
    }
    @include phone {
      width: 50%;
      font-size: 13px !important;
      padding: 0 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    @media (min-width: 1920px) {
      font-size: 20px !important;
      padding: 1 0rem !important;
      padding-bottom: 2rem !important;
    }
  }
  @include tablet {
    .modal-button-action {
      width: 100% !important;
    }
  }
  @include phone {
    padding: 0 0 !important;
    width: 100% !important;
    .modal-button-action {
      width: 100% !important;
      padding: 0 2rem !important;
    }
  }
  @media (min-width: 1920px) {
    width: 600px !important;
  }
}

//Sección de registro
.register {
  &__container {
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 70%;
    @include tablet {
      width: 70%;
    }
    @include phone {
      width: 85%;
    }
  }
  &__button {
    font-size: $font-size-xs !important;
    display: flex;
    text-transform: none !important;
    justify-content: end !important;
    justify-items: end !important;
    color: $moderate_blue !important;
  }
}

.login__text {
  display: flex;
  align-items: center;
  &__message {
    font-size: small;
    font-weight: bold;
  }
}

//Login de soporte
.loginSup__form {
  @include tablet {
    width: 50%;
  }
  @include phone {
    width: 70%;
  }
}

.loginSup__elements {
  display: flex;
  justify-content: center;
  @include tablet {
    width: 100% !important;
  }
  @include phone {
    width: 100% !important;
  }
}

.loginSup__button {
  display: flex;
  justify-content: center;
  @include tablet {
    width: 100% !important;
  }
  @include phone {
    width: 100% !important;
  }
}

.bubble-login {
  object-fit: contain !important;
  @include tablet {
    height: 100px !important;
  }
  @include phone {
    height: 50px !important;
  }
  &__container {
    padding-top: 3rem !important;
    margin-left: -5rem !important;
    width: 100%;
    display: flex;
    justify-items: start;
    @include tablet {
      margin-left: -3rem !important;
    }
    @include phone {
      padding-top: 3rem !important;
    }
  }
}

.color {
  color: $grayish_blue !important;
}

.loginImage {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  margin-top: 1.5rem;

  &-support {
    margin-top: 1rem;
    width: 550px !important;
    @media (min-width: 1920px) {
      width: 800px !important;
    }
  }

  &_title {
    @extend .heading-secondary;
    text-align: center;
    color: white;
    padding-bottom: 1.5rem !important;
    font-size: 23px;
    font-weight: 700;
    @media (min-width: 1920px) {
      font-size: 36px;
    }
  }
  &_titleSupport {
    text-align: center;
    color: white !important;
    padding-bottom: 2rem !important;
    font-size: 16px;
    font-weight: 200;
    @media (min-width: 1920px) {
      font-size: 26px !important;
    }
  }

  &_title-container {
    @extend .subHeading-primary;
    text-align: center;
    width: 30rem;
    @media (min-width: 1920px) {
      width: 50rem !important;
    }
  }

  &_instruction {
    @extend .default-text_black__bold;
    margin-top: 1.5rem;
  }

  &_img {
    width: 70%;
    z-index: 2;
    @include tablet {
      width: 65%;
    }

    @include phone {
      width: 100%;
    }
    @media (min-width: 1920px) {
      width: 100%;
    }
  }
  &_img-support {
    margin-left: 5rem;
    width: 78%;
    z-index: 2;
    @include tablet {
      width: 65%;
    }

    @include phone {
      width: 100%;
    }
    @media (min-width: 1920px) {
      width: 95%;
    }
  }
  &_img-container {
    display: flex;
    width: 100%;
  }

  &_pattern {
    position: absolute !important;
    top: 51.5%;
    right: 78%;
    z-index: 1;
    width: 17%;
    @media (min-width: 1367px) {
      width: 15%;
      top: 50%;
      right: 77%;
    }
  }
}

.login-error-message {
  color: $errorColor;
  font-size: $font-size-xs;
  font-family: $font-family-text;
  font-weight: 400;
  text-align: left;
  padding-left: 0.7rem;
  padding-top: 0.7rem;
  padding-bottom: 0.3rem;

  @include tablet {
    text-align: center;
  }
  &__small {
    font-size: 10px;
    font-weight: 400;
    color: $errorColor;
  }
  @include phone {
    font-size: $font-size-sm;
    padding: 0 !important;
  }
}

.recover-password {
  font-size: $font-size-sm;
  @include tablet {
    padding-top: 12px;
    font-size: $font-size-sm;
  }
  @include phone {
    font-size: $font-size-xs;
  }
  &__container {
    display: flex;
    width: 90%;
    justify-content: flex-end;
    @include tablet {
      width: 90%;
      margin-left: 1rem;
    }
  }
}

.second {
  @include phone {
    margin-bottom: 0 !important;
  }
}

.loginSupportContainer {
  padding-bottom: 0px;
  @include tablet {
    padding-bottom: 30rem;
  }
  @include phone {
    padding-bottom: 6rem;
  }
  @media (min-width: 1920px) {
    padding-bottom: 25rem;
  }
}
.LoginsupportP {
  padding-bottom: 1rem;
  @include tablet {
    padding-bottom: 2.5rem;
  }
  @include phone {
    padding-bottom: 3rem;
    padding-left: 3rem;
  }
  @media (min-width: 1920px) {
    padding-bottom: 2.5rem;
  }
  &-business {
    padding-bottom: 2rem;
    @include tablet {
      padding-bottom: 2rem;
    }
    @include phone {
      padding-bottom: 2rem;
      padding-left: 3rem;
    }
    @media (min-width: 1920px) {
      padding-bottom: 2rem;
    }
  }
}
.padingButton {
  padding-top: 1rem;
  @include tablet {
    padding-top: 2rem;
  }
  @include phone {
    padding-top: 1rem;
  }
  @media (min-width: 1920px) {
    padding-top: 0rem;
  }
}

.button__container-support {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  padding-bottom: 15px;
  @include phone {
    flex-direction: column; /* Alinea los botones en columna */
    gap: 8px; /* Puedes ajustar el espaciado */
    margin-top: 0px;
  }
}

.icon {
  font-size: 24px !important;
  color: $grey;
  &--error {
    font-size: 24px !important;
    color: $errorColor;
  }
  &--focus {
    font-size: 24px !important;
    color: $color-primary;
  }
}
