.btn {
  // TODO BACKGROUND DE BOTONES DEBE SER D4D4D4 (NUEVA VARIABLE)
  //ESTA VARIABLE SOLO ES DE BACKGROUND DISABLE DE BOTONES
  &__logout {
    text-transform: none !important;
    font-family: $font-family-text !important;
    font-size: $font-size-xs !important;
    font-weight: 400 !important;
    color: $text-color-grey !important;
    padding: 0% !important;
  }

  &__uploadNew {
    text-transform: none !important;
    color: $color-primary !important;
    font-size: $font-size-sm !important;
    font-weight: 5 00 !important;
    border: 1px solid $color-primary !important;
  }

  &__createConfig {
    font-size: $font-size-xs !important;
    text-transform: none !important;
    background-color: $color-primary !important;
    color: white !important;

    &-disabled {
      @extend .btn__applyFilter;
      background-color: $chinese_white !important;
    }

    @include phone {
      margin: 1rem 0 1rem 0 !important;
      text-align: center !important;
      max-width: 150rem !important;
      width: 100% !important;
    }
  }

  &__createCompany {
    font-size: $font-size-xs !important;
    text-transform: none !important;
    background-color: $color-primary !important;
    color: white !important;

    &-disabled {
      @extend .btn__createCompany;
      background-color: $chinese_white !important;
    }

    @include phone {
      text-align: center !important;
      max-width: 150rem !important;
      width: 100% !important;
    }
  }
  &__applyFilter {
    font-size: $font-size-xs !important;
    text-transform: none !important;
    background-color: $color-primary !important;
    color: white !important;

    &-disabled {
      @extend .btn__applyFilter;
      background-color: $chinese_white !important;
    }

    @include phone {
      margin: 1rem 4rem 1rem 0 !important;
      text-align: center !important;
      max-width: 150rem !important;
      width: 100% !important;
    }

    // Manejar div que contiene el botón

    &__container {
      @include phone {
        width: 100% !important;
      }
    }
  }

  &__outlined__blue__new {
    font-size: $font-size-xs !important;
    text-transform: none !important;
    background-color: transparent !important;
    color: $color-primary !important;
    border: 1px solid $color-primary !important;

    @include phone {
      margin: 1rem 4rem 1rem 0 !important;
      text-align: center !important;
      max-width: 150rem !important;
      width: 100% !important;
    }

    // Manejar div que contiene el botón

    &__container {
      @include phone {
        width: 100% !important;
      }
    }
  }
  &__outlined__see-company {
    height: 24px;
    width: 18px !important;
    text-transform: none !important;
    background-color: $tonal !important;
    color: $color-primary !important;
    border: 1px solid $primary-color-two !important;
    border-radius: 4px;
    font-family: $font-family-text !important;
    font-size: $font-size-xs !important;
    width: 100% !important;
    @include phone {
      text-align: center !important;
      width: 100% !important;
    }
  }
  &__applyFilterComerce {
    font-size: $font-size-xs !important;
    text-transform: none !important;
    background-color: $color-primary !important;
    color: white !important;

    &-disabled {
      @extend .btn__applyFilter;
      background-color: $chinese_white !important;
    }

    @include phone {
      margin: 1rem 1rem 1rem 1rem !important;
      text-align: center !important;
      max-width: 150rem !important;
      width: 100% !important;
    }

    // Manejar div que contiene el botón

    &__container {
      @include phone {
        width: 100% !important;
      }
    }
  }

  &__applyFilter-reload {
    font-size: $font-size-sm !important;
    font-weight: 700 !important;
    text-transform: none !important;
    background-color: $color-primary !important;
    color: white !important;
    width: 100% !important;

    &__icon {
      font-size: 20;
      color: white;
      width: 100%;

      &-disabled {
        font-size: 20;
        color: $grey;
        width: 100%;
      }
    }

    &-disabled {
      @extend .btn__applyFilter-reload;
      background-color: $grey-disable !important;
      color: $grey !important;
    }

    @include phone {
      margin: 1rem 0 1rem 0 !important;
      text-align: center !important;
      max-width: 150rem !important;
      width: 100% !important;
    }

    @include tablet {
      margin: 1rem 0 1rem 0 !important;
      width: 100% !important;
    }
  }

  &__deleteFilter-reload {
    font-size: $font-size-xs !important;
    text-transform: none !important;
    background-color: none !important;
    color: $color-primary !important;
    width: 100% !important;

    &__disabled {
      @extend .btn__deleteFilter-reload;
      color: $text-color-grey !important;
    }

    @include phone {
      text-align: center !important;
      max-width: 150rem !important;
      width: 100%;
    }

    @include tablet {
      // margin: 1rem 0 1rem 0 !important;
      width: 100% !important;
    }
  }

  &__deleteFilter {
    font-size: $font-size-xs !important;
    text-transform: none !important;
    background-color: none !important;
    color: $color-primary !important;

    &__disabled {
      @extend .btn__deleteFilter;
      color: $text-color-grey !important;
    }

    @include phone {
      margin: 1rem 4rem 1rem 0 !important;
      text-align: center !important;
      max-width: 150rem !important;
      width: 100%;
    }
  }

  &__users {
    font-size: $font-size-xs !important;
    text-transform: none !important;
    background-color: $color-primary !important;
    color: white !important;

    @include tablet {
      margin: 1rem 4rem 1rem 0 !important;
      width: 20rem;
    }
  }

  &__back {
    background-color: $color-primary !important;
    color: white !important;
    border-radius: 4px !important;
  }

  &__cancel {
    font-size: $font-size-xs !important;
    text-transform: none !important;
    background-color: white !important;
    color: $color-primary !important;
    border: 1px solid $color-primary !important;
  }
  &__outlinedExternal {
    &__blue {
      text-transform: none !important;
      font-family: $font-family-text !important;
      font-size: $font-size-xs !important;
      color: $color-primary !important;
      font-weight: 500 !important;
      border: 1.5px solid $color-primary !important;
      border-radius: 7px !important;
    }
  }
  &__outlined {
    &__blue {
      text-transform: none !important;
      font-family: $font-family-text !important;
      font-size: $font-size-xs !important;
      color: $color-primary !important;
      font-weight: 500 !important;
      border: 1px solid $color-primary !important;
    }

    &__orange {
      text-transform: none !important;
      font-family: $font-family-text !important;
      font-size: $font-size-xs !important;
      color: #e59500 !important;
      font-weight: 500 !important;
      border: 1px solid #e59500 !important;
    }

    &__black {
      text-transform: none !important;
      font-family: $font-family-text !important;
      font-size: $font-size-xs !important;
      color: $text-color-black !important;
      font-weight: 500 !important;
    }

    &__clean {
      text-transform: none !important;
      font-family: $font-family-text !important;
      font-size: $font-size-xs !important;
      color: $primary-color-one !important;
      font-weight: 500 !important;

      &__md {
        @extend .btn__outlined__clean;
        font-size: $font-size-subheading !important;
      }
    }
  }

  &__filled {
    &__gradient {
      text-transform: none !important;
      font-family: $font-family-text !important;
      font-size: $font-size-xs !important;
      color: $primary-color-one !important;
      font-weight: 500 !important;
      background-color: rgba(161, 137, 249, 0.15) !important;
    }
    &-support{
      @extend .btn__filled__gradient;
      text-transform: none !important;
      font-family: $font-family-text !important;
      font-size: $font-size-xs !important;
      color: $primary-color-one !important;
      font-weight: 500 !important;
      background-color: rgba(161, 137, 249, 0.15) !important;
      width: 90px !important;
      height: 40px !important; 
      @include tablet {
        height: 48px !important;
        width: 90px !important;
        margin: 1rem;
      }
      @include phone {
        width: 220px !important;
        height: 42px !important;
        margin-top: 2rem !important;
      }
      @media (min-width: 1920px) {
        width: 90px !important;
        height: 40px !important;
        margin-top: 0rem !important;
      }
    }
    &__gradient-contact {
      @extend .btn__filled__gradient;
      width: 100% !important;
    }
    &__blue {
      text-transform: none !important;
      font-family: $font-family-text !important;
      font-size: $font-size-xs !important;
      color: white !important;
      font-weight: 500 !important;
      background-color: $color-primary !important;
    }
    &__blue-contact {
      @extend .btn__filled__blue;
      width: 100% !important;
    }

    &__disabled {
      text-transform: none !important;
      font-family: $font-family-text !important;
      font-size: $font-size-xs !important;
      color: white !important;
      font-weight: 500 !important;
      border: 1px solid #c0c1d8 !important;
      background-color: #c0c1d8 !important;
    }

    &__disabled-contact {
      @extend .btn__filled__disabled;
      width: 100% !important;
    }

    &__light-blue {
      text-transform: none !important;
      font-family: $font-family-text !important;
      font-size: $font-size-xs !important;
      color: white !important;
      font-weight: 500 !important;

      background-color: $secondary-color-two !important;
    }
  }
}

.MuiChip-outlined {
  font-size: $font-size-sm !important;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px !important;
  background: #ffffff !important;
  border: none !important;
}
.button {
  &__primary {
    width: 294px !important;
    height: 48px !important;
    background-color: $primary-color-one !important;
    color: white !important;
    border-radius: 8px;
    padding: 1rem;
    align-content: center;
    margin-left: auto !important;
    margin-right: auto;
    font-size: $font-size-sm !important;
    font-weight: 700 !important;
    @include tablet {
      width: auto !important;
    }
    @include phone {
      width: 99% !important;
    }
  }
}

.download-btn {
  width: 100% !important;
  height: 45px !important;
  @include tablet {
    width: 100% !important;
    height: auto;
  }

  @include phone {
    width: 100% !important;
    padding: 0 !important;
    height: 45px !important;
  }
}

.btn-CEN {
  border-radius: 12px !important;
  box-shadow: none !important;
  &__RequestQuoteOutlinedIcon {
    background-color: $tertiary-color-two;
    font-size: 22px !important;
    box-shadow: 0px 2.4px 4.8px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    fill: white !important;
  }

  &__LocalAtmOutlinedIcon {
    background-color: $artyclick_sky_blue;
    font-size: 22px !important;
    box-shadow: 0px 2.4px 4.8px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    fill: white !important;
  }
}

//Botón de registro en login

.modal-button-action {
  background-color: rgba(161, 137, 249, 0.15) !important;
  color: #553ab6 !important;
  border-radius: 8px;
  border: 0 !important;
}

//Centrar botones de filtro Acceso indicadores
.btn__group {
  @include tablet {
    width: 35%;
    margin-left: 0 !important;
    padding-left: 1rem;
  }
  @include phone {
    display: flex;
    justify-content: center !important;
    width: 100% !important;
    margin-left: 0 !important;
    padding: 0 0.5rem 2rem 0.5rem !important;
  }
}

.btn__container {
  @include tablet {
    padding: 0% !important;
  }
}

.dimentions-btn {
  font-size: $font-size-sm !important;
  &__applyFilter {
    @extend .dimentions-btn;
    padding: 14px 24px !important;
    height: 48px !important;
    border-radius: 8px !important;
    font-weight: 700 !important;
    @include tablet {
      width: 100% !important;
    }
    @include phone {
      margin: 1rem 0 1rem 0 !important;
      text-align: center !important;
      width: 100% !important;
    }
    // Manejar div que contiene el botón
    &__container {
      @include tablet {
        width: 100% !important;
      }
      @include phone {
        width: 100% !important;
      }
    }
  }

  &__openModal {
    @extend .dimentions-btn__applyFilter;
    padding: 14px 24px !important;
    width: auto !important;
    margin: 0px !important;

    @include phone {
      width: 100% !important;
    }
  }

  &__cleanFilter {
    padding: 0 1rem !important;
    height: 48px !important;
    font-weight: 700 !important;
    border-radius: 8px !important;

    @include tablet {
      width: 100% !important;
    }
    @include phone {
      margin: 1rem 0 1rem 0 !important;
      text-align: center !important;
      max-width: 150rem !important;
      width: 100% !important;
    }

    // Manejar div que contiene el botón

    &__container {
      @include tablet {
        width: 100% !important;
      }
    }
  }
}

.btn__help {
  @extend .btn__outlined__blue;
  border-radius: 8px !important;
  background-color: $white !important;
  border-color: #dee0e4 !important;
  font-weight: $weight-medium !important;
  color: $text-color-default !important;

  &__selected {
    @extend .btn__outlined__blue;
    border-radius: 8px !important;
    background-color: #f4f6fa !important;
    font-weight: $weight-bold !important;
  }
}

.icon__help {
  background-color: #f4f6fa !important;
  color: $primary-color-one !important;

  &__selected {
    @extend .icon__help;
    background-color: $white !important;
    padding: 0rem;
  }
}

// Botón de paginación de mapeos

.mapping-page {
  &__container {
    padding: 2rem 0 !important;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__text {
    align-content: center;
    font-weight: 1000;
  }

  &__btn {
    background-color: rgba(85, 58, 182, 0.05) !important;
    color: $primary-color-one !important;
    height: 40px;
    width: 40px;
    border-radius: 3px !important;
  }

  &__btnContainer {
    display: flex;
    justify-content: space-between;
  }

  &__addField {
    @extend .btn__filled__blue;
    height: 48px;
    width: 90% !important;
    border-radius: 8px !important;

    @include phone {
      margin-bottom: 1rem !important;
    }
    &__disabled {
      @extend .mapping-page__addField;
      background-color: $chinese_white !important;
    }
  }
  &__removeField {
    @extend .btn__filled__blue;
    height: 48px;
    width: 90% !important;
    border-radius: 8px !important;
    color: $primary-color-one !important;
    background-color: rgba(85, 58, 182, 0.05) !important;
    &__disabled {
      @extend .mapping-page__removeField;
      color: $text-color-grey !important;
    }
  }
}

.allie-page {
  &__container {
    padding: 2rem 0 !important;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__text {
    align-content: center;
    font-weight: 1000;
  }

  &__btn {
    background-color: rgba(85, 58, 182, 0.05) !important;
    color: $primary-color-one !important;
    height: 40px;
    width: 40px;
    border-radius: 3px !important;
  }

  &__btnContainer {
    display: flex;
    justify-content: space-between;
  }

  &__addField {
    @extend .btn__filled__blue;
    height: 48px;
    width: 100% !important;
    border-radius: 8px !important;

    @include phone {
      margin-bottom: 1rem !important;
    }
    &__disabled {
      @extend .allie-page__addField;
      background-color: $chinese_white !important;
    }
  }

  &__removeField {
    @extend .btn__filled__blue;
    height: 48px;
    width: 100% !important;
    border-radius: 8px !important;
    color: $primary-color-one !important;
    background-color: rgba(85, 58, 182, 0.05) !important;
    &__disabled {
      @extend .allie-page__removeField;
      color: $text-color-grey !important;
    }
  }
}

.mapForm_container {
  padding: 1rem !important;
  @include tablet {
    padding: 1rem 3rem !important;
    margin: 0 !important;
  }
  @include phone {
    padding: 1rem !important;
  }
}

.mapping-btn__container {
  @include phone {
    padding-bottom: 1rem !important;
    width: 100% !important;
  }
}

.modal-decision-btn {
  padding: 14px 24px !important;
  border-radius: 8px !important;
  &__container {
    max-height: none !important;
  }
}
