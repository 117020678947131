.language_selector {
  color: $grey !important;
  font-size: $font-size-sm !important;
  font-weight: 500 !important;
  border: 1px solid #e5e5e5 !important;
  border-radius: 8px !important;
  width: 100% !important;
  height: 43px;
  justify-content: center !important;
  @include tablet {
    font-size: $font-size-xs !important;
    font-size: $font-size-xs !important;
    width: 100% !important;
    // top: 9% !important;
    // right: 10% !important;
  }
  @include phone {
    font-size: $font-size-xs !important;
    width: 100% !important;
    // top: 7% !important;
    // right: 10% !important;
  }
  @media (min-width: 1920px) {
    width: 100% !important;
    // position: absolute !important;90px
    // top: 7.5% !important;
    // right: 5% !important;
  }
  &__support{
    color: $grey;
    width: 100% !important;
    position: absolute !important;
    top: 4rem !important;
    right: 0rem !important;
  }
  &__pasword {
    //Estilos de recuperar contraseña (LanguageSwitcher )
    color: $grey;
    width: 100% !important;
    position: absolute !important;
    top: 4rem !important;
    right: 0rem !important;

    @include tablet {
      width: 100% !important;
      height: 43px;
      position: absolute !important;
      top: 33% !important;
      right: 0% !important;
    }
    @include phone {
      width: 100% !important;
      height: 43px;
      position: absolute !important;
      top: 33% !important;
      right: 0% !important;
    }
    @media (min-width: 1920px) {
      width: 100% !important;
      position: absolute !important;
      top: 5% !important;
      right: 0% !important;
    }
  }
  &__credentials {
    //Estilos de validar credenciales (LanguageSwitcher )
    color: $grey;
    width: 100% !important;
    position: absolute !important;
    top: 4rem !important;
    right: 0rem !important;

    @include tablet {
      width: 100% !important;
      height: 43px;
      position: absolute !important;
      top: 33% !important;
      right: 0% !important;
    }
    @include phone {
      width: 100% !important;
      height: 43px;
      position: absolute !important;
      top: 33% !important;
      right: 0% !important;
    }
    @media (min-width: 1920px) {
      width: 100% !important;
      position: absolute !important;
      top: 5% !important;
      right: 0% !important;
    }
  }
  &__changePasword {
    //Estilos de cambiar contraseña (LanguageSwitcher )
    color: $grey;
    width: 100% !important;
    position: absolute !important;
    top: 4rem !important;
    right: 0rem !important;
    @include tablet {
      width: 100% !important;
      height: 43px;
      position: absolute !important;
      top: 45rem !important;
      right: 0% !important;
    }
    @include phone {
      width: 95% !important;
      height: 20px !important;
      top: 27rem !important;
      right: 1.5rem !important;
    }
  }

  &:before {
    color: $grey !important;
  }
  .MuiSelect-select {
    color: $grey !important;
    padding: 15px !important;
    border-color: inherit !important;
    @include tablet {
      padding: 2px !important;
    }
  }
  .MuiSelect-icon {
    font-size: 2.5rem !important;
  }
}
.MuiMenu-list {
  font-family: $font-family-text;
  font-size: $font-size-sm !important;
  font-weight: 500 !important;
}

.MuiMenuItem-root {
  font-size: $font-size-sm !important;
}
fieldset {
  border: none !important;
  outline: none !important;
}
