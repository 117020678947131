.card-access-sales {
  border-radius: 16px !important;
  box-shadow: none !important;
  height: auto;
  width: 100%;
  border: 2px solid transparent;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .div-icon-title {
      display: flex;
      gap: 10px;
      align-items: center;

      .icon-button {
        color: $color-primary !important;
        background-color: #f3f0ff !important;
        cursor: auto !important;
        width: 35px;
        height: 35px;

        .icon {
          width: 20px;
        }
      }
    }

    .button-access-sales {
      margin-top: 2rem;
      background-color: $color-primary !important;
      color: $white-color !important;
      font-size: $font-size-button !important;
      border-radius: 8px !important;
      font-weight: $weight-bold !important;
      padding: 2px 10px !important;
      align-self: flex-end;

      @include tablet {
        font-size: $font-size-button-mobile !important;
      }
    }

    .info-access-sales {
      height: 148px !important;
      display: flex !important;
      flex-direction: column !important;
      justify-content: space-between !important;
    }
  }

  @include tablet {
    height: auto;
    width: auto;
  }
}

.text-access-sales {
  margin-bottom: 3%;
}

.card-access-sales:hover {
  border: 2px solid $artyclick_sky_blue;
}
