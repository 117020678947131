.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 80px;
  gap: 10px;

  width: 602px;
  height: 286.35px;

  background: $white;

  box-shadow: 0px 14px 14px 2px rgba(0, 0, 0, 0.1);
  border-radius: 24px;

  @include tablet {
    width: 580px;
    padding: 10px 20px;
  }

  @include phone {
    width: 327px;
    padding: 10px 20px;
  }
}

.container h3 {
  width: 152px;
  height: 32px;

  font-weight: 500;
  font-size: 28px;
  line-height: 32px;

  text-align: center;

  color: $color-primary;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.container p {
  width: 213px;
  height: 24px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: #2e3043;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.container-circles div {
  display: inline-block;

  @include tablet {
    margin: 0 5px;
  }

  @include phone {
    margin: 0 1px;
  }
}

.circle-woman,
.circle-man {
  position: relative;
}

.circle1,
.circle2,
.circle3,
.circle4,
.circle5,
.circle6 {
  border-radius: 100%;
  width: 43.39px;
  height: 43.39px;
  margin: 0 15px;

  background: #c0c1d8;
}

.woman-pc {
  position: absolute;
  width: 47.52px;
  height: 83.38px;
  left: 22px;
  bottom: 20px;

  @include tablet {
    left: 15px;
    bottom: 15px;
  }

  @include phone {
    left: 10px;
    bottom: 20px;
  }
}

.man-pc {
  position: absolute;
  width: 49px;
  height: 76.54px;
  left: 2px;
  bottom: 20px;

  @include tablet {
    left: -8px;
    bottom: 15px;
  }

  @include phone {
    left: -11px;
    bottom: 20px;
  }
}

.container-circles .circle1 {
  animation: animate 3s infinite linear both;
  animation-delay: -1s;
}

.container-circles .circle2 {
  animation: animate 3s infinite linear both;
  animation-delay: -0.8s;
}

.container-circles .circle3 {
  animation: animate 3s infinite linear both;
  animation-delay: -0.6s;
}

.container-circles .circle4 {
  animation: animate 3s infinite linear both;
  animation-delay: -0.4s;
}

.container-circles .circle5 {
  animation: animate 3s infinite linear both;
  animation-delay: -0.2s;
}

.container-circles .circle6 {
  animation: animate 3s infinite linear both;
  animation-delay: -0s;
}

@keyframes animate {
  0% {
    background: #c0c1d8;
  }
  5% {
    background: $color-primary;
  }
  20%,
  30%,
  40%,
  50%,
  60%,
  80%,
  90%,
  100% {
    background: #c0c1d8;
  }
}

@media screen and (max-width: 280px) {
  .container {
    width: 275px;
    padding: 2.5px 5px;
  }

  .container-circles div {
    margin: 0 0.1px;
  }

  .woman-pc {
    left: 10px;
    bottom: 20px;
  }

  .man-pc {
    left: -11px;
    bottom: 20px;
  }
}
