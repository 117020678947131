.table {
  width: 350px;
  border-collapse: collapse;

  @include phone {
    width: 50px;
  }
}

.border-radius-table {
  border: 1px solid $primary-color-one;
  border-radius: 8px;
}

.th {
  border-bottom: 1px solid $primary-color-one;
  border-right: 1px solid $primary-color-one;
}

.td {
  border-right: 1px solid $primary-color-one;
}

.tr:hover {
  background-color: rgba(85, 58, 182, 0.08);
}
