.MuiTableCell-head {
  font-family: $font-family-text;
  font-size: 12px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  padding: 1rem !important;
  text-align: center !important;
  align-items: center !important;
  border-radius: 16px 16px 0px 0px !important;
}

.tss-1h9t3sl-MUIDataTableHeadCell-sortAction {
  font-family: $font-family-text !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  text-transform: none !important;
}

.MuiTableCell-body {
  font-family: $font-family-text !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  padding: 1.5rem !important;
}

.MuiToolbar-regular {
  flex-direction: row-reverse !important;
  div {
    text-align: center !important;
  }
}

.MuiToolbar-regular {
  font-family: $font-family-text !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  min-height: 1px !important;
}

.MuiTablePagination-toolbar {
  flex-direction: row !important;
}

.MuiTablePagination-selectLabel {
  font-family: $font-family-text !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  text-transform: none !important;
}

.MuiTablePagination-displayedRows {
  font-family: $font-family-text !important;
  font-size: 12px !important;

  text-transform: none !important;
}

th {
  text-align: center !important;
}

.tss-1fbujeu-MUIDataTableHeadCell-toolButton {
  width: 100%;
}

td {
  text-align: center !important;
  max-width: 14rem;
  word-break: break-word;
  @include tablet {
    min-width: 12rem;
  }
}

.mui-1q1u3t4-MuiTableRow-root .mui-2e5qq7-MuiTableCell-root:nth-child(5),
.mui-1gqug66 .mui-17yvvco:nth-child(5) {
  width: 15%;
}

.subheading-datatable {
  font-weight: 400;
  font-size: $font-size-xxs;
  color: $subheading-color;
}

.dataTable {
  &__active {
    color: #5c40ff !important;
  }

  &__inactive {
    color: #e59500 !important;
  }
}

.tss-52j81d-MUIDataTableSearch-clearIcon {
  display: none !important;
}
.dataTable {
  &__base {
    border-radius: 16px !important;
    padding-top: 20px;
    border: 1px solid$chinese_white !important;
    .css-nfmglb-MuiTableCell-root {
      border-bottom: none !important;
    }
  }
  &__salesForce {
    border-radius: 16px !important;
    padding-top: 20px;
    border: 1px solid$chinese_white !important;
    .css-nfmglb-MuiTableCell-root {
      border-bottom: none !important;
    }
  }
  &__OC {
    border-radius: 16px !important;
    padding-top: 20px;
    border: 1px solid$chinese_white !important;
    .css-nfmglb-MuiTableCell-root {
      border-bottom: none !important;
    }
    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingCheckbox {
      text-align: center !important;
      max-width: 20rem;
      word-break: break-word;
      min-width: 3rem;
      @include tablet {
        min-width: 3rem !important;
      }
      @include phone {
        min-width: 3rem !important;
      }
      @media (min-width: 1920px) {
        min-width: 5rem !important;
      }
    }
  }
  &__BILayers {
    @extend .dataTable__base;
    padding-top: 0px;
  }
}

.containerTable {
  background-color: pink;
  border: 1px solid #5c40ff;
}

.css-1p7qfpd-MuiPaper-root {
  width: 98.3%;

  @include tablet {
    width: 97%;
  }

  @include phone {
    width: 93.5%;
  }
}

.tss-1fz4yw6-MUIDataTableSelectCell-fixedLeft {
  position: static !important;
}
