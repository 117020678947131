.modal {
  &__box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    border-radius: 12px;
    background-color: $white-color;
    padding: 3rem 5rem 5rem 5rem;
    @include tablet {
      width: 80% !important;
      padding: 3rem 4rem 5rem 4rem;
    }
    @include phone {
      width: 90% !important;
      padding: 3rem 2.5rem 5rem 2.5rem;
    }
  }

  &__title {
    padding-top: 0px !important;
  }
  &__form {
    align-items: left;
    text-align: left;
    margin-top: 2%;

    &-input {
      transition: all 0.2s !important;
      background-color: white !important;
      border: 1px solid #543ab4 !important;
      border-radius: 8px !important;
      height: 4rem;
      width: 100%;
      margin: 1.5rem 2rem 1.5rem 0 !important;
      font-size: $font-size-xs !important;

      .MuiInputBase-input.MuiOutlinedInput-input.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
        padding-top: 4.8% !important;
        @include tablet{
        padding-top: 2.4% !important;
        }
        @include phone{
        padding-top: 3.8% !important;
        }
        @media(min-width: 1920px) {
        padding-top: 2.8% !important; 
        }      
      }

      .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
        padding-top: 6.8% !important;  
        @include tablet{
        padding-top: 3.8% !important;
        }
        @include phone{
        padding-top: 5.8% !important;
        }
        @media(min-width: 1920px) {
        padding-top: 4.5% !important; 
        }        
      }

      &--error {
        @extend .modal__form-input;
        border: 1px solid $errorColor !important;
      }

      @include tablet {
        margin: 0.5rem 4rem 1.5rem 0 !important;
      }
    }
  }

  &__btnSave {
    padding: 0rem 2rem !important;
  }
  &__btnEdit {
    padding: 0rem 2rem !important;
  }
  &__btnCancel {
    @extend .dimentions-btn__cleanFilter;
    padding: 0rem 2rem !important;
  }
}
