.modalEditUsers {
  &__form {
    align-items: left;
    text-align: left;
    margin-top: 0.5rem;

    @include tablet {
      align-items: center;
      text-align: center;
    }

    &-permit {
      display: flex;
      flex-flow: row !important;

      @include tablet {
        flex-flow: column !important;
        align-items: left;
      }
    }

    &-responsive-box {
      display: flex;
      text-align: left;
    }

    &-responsive--label {
      display: none;

      text-align: left;
      width: 263px;
    }

    &-errorText {
      color: $errorColor;
      text-align: left;
      font-size: 9px;
      margin-top: -10px;
      width: fit-content;
    }

    &-title {
      color: $color-primary;
      font-size: $font-size-md !important;
      font-family: $font-family-text;
      font-weight: bolder !important;
      text-align: left;
      padding-top: 1.5rem;
      padding-bottom: 3rem;

      @include tablet {
        text-align: center;
        font-size: 2rem !important;
      }
    }

    &-input {
      border: 1px solid $grey-color !important;
      border-radius: 8px !important;
      height: 4rem;
      width: 23rem;
      transition: all 0.2s !important;
      margin: 1.8rem 4rem 1rem 0 !important;
      font-size: $font-size-xs !important;

      @include tablet {
        width: 60rem !important;
        margin: 1.5rem 4rem 1.5rem 0 !important;
      }

      @include phone {
        width: 20rem !important;
      }

      &--error {
        @extend .modalEditUsers__form-input;
        border-color: $errorColor !important;

        &__large {
          @extend .modalEditUsers__form-input--error;
          width: 35rem;
        }
      }
    }

    :disabled {
      background-color: #e6e9ef !important;
      margin-top: 0.07rem !important;
      border-radius: 8px !important;
      transition: all 0.2s !important;
      font-size: $font-size-xs !important;
      height: 0.5rem;
    }

    &__actions {
      text-align: center !important;
      padding-top: 3rem;

      &-buttonFilled {
        width: 15rem;
        height: 3.5rem;
        background: $color-primary !important;
        padding: 8px 24px;
        font-family: $font-family-text;
        color: $white !important;
        font-size: 12px !important;
        font-weight: bold;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.4s;
        text-transform: none !important;

        @include tablet {
          width: 20rem;
          margin: 0rem 4rem 1.5rem 0 !important;
        }
      }

      &-buttonOutline {
        width: 15rem;
        height: 3.5rem;
        background: transparent !important;
        padding: 8px 24px;
        font-family: $font-family-text;
        color: $color-primary !important;
        font-size: 12px !important;
        font-weight: bold;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.4s;
        border: 1px solid $color-primary !important;
        margin-right: 1rem !important;
        text-transform: none !important;

        @include tablet {
          width: 20rem;
          margin: 0.5rem 4rem 1.5rem 0 !important;
        }
      }
    }

    &-buttonResend {
      height: 3.5rem;
      padding: 8px 24px;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.4s;
      margin-right: 1rem !important;
      text-transform: none !important;

      &__disabled {
        @extend .btn__filled__disabled;
      }

      @include tablet {
        align-items: center !important;
        text-align: center !important;
        width: 20.2rem;
        margin: 0.5rem 4rem 1.5rem 0 !important;
      }
    }

    @include tablet {
      align-items: center;
    }

    .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
      color: rgba(0, 0, 0, 0.6) !important;
    }

    &__inputLabel {
      font-size: 13px !important;
      margin: 5px 0 0 -10px;
      padding: 0 0 0 5px !important;
      color: $grey !important;
    }
  }
}

.grid-father {
  @include tablet {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .forwardText {
    &__display-none {
      display: none;
    }

    &__display-block {
      display: block;
    }
  }

  .grid-forward-text {
    text-align: left;

    @include tablet {
      text-align: center;
      width: 75%;
      margin: 0 4rem 0 0 !important;
    }
  }
}

.shakingClick {
  @extend .col-start;
  text-align: start !important;
  margin-top: 1.4rem;
  color: $errorColor;
  background-color: rgba(229, 41, 0, 0.05);
  font-size: $font-size-label;
}
