.img-girl {
  width: 85%;

  @include tablet {
    width: 100%;
  }
}

.container-welcome {
  background: white;
  width: 100%;
  border-radius: 8px;
  padding: 25px;
}
