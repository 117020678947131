.drawer {
  width: 220px !important;
  white-space: nowrap; // Ahora se convierte en una propiedad CSS
  box-sizing: border-box;
  flex-shrink: 0;

  &__open {
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1);
    border: white;
    overflow-x: hidden;
    @extend .drawer;
    @include sidebar-xs {
      background: linear-gradient(90deg, #543ab4 40%, white 40%);
      width: 250px;
    }
    @include sidebar-lg {
      background: linear-gradient(90deg, #543ab4 37.5%, white 22%);
      width: 220px;
    }
  }

  &__close {
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1);
    overflow-x: hidden;
    background-color: #543ab4;
    // {theme.spacing(7)} + 28px
    width: 95px !important;
    @extend .drawer;
    @include sidebar-xs {
      width: 70px !important;
      background: linear-gradient(90deg, #543ab4 22%, white 22%);
    }
    @include sidebar-lg {
      width: 95px !important;
    }
  }
}

.MuiDrawer-paper {
  height: 100% !important;
  box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.1) !important;
  border-radius: 12px 0 0 12px !important;
  @include sidebar-xs {
    border-radius: 0 24px 24px 0 !important;
  }
}
.sideBar__button {
  text-transform: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  font-family: $font-family-text !important;
  &___responsive {
    @extend .sideBar__button;
    @include tablet {
      padding-left: 8rem !important;
    }
  }
}
.exit__button {
  top: 70% !important;
}
.sideBar__button__options {
  :hover {
    color: black;
    background-color: rgba(77, 202, 250, 0.3);
    border-radius: 5px;
  }

  text-transform: none !important;
  font-size: 12px !important;
  color: black !important;
  text-align: left;
  font-weight: 400 !important;
}

.MuiTooltip-popper {
  max-width: 700px !important;
    @include tablet{
      padding-left: 85px;
  }
  @include phone{
      padding-left: 100px;
      padding-right: 100px;
 }   
}
.MuiTooltip-tooltip {
  font-weight: 500 !important;
  font-size: 13px !important;
  color: $color-primary !important;
  /**background-color: white !important;*/
  text-align: center !important;
  max-width: 1000px !important;
  border: 2px solid #553ab6 !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px !important;
  background: linear-gradient(
      0deg,
      rgba(85, 58, 182, 0.08),
      rgba(85, 58, 182, 0.08)
    ),
    #ffffff !important;
    @include tablet{
      font-weight: 600 !important;
      font-size: 18px !important;
      text-align: left !important;
   } 
   @include phone{
      font-size: 11px !important;
   } 
    @media (max-width: 1366px){
        padding-top: 100px;
    }
}
.MuiTooltip-tooltipArrow,
.css-kudwh-MuiTooltip-arrow {
  color: #553ab6 !important;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper,
.css-1l8j5k8 {
  z-index: 101 !important;
}

.show-menu {
  left: 0 !important;
  transition: left 1s !important;
}

.hidde-menu {
  left: -305px !important;
  transition: left 1s !important;
}

.responsive_grid {
  @include tablet {
    padding: 0 !important;
    padding-left: 6px !important;
    padding-top: 10px !important;
  }
}
.icons__text {
  display: none !important;
  text-align: center !important;
  @include tablet {
    display: block !important;
    color: white;
    font-family: $font-family-text;
    font-size: 12px;
    word-break: break-all;
  }
  &__responsive {
    @extend .icons__text;
    text-align: end !important;
    color: black;
  }
  &_open {
    text-align: center !important;
    display: block !important;
    color: white;
    @include tablet {
      text-align: center !important;
      display: block !important;
      color: white;
    }
  }
}
.icons__text_mobile {
  font-family: $font-family-text !important;
  font-size: 12px !important;
  text-align: end !important;
  color: black !important;

  &__responsive {
    @extend .icons__text_mobile;
    word-wrap: "break-word" !important;
    text-align: left !important;
  }

  &__description {
    @extend .icons__text_mobile;
    text-align: left !important;
    word-wrap: "break-word" !important;
    color: #9ca3af !important;
    font-size: 11px !important;
  }

  &__back-tablet {
    @extend .icons__text_mobile;
    font-weight: bold !important;
  }
  &__back-phone {
    @extend .icons__text_mobile;
    font-weight: bold !important;
    text-align: left !important;
  }
}

.exit__button__responsive {
  @include tablet {
    padding-left: 4rem !important;
  }
}
.MuiDrawer-docked {
  white-space: normal !important;
}

.menu__icon__img {
  width: 1.8rem;

  &__selected {
    @extend .menu__icon__img;
    filter: invert(20%) sepia(34%) saturate(3871%) hue-rotate(238deg)
      brightness(109%) contrast(96%);
  }

  &__normal {
    @extend .menu__icon__img;
    filter: invert(1);
  }
}

.scroll-sidebar {
  overflow-y: auto !important;
}

.scroll-sidebar::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.scroll-sidebar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.scroll-sidebar::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 10px;
}

/// Scroll vertical
.only-scroll-y {
  overflow-y: auto !important;
}

.only-scroll-y::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.only-scroll-y::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.only-scroll-y::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 10px;
}

/// Scroll horizontal
.scroll-x {
  overflow-x: auto !important;
}

.scroll-x::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.scroll-x::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.scroll-x::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 10px;
}
