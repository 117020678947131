.accordion {
  &__menuParent {
    padding: 0 !important;
    padding-right: 10px !important;
    box-shadow: none !important;
    &__rounded {
      box-shadow: none !important;
    }
    &__content {
      padding: 0 !important;
      padding-left: 20px !important;
      padding-right: 5px !important;
      background-color: transparent !important;
      border: 0 !important;
    }
  }
  &__filter_externalFiles {
    box-shadow: none !important;
    border: 1px solid$chinese_white !important;
    border-radius: 8px 8px 10px 10px !important;
  }
  &__filter {
    box-shadow: none !important;
    border: 1px solid$chinese_white !important;
    border-radius: 8px 8px 0px 0px !important;

    &-padding {
      @extend .accordion__filter;
      padding: 6px !important;
    }

    &__master {
      max-width: 100% !important;
    }
    &__expanded {
      box-shadow: none !important;
      border: 1px solid$chinese_white !important;
      border-radius: 8px !important;
    }
  }

  &__upload {
    border-radius: 12px !important;
    box-shadow: none !important;
    &__downloadIcon {
      background-color: $tertiary-color-two;
      font-size: 22px !important;
      box-shadow: 0px 2.4px 4.8px rgba(0, 0, 0, 0.15);
      border-radius: 5px;

      fill: white !important;
    }

    &__deleteIcon {
      background-color: $errorColor;
      font-size: 22px !important;
      box-shadow: 0px 2.4px 4.8px rgba(0, 0, 0, 0.15);
      border-radius: 5px;

      fill: white !important;
    }
    &__uploadIcon {
      background-color: $artyclick_sky_blue;
      font-size: 22px !important;
      box-shadow: 0px 2.4px 4.8px rgba(0, 0, 0, 0.15);
      border-radius: 5px;

      fill: white !important;
    }
    &__deployIcon {
      background-color: #f96d10;
      font-size: 22px !important;
      box-shadow: 0px 2.4px 4.8px rgba(0, 0, 0, 0.15);
      border-radius: 5px;

      fill: white !important;
    }
    &__tableIcon {
      background-color: $primary-color-two;
      font-size: 22px !important;
      box-shadow: 0px 2.4px 4.8px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      padding: 1.5px !important;
      fill: white !important;
    }
    &__detail {
      background-color: white !important;
      border: none !important;
    }
  }

  &__help {
    box-shadow: none !important;

    &__icon-title {
      background-color: $primary-color-one !important;
      border-radius: 8px !important;
      width: 25px;
      height: 25px;
    }

    &__details {
      background-color: $white !important;
      border: none !important;
      padding: 0% 1.5% 2% 1.5% !important;
    }

    &__questions-accordion {
      box-shadow: none !important;
      border-radius: 0px !important;
      border-bottom: 1px solid $text-color-disabled;
    }

    &__question-content {
      background-color: $background-color-main !important;
      min-height: 150px;
      border-radius: 8px;
      padding: 2%;

      @include tablet {
        padding: 20px;
      }
    }

    &__tutorials-image {
      @extend .col-start;
      background-color: $background-color-main;
      border-radius: 8px;

      @include phone {
        justify-content: center;
      }
    }
  }
}
