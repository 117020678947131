.scroll-y {
  @include phone {
    overflow-y: scroll;
    scroll-behavior: smooth;
    height: 550px;
  }
}

.modal-box-createEditCompany {
  background-color: $white-color;
  border-radius: 12px;
  left: 50%;
  padding: 30px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-width: 1000px;
}
