//FONT FAMILY SETTINGS
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
body {
  font-family: $font-family-text;
  font-size: $font-size-default;
  line-height: 1.5;
  color: $text-color;
}

.heading-primary {
  color: $heading-color;
  font-size: $font-size-lg;
  font-family: $font-family-text;
  font-weight: 500;
  text-align: center;
  @include phone {
    font-size: 20px;
  }
}

.heading {
  font-family: $font-family-text;
  font-weight: 500;
  font-size: $font-size-default;
  line-height: 24px;
  &__success-color {
    @extend .heading;
    color: $successColor;
  }
  &__error-color {
    @extend .heading;
    color: $errorColor;
  }
  &__warning-color {
    @extend .heading;
    color: $warningColor;
  }
  &__bold {
    @extend .heading;
    font-weight: 500 !important;
  }
}

.heading-secondary {
  color: $color-primary;
  font-size: $font-size-md;
  font-family: $font-family-text;
  font-weight: 500;
  text-align: left;
  padding-top: 1.5rem;
  padding-bottom: 3rem;
  @include phone {
    font-size: 20px;
  }
  &__md {
    @extend .heading-secondary;
    font-weight: 500 !important;
    font-size: 20px !important;
  }
  &__md-one {
    @extend .heading-secondary;
    width: 600px;
    font-weight: 500 !important;
    font-size: 20px !important;
    text-align: left;
    padding-left: 2rem;
    padding-bottom: 0rem;
    @include phone {
      font-size: 20px;
      width: 100% !important;
      text-align: center;
      padding-left: 0rem;
    }
    @media (min-width: 1920px) {
      font-size: 28px !important;
      padding-bottom: 0rem !important;
    }
  }
  &__md-two {
    @extend .heading-secondary;
    font-weight: 500 !important;
    font-size: 20px !important;
    text-align: center;
  }

  &__bond {
    @extend .heading-secondary;
    font-weight: 500 !important;
  }
  &__sm {
    @extend .heading-secondary;
    font-weight: 500 !important;
    font-size: 14px !important;
  }
}

.heading-secondary-modal {
  color: $color-primary !important;
  font-size: 18px !important;
  font-family: $font-family-text !important;
  font-weight: 500 !important;
  text-align: center !important;
  padding-top: 1.5rem !important;
  padding-bottom: 2.5rem !important;
  min-width: 100% !important;
  @include tablet {
    font-weight: 900 !important;
    font-size: 20px !important;
  }
  @include phone {
    font-weight: 900 !important;
    font-size: 12.5px !important;
  }
  @media (min-width: 1920px) {
    font-size: 21px !important;
    font-weight: 600 !important;
  }
  &__md {
    @extend .heading-secondary;
    line-height: 27px;
    font-weight: 500 !important;
    font-size: 23px !important;
  }
}

.heading-secondary-modal-info {
  color: $color-primary !important;
  font-size: 28px !important;
  font-family: $font-family-text !important;
  font-weight: 500 !important;
  text-align: left !important;
  line-height: 30px;
  padding-top: 1.5rem !important;
  padding-bottom: 2.5rem !important;
  @include tablet {
    font-weight: 800 !important;
    font-size: 30px !important;
    line-height: 30px !important;
  }
  @include phone {
    font-weight: 900 !important;
    font-size: 13px !important;
    line-height: 17px !important;
  }
  @media (min-width: 1920px) {
    font-size: 28px !important;
    font-weight: 500 !important;
    line-height: 32px !important;
  }
}

.subHeading-primary {
  color: $subheading-color;
  font-size: $font-size-default;
  font-family: $font-family-text;
  font-weight: 500;
  text-align: left;
}

.subHeading-secondary {
  color: black;
  font-size: $font-size-sm;
  font-family: $font-family-text;
  font-weight: 500;
  text-align: left;
}

.subHeading-third {
  color: #5572b7;
  font-size: $font-size-sm;
  font-family: $font-family-text;
  font-weight: 500;
  text-align: left;
}

.subHeading-four {
  color: #ffa836;
  font-size: $font-size-sm;
  font-family: $font-family-text;
  font-weight: 500;
  text-align: left;
  text-align: center !important;
}

.default-text-one {
  color: $heading-color;
  font-size: $font-size-default;
  font-family: $font-family-text;
  text-decoration: none !important;
  line-height: 24px;
  text-align: left !important;
  &__link {
    @extend .default-text-one;
    color: black !important;
    text-decoration: underline;
    text-align: left;
    font-size: 12px !important;
    padding-left: 7rem !important;
    @include tablet {
      padding-left: 7rem !important;
      text-align: center !important;
    }
    @include phone {
      text-align: center !important;
      padding-left: 0rem !important;
    }
  }
}

.default-text-modal {
  color: $heading-color;
  font-size: $font-size-default;
  font-family: $font-family-text;
  text-decoration: none !important;
  line-height: 24px;
  text-align: left !important;
  &_black {
    @extend .default-text-modal;
    color: $text-color !important;
    text-align: left !important;
    font-size: 17px !important;
    @include tablet {
      margin-top: 15px !important;
      font-size: 20px !important;
      line-height: 25px !important;
    }
    @include tablet {
      margin-top: 15px !important;
      font-size: 20px !important;
      line-height: 25px !important;
    }
    @include phone {
      margin-top: 0px !important;
      font-size: 12px !important;
      line-height: 15px !important;
    }
    @media (min-width: 1920px) {
      font-size: 18px !important;
      font-weight: 30px !important;
      margin-top: 10px !important;
    }
  }
}

.default-text {
  color: $heading-color;
  font-size: $font-size-default;
  font-family: $font-family-text;
  text-decoration: none !important;
  line-height: 24px;
  text-align: left !important;
  &_black {
    @extend .default-text;
    color: $text-color !important;
    text-align: left !important;
    margin-top: 10px;
    font-size: 20px !important;
    @include tablet {
      margin-top: 15px !important;
      font-size: 20px !important;
      line-height: 25px !important;
    }
    @include phone {
      margin-top: 10px !important;
      font-size: 11px !important;
      line-height: 15px !important;
    }
    @media (min-width: 1920px) {
      font-size: 18px !important;
      font-weight: 30px !important;
      margin-top: 10px !important;
    }
    &__bold {
      font-size: $font-size-sm;
      font-weight: 500 !important;
    }
    &__sm {
      @extend .default-text_black;
      font-size: $font-size-sm !important;
      font-weight: 500 !important;
    }
    &__xs {
      @extend .default-text_black;
      font-size: $font-size-xs;
    }
  }
  &__blue {
    @extend .default-text;
    color: $color-primary !important;
    text-align: left !important;
    font-weight: 400 !important;
    &__sm {
      @extend .default-text__blue;
      font-size: $font-size-sm !important;
    }
  }
  &__grey {
    @extend .default-text;
    color: #2e3043 !important;
    text-align: center !important;
    font-weight: 400 !important;
    font-size: $font-size-sm !important;
  }
  &__link {
    @extend .default-text;
    color: black !important;
    text-decoration: underline;
    text-align: left;
    font-size: 12px !important;
    @include tablet {
      text-align: center !important;
    }
    @include phone {
      text-align: center !important;
      padding-left: 0rem !important;
    }
  }
  &__linkBlue {
    @extend .default-text;
    color: #5c40ff !important;
    text-align: left !important;
    font-weight: 400 !important;
    text-decoration: underline !important;
    &__sm {
      @extend .default-text__linkBlue;
      font-size: 10px !important;
      font-weight: 500 !important;
      &__grey {
        @extend .default-text__linkBlue__sm;
        color: $grey !important;
      }
    }
  }
  &_successful {
    @extend .default-text;
    color: $text-successful !important;
    @include phone {
      font-size: 12px;
    }
  }
}

.error-message {
  color: $errorColor;
  font-size: $font-size-xs;
  font-family: $font-family-text;
  font-weight: 400;
  text-align: left;
  padding-left: 0.7rem;
  padding-top: 0.7rem;
  padding-bottom: 0.3rem;
  @include tablet {
    text-align: center;
  }
  &__small {
    font-size: 10px;
    font-weight: 400;
    color: $errorColor;
  }
}

.label-Primary {
  display: block;
  text-align: center;
  color: $color-primary;
  font-size: $font-size-xs;
  font-weight: 500;
  padding: 0.5rem;
  &__upload {
    display: block;
    text-align: center;
    color: white;
    font-size: $font-size-xs;
    font-weight: 500;
    padding: 1rem;
    background-color: $color-primary;
    border-radius: 8px;
    cursor: pointer;
    @include tablet {
      font-size: $font-size-sm;
    }
  }
  &__small {
    display: block;
    padding-top: 1.5rem;
    color: $color-primary;
    font-weight: 500;
    &__error {
      @extend .label-Primary__small;
      color: $errorColor !important;
      @include tablet {
        text-align: center !important;
        font-size: 18px !important;
      }
    }
    @include tablet {
      text-align: center !important;
      font-size: 18px !important;
    }
  }
  &__xs {
    display: block;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    color: black;
    text-align: center;
    font-size: $font-size-xs;
  }
  &__uploadButton {
    background: #543ab4 !important;
    text-transform: none !important;
    color: white !important;
    min-width: 140px !important;
    padding: 0.7rem !important;
    border-radius: 8px !important;
    font-size: $font-size-default !important;
    @include phone {
      width: 100% !important;
    }
  }
  &__cancelButton {
    padding: 0.7rem !important;
    text-transform: none !important;
    border-radius: 8px !important;
    min-width: 104px !important;
    font-size: $font-size-default !important;
    color: #543ab4 !important;
    border: 1.5px solid #543ab4 !important;
    @include phone {
      width: 100% !important;
    }
  }
}

.subHeading-secondary {
  color: $grey-color;
  font-size: $font-size-sm;
  font-family: $font-family-text;
  font-weight: 500;
  text-align: left;
  &__black {
    @extend .subHeading-secondary;
    color: black;
  }
  &__sm {
    @extend .subHeading-secondary;
    font-weight: 400;
    color: black;
  }
  &__sm-grey {
    @extend .subHeading-secondary;
    font-weight: 400;
    color: $grey-color;
  }
}

.default-text {
  color: $heading-color;
  font-size: $font-size-default;
  font-family: $font-family-text;
  text-decoration: none !important;
  text-align: center;
  &_black {
    @extend .default-text;
    color: $text-color !important;
    text-align: left !important;
    font-weight: 400 !important;
    &__xs {
      @extend .default-text_black;
      font-size: $font-size-xs !important;
    }
    &__bold {
      @extend .default-text_black;
      font-weight: 500 !important;
      &__sm {
        @extend .default-text_black;
        font-size: $font-size-sm !important;
      }
    }
    &__blue {
      @extend .default-text;
      color: $color-primary !important;
      text-align: left !important;
      font-weight: 400 !important;
      &__sm {
        @extend .default-text__blue;
        font-size: $font-size-sm !important;
      }
    }
    &__link {
      @extend .default-text;
      color: $color-primary !important;
      text-align: center !important;
      font-size: 12px !important;
      text-decoration: underline !important;
    }
    &_successful {
      @extend .default-text;
      color: $text-successful !important;
      @include phone {
        font-size: 12px;
      }
    }
  }
}

.form__label {
  padding-left: 3px;
  color: #8c8d9d;
  &__sm {
    @extend .form__label;
    font-size: 11px !important;
  }
  &__md {
    @extend .form__label;
    text-align: left !important;
    padding-left: 3px !important;
  }
}

.subHeading-third {
  color: #5572b7;
  font-size: $font-size-sm;
  font-family: $font-family-text;
  font-weight: 500;
  text-align: left;
}

.subHeading-five {
  color: $color-primary;
  font-size: $font-size-sm;
  font-family: $font-family-text;
  font-weight: 500;
  text-align: left;
}

.subHeading-six {
  color: $color-primary !important;
  font-size: $font-size-sm;
  font-family: $font-family-text;
  font-weight: 500;
  text-align: left;
  line-height: 16px;
}

/*
* New typographies
*/

.bold {
  font-weight: $weight-bold !important;
}

.regular {
  font-weight: $weight-regular !important;
}

.display-x-large-home {
  font-family: $font-family-text;
  font-weight: $weight-medium;
  font-size: 43px;
  line-height: $line-height-x-large;
  margin-bottom: 3rem;
  @include phone {
    font-size: 16px;
    line-height: $line-height-x-large-mobile;
  }
  &__primary-one {
    @extend .display-x-large-home;
    color: $primary-color-one;
  }
  @media (max-width: 1340px) {
    font-size: 30px;
    line-height: $line-height-x-large-mobile;
    margin-bottom: 2rem;
  }
  @media (max-width: 770px) {
    font-size: 35px;
    line-height: $line-height-x-large-mobile;
    text-align: center;
    margin-bottom: 4rem;
  }
  @media (max-width: 380px) {
    font-size: 22px;
    line-height: $line-height-x-large-mobile;
    margin-bottom: 2rem;
  }
}

.display-x-large {
  font-family: $font-family-text;
  font-weight: $weight-medium;
  font-size: $font-size-x-large;
  line-height: $line-height-x-large;
  @include tablet {
    font-size: $font-size-x-large-mobile;
    line-height: $line-height-x-large-mobile;
  }
  &__bold {
    @extend .display-x-large;
    font-weight: $weight-bold;
  }
  &__regular {
    @extend .display-x-large;
    font-weight: $weight-regular;
  }
  &__primary-one {
    @extend .display-x-large;
    color: $primary-color-one;
  }
  &__primary-two {
    @extend .display-x-large;
    color: $primary-color-two;
  }
  &__secondary-one {
    @extend .display-x-large;
    color: $secondary-color-one;
  }
  &__secondary-two {
    @extend .display-x-large;
    color: $secondary-color-two;
  }
  &__tertiary-one {
    @extend .display-x-large;
    color: $tertiary-color-one;
  }
  &__tertiary-two {
    @extend .display-x-large;
    color: $tertiary-color-two;
  }
  &__tertiary-third {
    @extend .display-x-large;
    color: $tertiary-color-third;
  }
  &__text-grey {
    @extend .display-x-large;
    color: $text-color-grey;
  }
  &__text-default {
    @extend .display-x-large;
    color: $text-color-default;
  }
  &__text-black {
    @extend .display-x-large;
    color: $text-color-black;
  }
  &__text-white {
    @extend .display-x-large;
    color: $text-color-white;
  }
  &__text-disabled {
    @extend .display-x-large;
    color: $text-color-disabled;
  }
  &__success-color {
    @extend .display-x-large;
    color: $successColor;
  }
  &__warning-color {
    @extend .display-x-large;
    color: $warningColor;
  }
  &__error-color {
    @extend .display-x-large;
    color: $errorColor;
  }
}

.display-large-one {
    font-family: $font-family-text !important;
    font-weight: $weight-medium !important;
    font-size: $font-size-large !important;
    line-height: $line-height-large !important;
    @include tablet {
        font-size: 24px !important;
        width: 100%;
        line-height: $line-height-large-mobile;
        padding-right: 8rem;
        padding-bottom: 2rem;
    }
    @include phone {
        font-size: 13px !important;
        padding-right: 4rem !important;
        padding-bottom: 1rem !important;
        align: center;
    }
    &__text-white {
        @extend .display-large-one;
        color: $text-color-white;
    }
}

.display-large-two {
    font-family: $font-family-text !important;
    font-weight: $weight-medium !important;
    font-size: $font-size-large !important;
    line-height: $line-height-large !important;
    @include tablet {
        font-size: 24px !important;
        width: 100%;
        line-height: $line-height-large-mobile;
        padding-bottom: 2rem;
    }
    @include phone {
        font-size: 20px !important;
        padding-right: 12rem !important;
    }
    &__primary-one {
        @extend .display-large-two;
        color: $primary-color-one !important;
    }
    &__primary-two {
        color: $primary-color-one !important;
        padding-top: 1.5rem !important;
        padding-bottom: 2.5rem !important;
        font-weight: $weight-medium !important;
        font-size: 23px !important;
        @include tablet {
            font-size: 25px !important;
            width: 100%;
            line-height: $line-height-large-mobile;
        }
        @include phone {
            min-width: 100% !important;
            font-size: 19px !important;
        }
    }
}

.display-large {
    font-family: $font-family-text !important;
    font-weight: $weight-medium !important;
    font-size: $font-size-large !important;
    line-height: $line-height-large !important;
    @include tablet {
        font-size: 24px !important;
        width: 100%;
        line-height: $line-height-large-mobile;
        padding-right: 8rem;
        padding-bottom: 2rem;
    }
    @include phone {
        font-size: 20px !important;
        padding-right: 4rem !important;
        padding-bottom: 1rem !important;
    }
    &__primary-one {
        @extend .display-large;
        color: $primary-color-one !important;
    }
    &__primary-two {
        @extend .display-large;
        color: $primary-color-two;
    }
    &__primary-three {
        @extend .display-large;
        color: $primary-color-one !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
    }
    &__secondary-one {
        @extend .display-large;
        color: $secondary-color-one;
    }
    &__secondary-two {
        @extend .display-large;
        color: $secondary-color-two;
    }
    &__tertiary-one {
        @extend .display-large;
        color: $tertiary-color-one;
    }
    &__tertiary-two {
        @extend .display-large;
        color: $tertiary-color-two;
    }
    &__tertiary-third {
        @extend .display-large;
        color: $tertiary-color-third;
    }
    &__text-grey {
        @extend .display-large;
        color: $text-color-grey;
    }
    &__text-default {
        @extend .display-large;
        color: $text-color-default;
    }
    &__text-black {
        @extend .display-large;
        color: $text-color-black;
    }
    &__text-white {
        @extend .display-large;
        color: $text-color-white;
    }
    &__text-disabled {
        @extend .display-large;
        color: $text-color-disabled;
    }
    &__success-color {
        @extend .display-large;
        color: $successColor;
    }
    &__warning-color {
        @extend .display-large;
        color: $warningColor;
    }
    &__error-color {
        @extend .display-large;
        color: $errorColor;
    }
    &__moderate-blue {
        @extend .display-large;
        color: $moderate_blue !important;
    }
    &__grey {
        @extend .display-large;
        color: $grey !important;
    }
}

.display-large-orders {
  font-family: $font-family-text !important;
  font-weight: $weight-medium !important;
  font-size: 26px !important;
  line-height: $line-height-large !important;
  @include tablet {
    font-size: 25px !important;
    width: 100%;
    line-height: 10px;
    padding-right: 8rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
  @include phone {
    font-size: 18px !important;
    padding-right: 4rem !important;
    padding-bottom: 1rem !important;
    line-height: 25px !important;
  }
  @media (min-width: 1920px) {
    font-size: 28px !important;
  }
  &__primary-one {
    @extend .display-large-orders;
    color: $primary-color-one !important;
    padding-top: 2rem;
  }
}

.display-medium {
  font-family: $font-family-text;
  font-weight: $weight-regular;
  font-size: $font-size-medium;
  line-height: $line-height-medium;
  @include tablet {
    font-size: $font-size-medium-mobile;
    line-height: $line-height-medium-mobile;
  }
  &__primary-one {
    @extend .display-medium;
    color: $primary-color-one;
  }
  &__primary-two {
    @extend .display-medium;
    color: $primary-color-two;
  }
  &__secondary-one {
    @extend .display-medium;
    color: $secondary-color-one;
  }
  &__secondary-two {
    @extend .display-medium;
    color: $secondary-color-two;
  }
  &__tertiary-one {
    @extend .display-medium;
    color: $tertiary-color-one;
  }
  &__tertiary-two {
    @extend .display-medium;
    color: $tertiary-color-two;
  }
  &__tertiary-third {
    @extend .display-medium;
    color: $tertiary-color-third;
  }
  &__text-grey {
    @extend .display-medium;
    color: $text-color-grey;
  }
  &__text-default {
    @extend .display-medium;
    color: $text-color-default;
  }
  &__text-black {
    @extend .display-medium;
    color: $text-color-black;
  }
  &__text-white {
    @extend .display-medium;
    color: $text-color-white;
  }
  &__text-disabled {
    @extend .display-medium;
    color: $text-color-disabled;
  }
  &__success-color {
    @extend .display-medium;
    color: $successColor;
  }
  &__warning-color {
    @extend .display-medium;
    color: $warningColor;
  }
  &__error-color {
    @extend .display-medium;
    color: $errorColor;
  }
}

.display-small-one {
  font-family: $font-family-text;
  font-weight: $weight-bold;
  font-size: 13px;
  line-height: $line-height-small;
  &__text-white {
    @extend .display-small-one;
    color: $text-color-white;
  }
}

.display-small {
  font-family: $font-family-text;
  font-weight: $weight-bold;
  font-size: $font-size-small;
  line-height: $line-height-small;
  // @include tablet {
  //   font-size: $font-size-small-mobile;
  //   line-height: $line-height-small-mobile;
  // }
  &__warning {
    @extend .display-small;
    color: $rojo_400;
  }
  &__primary-one {
    @extend .display-small;
    color: $primary-color-one;
  }
  &__primary-two {
    @extend .display-small;
    color: $primary-color-two;
  }
  &__secondary-one {
    @extend .display-small;
    color: $secondary-color-one;
  }
  &__secondary-two {
    @extend .display-small;
    color: $secondary-color-two;
  }
  &__tertiary-one {
    @extend .display-small;
    color: $tertiary-color-one;
  }
  &__tertiary-two {
    @extend .display-small;
    color: $tertiary-color-two;
  }
  &__tertiary-third {
    @extend .display-small;
    color: $tertiary-color-third;
  }
  &__text-grey {
    @extend .display-small;
    color: $text-color-grey;
  }
  &__text-default {
    @extend .display-small;
    color: $text-color-default;
  }
  &__text-black {
    @extend .display-small;
    color: $text-color-black;
  }
  &__text-white {
    @extend .display-small;
    color: $text-color-white;
  }
  &__text-disabled {
    @extend .display-small;
    color: $text-color-disabled;
  }
  &__success-color {
    @extend .display-small;
    color: $successColor;
  }
  &__warning-color {
    @extend .display-small;
    color: $warningColor;
  }
  &__error-color {
    @extend .display-small;
    color: $errorColor;
  }
  &__moderate-blue {
    @extend .display-small;
    color: $moderate_blue !important;
  }
  &__light-blue {
    @extend .display-small;
    color: $light_blue !important;
  }
  &__text-blue3 {
    @extend .display-small;
    color: $blue3 !important;
  }
}

.heading_one {
  font-size: $font-size-heading !important;
  font-family: $font-family-text !important;
  line-height: $line-height-heading !important;
  position: relative;
  &__primary-one {
    @extend .heading_one;
    color: black;
    position: relative;
    right: 70px;
    @include phone {
      color: black;
      font-size: 10.5px !important;
      right: 0px;
    }
  }
  &__primary-two {
    @extend .heading_one;
    color: black;
    position: relative;
    @include phone {
      color: black;
      font-size: 10.5px !important;
      right: 0px;
    }
  }
}

.heading {
  font-size: $font-size-heading !important;
  font-family: $font-family-text !important;
  font-weight: $weight-bold !important;
  line-height: $line-height-heading !important;
  position: relative;
  &__primary-one {
    @extend .heading;
    color: black;
    position: relative;
    right: 70px;
    @include phone {
      color: black;
      font-size: 10.5px !important;
      right: 0px;
    }
  }
  &__primary-three {
    @extend .heading;
    position: static !important;
    right: 70px;
  }
  &__primary-two {
    @extend .heading;
    color: $primary-color-two;
  }
  &__secondary-one {
    @extend .heading;
    color: $secondary-color-one;
  }
  &__secondary-two {
    @extend .heading;
    color: $secondary-color-two;
  }
  &__tertiary-one {
    @extend .heading;
    color: $tertiary-color-one;
    @include phone {
      text-align: right;
    }
  }
  &__tertiary-two {
    @extend .heading;
    color: $tertiary-color-two;
  }
  &__tertiary-third {
    @extend .heading;
    color: $tertiary-color-third;
  }
  &__text-blue3 {
    @extend .heading;
    color: $blue3;
  }
  &__text-grey {
    @extend .heading;
    color: $text-color-grey;
  }
  &__text-default {
    @extend .heading;
    color: $text-color-default;
  }
  &__text-black {
    @extend .heading;
    color: $text-color-black;
  }
  &__text-disabled {
    @extend .heading;
    color: $text-color-disabled;
  }
  &__success-color {
    @extend .heading;
    color: $successColor;
  }
  &__text-white {
    @extend .heading;
    color: $text-color-white;
  }
  &__warning-color {
    @extend .heading;
    color: $warningColor;
  }
  &__error-color {
    @extend .heading;
    color: $errorColor;
  }
  &__bold {
    @extend .heading;
    font-weight: $weight-bold !important;
  }
}

.subheading {
  font-size: $font-size-subheading !important;
  font-family: $font-family-text !important;
  font-weight: $weight-medium !important;
  // line-height: $line-height-subheading !important;
  line-height: $line-height-small;
  @include tablet {
    padding-left: 1rem;
  }
  @include phone {
    width: 90% !important;
    padding-left: 3rem;
    font-size: 1.3rem !important;
  }
  &__primary-one {
    @extend .subheading;
    color: $primary-color-one;
  }
  &__primary-two {
    @extend .subheading;
    color: $primary-color-two;
  }
  &__static {
    text-transform: initial;
  }
  &__secondary-one {
    @extend .subheading;
    color: $secondary-color-one;
  }
  &__secondary-two {
    @extend .subheading;
    color: $secondary-color-two;
  }
  &__tertiary-one {
    @extend .subheading;
    color: $tertiary-color-one;
  }
  &__tertiary-two {
    @extend .subheading;
    color: $tertiary-color-two;
  }
  &__tertiary-third {
    @extend .subheading;
    color: $tertiary-color-third;
  }
  &__text-grey {
    @extend .subheading;
    color: $text-color-grey;
  }
  &__text-default {
    @extend .subheading;
    color: $text-color-default;
  }
  &__text-black {
    @extend .subheading;
    color: $text-color-black;
  }
  &__text-black-2 {
    @extend .subheading;
    color: $text-color-black;
    font-weight: $weight-regular !important;
  }
  &__text-disabled {
    @extend .subheading;
    color: $text-color-disabled;
  }
  &__success-color {
    @extend .subheading;
    color: $successColor;
    padding-left: 4rem !important;
  }
  &__warning-color {
    @extend .subheading;
    color: $warningColor;
  }
  &__error-color {
    @extend .subheading;
    color: $errorColor;
  }
  &__moderate-blue {
    @extend .subheading;
    color: $color-primary;
  }
  &__blue3 {
    @extend .subheading;
    color: $blue3 !important;
  }
}

.label {
  font-size: $font-size-label !important;
  font-family: $font-family-text !important;
  font-weight: $weight-regular !important;
  line-height: $line-height-label !important;
  @include tablet {
    font-size: $font-size-label-mobile;
    line-height: $line-height-label-mobile;
  }
  &__primary-one {
    @extend .label;
    color: $primary-color-one;
  }
  &__primary-two {
    @extend .label;
    color: $primary-color-two;
  }
  &__secondary-one {
    @extend .label;
    color: $secondary-color-one;
  }
  &__secondary-two {
    @extend .label;
    color: $secondary-color-two;
  }
  &__tertiary-one {
    @extend .label;
    color: $tertiary-color-one;
  }
  &__tertiary-two {
    @extend .label;
    color: $tertiary-color-two;
  }
  &__tertiary-third {
    @extend .label;
    color: $tertiary-color-third;
  }
  &__text-grey {
    @extend .label;
    color: $text-color-grey;
  }
  &__text-default {
    @extend .label;
    color: $text-color-default;
  }
  &__text-black {
    @extend .label;
    color: $text-color-black;
  }
  &__text-disabled {
    @extend .label;
    color: $text-color-disabled;
  }
  &__success-color {
    @extend .label;
    color: $successColor;
  }
  &__warning-color {
    @extend .label;
    color: $warningColor;
  }
  &__error-color {
    @extend .label;
    color: $errorColor;
  }
  &__text-blue3 {
    @extend .label;
    color: $blue3;
  }
  &__bold {
    @extend .label;
    font-weight: $weight-bold !important;
  }
}

.button {
  font-size: $font-size-button !important;
  font-family: $font-family-text !important;
  font-weight: $weight-bold !important;
  line-height: $line-height-button !important;
  @include tablet {
    font-size: $font-size-button-mobile !important;
    line-height: $line-height-button-mobile !important;
  }
  &__primary-one {
    @extend .button;
    color: $primary-color-one !important;
  }
  &__primary-two {
    @extend .button;
    color: $primary-color-two;
  }
  &__secondary-one {
    @extend .button;
    color: $secondary-color-one;
  }
  &__secondary-two {
    @extend .button;
    color: $secondary-color-two;
  }
  &__tertiary-one {
    @extend .button;
    color: $tertiary-color-one;
  }
  &__tertiary-two {
    @extend .button;
    color: $tertiary-color-two;
  }
  &__tertiary-third {
    @extend .button;
    color: $tertiary-color-third;
  }
  &__text-grey {
    @extend .button;
    color: $text-color-grey;
  }
  &__text-default {
    @extend .button;
    color: $text-color-default;
  }
  &__text-black {
    @extend .button;
    color: $text-color-black;
  }
  &__text-disabled {
    @extend .button;
    color: $text-color-disabled;
  }
  &__text-white {
    @extend .button;
    color: $text-color-white !important;
  }
  &__success-color {
    @extend .button;
    color: $successColor;
  }
  &__warning-color {
    @extend .button;
    color: $warningColor;
  }
  &__error-color {
    @extend .button;
    color: $errorColor;
  }
}

.body {
  font-size: $font-size-body;
  font-family: $font-family-text;
  font-weight: $weight-regular;
  line-height: $line-height-body;
  @include tablet {
    font-size: $font-size-body-mobile;
    line-height: $line-height-body-mobile;
  }
  &__primary-one {
    @extend .body;
    color: $primary-color-one;
  }
  &__primary-two {
    @extend .body;
    color: $primary-color-two;
  }
  &__secondary-one {
    @extend .body;
    color: $secondary-color-one;
  }
  &__secondary-two {
    @extend .body;
    color: $secondary-color-two;
  }
  &__tertiary-one {
    @extend .body;
    color: $tertiary-color-one;
  }
  &__tertiary-two {
    @extend .body;
    color: $tertiary-color-two;
  }
  &__tertiary-third {
    @extend .body;
    color: $tertiary-color-third;
  }
  &__text-grey {
    @extend .body;
    color: $text-color-grey;
  }
  &__text-default {
    @extend .body;
    color: $text-color-default;
  }
  &__text-black {
    @extend .body;
    color: $text-color-black;
  }
  &__text-disabled {
    @extend .body;
    color: $text-color-disabled;
  }
  &__success-color {
    @extend .body;
    color: $successColor;
  }
  &__warning-color {
    @extend .body;
    color: $warningColor;
  }
  &__error-color {
    @extend .body;
    color: $errorColor;
  }
}

.caption {
  font-size: $font-size-caption !important;
  font-family: $font-family-text !important;
  font-weight: $weight-regular !important;
  line-height: $line-height-caption !important;
  @include tablet {
    font-size: $font-size-caption-mobile;
    line-height: $line-height-caption-mobile;
  }
  &__primary-one {
    @extend .caption;
    color: $primary-color-one !important;
  }
  &__primary-two {
    @extend .caption;
    color: $primary-color-two;
  }
  &__secondary-one {
    @extend .caption;
    color: $secondary-color-one;
  }
  &__secondary-two {
    @extend .caption;
    color: $secondary-color-two;
  }
  &__tertiary-one {
    @extend .caption;
    color: $tertiary-color-one;
  }
  &__tertiary-two {
    @extend .caption;
    color: $tertiary-color-two;
  }
  &__tertiary-third {
    @extend .caption;
    color: $tertiary-color-third;
  }
  &__text-grey {
    @extend .caption;
    color: $text-color-grey;
  }
  &__text-default {
    @extend .caption;
    color: $text-color-default;
  }
  &__text-black {
    @extend .caption;
    color: $text-color-black;
  }
  &__text-disabled {
    @extend .caption;
    color: $text-color-disabled;
  }
  &__success-color {
    @extend .caption;
    color: $successColor;
  }
  &__warning-color {
    @extend .caption;
    color: $warningColor;
  }
  &__error-color {
    @extend .caption;
    color: $errorColor;
  }
  &__blue3 {
    @extend .caption;
    color: $blue3 !important;
  }
}

.link {
  font-size: $font-size-link;
  font-family: $font-family-text;
  font-weight: $weight-regular;
  line-height: $line-height-link;
  @include tablet {
    font-size: $font-size-link-mobile;
    line-height: $line-height-link-mobile;
  }
  &__primary-one {
    @extend .link;
    color: $primary-color-one;
  }
  &__primary-two {
    @extend .link;
    color: $primary-color-two;
  }
  &__secondary-one {
    @extend .link;
    color: $secondary-color-one;
  }
  &__secondary-two {
    @extend .link;
    color: $secondary-color-two;
  }
  &__tertiary-one {
    @extend .link;
    color: $tertiary-color-one;
  }
  &__tertiary-two {
    @extend .link;
    color: $tertiary-color-two;
  }
  &__tertiary-third {
    @extend .link;
    color: $tertiary-color-third;
  }
  &__text-grey {
    @extend .link;
    color: $text-color-grey;
  }
  &__text-default {
    @extend .link;
    color: $text-color-default;
  }
  &__text-black {
    @extend .link;
    color: $text-color-black;
  }
  &__text-disabled {
    @extend .link;
    color: $text-color-disabled;
  }
  &__success-color {
    @extend .link;
    color: $successColor;
  }
  &__warning-color {
    @extend .link;
    color: $warningColor;
  }
  &__error-color {
    @extend .link;
    color: $errorColor;
  }
}

.subheadingOne {
  font-size: $font-size-subheading !important;
  font-family: $font-family-text !important;
  // line-height: $line-height-subheading !important;
  line-height: $line-height-small;
  &__text-black {
    @extend .subheadingOne;
    color: $text-color-black;
    padding-right: 3rem;

    @include tablet {
      font-size: 15px !important;
      padding-right: 1rem !important;
    }
    @include phone {
      font-size: 15px !important;
      padding-left: 3.5rem !important;
      padding-right: 2rem !important;
    }
  }
  &__subheading-one {
    line-height: $line-height-small;
    color: $text-color-black;
    font-weight: 500 !important;
    font-size: 14px;
    margin-top: 1rem;
    padding-right: 3rem;
    @include tablet {
      font-size: 15px !important;
    }
    @include phone {
      font-size: 12px !important;
      line-height: $line-height-small-mobile;
    }
    @media (min-width: 1920px) {
      font-size: 18px !important;
    }
  }
}

.display-large-password {
  font-family: $font-family-text !important;
  font-weight: $weight-medium !important;
  font-size: $font-size-large !important;
  line-height: $line-height-large !important;
  @include tablet {
    font-size: 24px !important;
    width: 100%;
    line-height: $line-height-large-mobile;
    padding-right: 8rem;
    padding-bottom: 0rem;
  }
  @include phone {
    font-size: 20px !important;
    padding-right: 4rem !important;
    padding-bottom: 0rem !important;
  }
  &__primary-one {
    @extend .display-large-password;
    color: $primary-color-one !important;
    padding-right: 21rem !important;
    @include tablet {
      padding-right: 20rem !important;
    }
    @include phone {
      padding-right: 4rem !important;
    }
  }
}

.subheading_one {
  font-size: $font-size-subheading !important;
  font-family: $font-family-text !important;
  font-weight: $weight-medium !important;
  // line-height: $line-height-subheading !important;
  line-height: $line-height-small;
  &__success-color {
    @extend .subheading_one;
    color: $successColor;
    @include phone {
      padding-left: 4rem !important;
    }
  }
}

.subheading-one {
  font-size: $font-size-subheading !important;
  font-family: $font-family-text !important;
  font-weight: $weight-medium !important;
  // line-height: $line-height-subheading !important;
  line-height: $line-height-small;
  &__text-black {
    @extend .subheading-one;
    color: $text-color-black;
    @include phone {
      font-size: 10px;
      padding-left: 2rem;
    }
  }
  &__text-black-guide {
    font-family: $font-family-text !important;
    color: $text-color-black;
    font-weight: 700 !important;
    font-size: $font-size-subheading !important;
    @include phone {
      font-size: 13px !important;
      padding-left: 0.5rem;
      text-align: right !important;
    }
  }
  &__primary-one {
    font-weight: 700 !important;
    @extend .subheading-one;
    color: $primary-color-one;
  }
  &__primary-two {
    font-size: $font-size-subheading;
    color: $primary-color-one;
    @include phone {
      font-weight: 500;
      font-size: 10px;
    }
  }
  &__primary-three {
    @extend .subheading-one;
    font-weight: 400 !important;
  }
}

.regular-text {
  @extend .default-text;
  font-size: $font-size-regular !important;
  font-weight: 400 !important;
  &_black {
    @extend .default-text;
    font-size: $font-size-regular !important;
    font-weight: 400 !important;
    color: $text-color !important;

    @include tablet {
      font-size: $font-size-regular !important;
    }

    @include phone {
      font-size: $font-size-sm !important;
    }
    &__primary-three {
        @extend .subheading-one;
        font-weight: 400 !important;
    }
  }
}

.back-text {
  font-size: $font-size-default !important;
  font-family: $font-family-text !important;
  font-weight: $weight-bold !important;
  line-height: $line-height-heading !important;

  @include tablet {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  &__blue-500 {
    @extend .back-text;
    color: $blue-500 !important;
  }
}

.noSelectionTitle {
  font-family: $font-family-text !important;
  font-weight: $weight-medium !important;
  font-size: $font-size-large !important;
  line-height: $line-height-large !important;

  @include tablet {
    font-size: 24px !important;
    width: 100%;
    line-height: $line-height-large-mobile;
    padding-bottom: 2rem;
  }
  @include phone {
    font-size: 20px !important;
    padding-bottom: 1rem !important;
  }

  &__primary-one {
    @extend .noSelectionTitle;
    color: $primary-color-one !important;
  }
}

.noSelectionText {
  font-size: $font-size-subheading !important;
  font-family: $font-family-text !important;
  font-weight: $weight-medium !important;
  line-height: $line-height-small;

  @include tablet {
    // padding-left: 3rem;
  }

  @include phone {
    width: 90% !important;
    padding-left: 2rem;
    font-size: 1.3rem !important;
  }
  &__text-grey {
    @extend .noSelectionText;
    color: $text-color-grey;
  }
}

.title-screen-text {
  font-family: $font-family-text;
  font-weight: $weight-medium;
  font-size: $font-size-x-large;
  line-height: $line-height-x-large;
  @include tablet {
    font-size: 2.4rem;
    line-height: $line-height-x-large-mobile;
  }
  &__primary-one {
    @extend .title-screen-text;
    color: $primary-color-one;
  }
}

.selector-text {
  font-family: $font-family-text;
  font-weight: $weight-bold;
  font-size: $font-size-large;
  line-height: $line-height-x-large;
  @include tablet {
    margin-left: 2rem;
    margin-bottom: 2rem;
  }
  @include phone {
    margin-top: 2rem;
  }
}

.BILayers-title {
  font-family: $font-family-text;
  font-weight: $weight-bold;
  font-size: 20px;
  line-height: $line-height-small;
  color: $primary-color-one;

  @include phone {
    font-size: 18px;
  }
}
