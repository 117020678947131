.MuiSelect-select {
  font-family: $font-family-text !important;
}
em {
  font-family: $font-family-text !important;
  font-style: normal;
}
.select {
  &__border {
    border: 1px solid $text-color-grey !important;
    border-radius: 8px !important;
    width: 25rem !important;
    max-width: 25rem !important;
    @include phone {
      width: 25rem !important;
      max-width: 25rem !important;
      margin-left: 5px !important;
    }
    .MuiSelect-select {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
}

// Alineación de texto (label) en select
.grid__container__filled__rounded__select {
  label {
    font-size: small !important;
    top: -5px !important;
    padding-bottom: 5px !important;
  }
}

// Filtro de indicadores

.filter_container {
  @include phone {
    display: flex;
    justify-content: center !important;
    width: 100% !important;
  }
  &__form {
    @include tablet {
      width: 100% !important;
    }
    @include phone {
      margin: 0 !important;
      padding-bottom: 2rem !important;
      width: 100% !important;
    }
  }
}

//Base info
.base-info {
  padding-right: 0.5rem !important;
  @include phone {
    padding-right: 0 !important;
  }
  &__container {
    @extend .base-info;
  }
  &__container-b {
    @include tablet {
      width: 100% !important;
    }
  }
  &__select {
    @extend .base-info;
  }
  &__input {
    width: 100% !important;
  }
}

.filter_form-responsive {
  width: 100% !important;
  padding-right: 2rem !important;
  @include phone {
    padding-right: 0 !important;
    max-width: none !important;
  }
}
