// COLORS ----------------------------------------------

//Primary
$moderate_blue: #553ab6;
$light_blue: #6133e4;
$tonal: #f1edfe;

//Secondary
$light_purple: #4dcafa;
$dark_purple: #2d2259;
$background_dark: #1d0d58;
$grayish_blue: #0fbbf1;
$rose: #d92fd2;

//Tertiary
$bright_orange: #ffa836;
$very_light_orange: #ffd37d;
$qlik_purple: #8d51da;

//Text
//Diseño > grey default
//ESRE MISMO ES TEXTO DE BOTON DESHABILITADO
//TODO TEXTO DEFAULT DISABLE PARA BOTÓN DESHABILITADO ES NUEVO
// Y ES A3A3A3 > AGREGAR
$grey: #a3a3a3;
$very_dark_grayish_blue: #111827;
$very_light_gray: #c0c1d8;
//TODO REEMPLAZAR VERY LIGHT GRAY POR GREY DISABLE EN TODO EL FRONT
$grey-disable: #d4d4d4;

$green_dark: #289858;
$rojo_400: #f36c70;

//Feedback

//Info
$blue-200: #bfdbfe;
$blue-500: #1d4ed8;
$text-info_blue: #3b8ef6;

//Warning
$orange: $bright_orange;
$yellow-200: #fef08a;
$yellow-900: #713f12;

//Success
$green-200: #bbf7d0;
//TODO REEMPLAZAR VALOR #60cc64 en el front por #22c55e
$green-500: #22c55e;
$green-900: #14532d;

//Error
//TODO REEMPLAZAR VALOR #e52900 POR #ef4444 en eltodo el front
$red: #ef4444;
$red-200: #fecaca;
$red-900: #7f1d1d;

//Background and lines
$blue3: #5572b7;
$white: #ffffff;
$light_grayish_blue: #f5f5fb;
$background-two: #f4f6fa;

//TODO CAMBIO A GREY/DISABLE DISEÑO FONDO DE BOTONES D4D4D4
$chinese_white: #c0c1d8;
$artyclick_sky_blue: #0fbbf1;

// SHADOW -------------------------------------------------

$default-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

//PRIMARY COLORS ------------------------------------

$primary-color-one: $moderate_blue;
$primary-color-two: $light_blue;
//TODO ESTE ES EL COLOR DEL FONDO DE LAS CHIPS Y BACKGROUND-COLOR
//DE LOS CHECKBOXS OUTLINED Y EL FONDO DEL BOTON DE AYUDA
//Y DE LAS TARJETAS DE INDICADORES
//HAY QUE CAMBIAR EL ACTUAL DE ESTOS POR ESTE
$primary-color-three: $tonal;

//SECONDARY COLORS ------------------------------------

$secondary-color-one: $light_purple;
$secondary-color-two: $grayish_blue;
$secondary-color-three: $rose;

//TERTIARY COLORS ------------------------------------

$tertiary-color-one: $bright_orange;
$tertiary-color-two: $very_light_orange;
$tertiary-color-third: $qlik_purple;

//TEXT COLORS ------------------------------------

$text-color-grey: $grey;
//Se dejan ambos, acuerdo con diseño por estado avanzado de proyecto
$text-color-default: $very_dark_grayish_blue;
$text-color-black: $very_dark_grayish_blue;
$text-color-white: $white;
$text-color-disabled: $grey;

//FEEDBACK COLORS ------------------------------------

$info-light: $blue-200;
$info-dark: $blue-500;
$warningColor: $orange;
$warning-light: $yellow-200;
$warning-dark: $yellow-900;
$success-light: $green-200;
$successColor: $green-500;
$success-medium: $green-500;
$success-dark: $green-900;
$error-light: $red-200;
$errorColor: $red;
$error-medium: $red;
$error-dark: $red-900;

//BACKGROUND AND LINE COLORS ------------------------------------

$background-color-main: $background-two;
$background-color: $light_grayish_blue;
$login-background-color: $background_dark;
$lines-color: $very_light_gray;
$white-color: $white;

// OLD VARIABLES ----------------------------------------------
$color-primary: $moderate_blue;
$heading-color: $white;
$subheading-color: $grey;
$text-color: $very_dark_grayish_blue;
$text-successful: $green-500;
$errorColor: $red;
$color-input-placeholder: $white;
$grey-color: $grey;

// State colors
.text-colors {
  &__success {
    color: $successColor;
  }

  &__error {
    color: $errorColor;
  }

  &__error-state {
    color: $red;
  }
  &__sucess-parcial {
    color: $green_dark;
  }

  &__very_dark_grayish_blue {
    color: $very_dark_grayish_blue;
  }

  &__primary-color-one {
    color: $primary-color-one;
  }

  &__primary-color-two {
    color: $primary-color-two;
  }

  &__secondary-color-one {
    color: $secondary-color-one;
  }

  &__secondary-color-two {
    color: $secondary-color-two;
  }

  &__tertiary-color-one {
    color: $tertiary-color-one;
  }

  &__tertiary-color-two {
    color: $tertiary-color-two;
  }

  &__tertiary-color-third {
    color: $tertiary-color-third;
  }

  &__text-color-disabled {
    color: $text-color-disabled;
  }

  &__text-color-fully-disabled {
    color: #c5c7d0;
  }
}

.background-colors {
  &__success {
    background-color: $successColor !important;
  }

  &__error {
    background-color: $errorColor;
  }

  &__very_dark_grayish_blue {
    background-color: $very_dark_grayish_blue;
  }

  &__primary-color-one {
    background-color: $primary-color-one;
  }

  &__primary-color-two {
    background-color: $primary-color-two;
  }

  &__secondary-color-one {
    background-color: $secondary-color-one;
  }

  &__secondary-color-two {
    background-color: $secondary-color-two;
  }

  &__tertiary-color-one {
    background-color: $tertiary-color-one;
  }

  &__tertiary-color-two {
    background-color: $tertiary-color-two;
  }

  &__tertiary-color-third {
    background-color: $tertiary-color-third;
  }
  &__text-color-disabled {
    background-color: $text-color-disabled;
  }
  &__gradient {
    background-color: rgba(161, 137, 249, 0.15) !important;
  }
  &__white {
    background-color: #ffffff;
  }
}
