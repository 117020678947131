@mixin phone {
  @media only screen and (max-width: $media-max-width-sm) {
    @content;
  }
}

@mixin phone-xs {
  @media only screen and (max-width: $media-max-width-xs) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $media-max-width-md) {
    @content;
  }
}

@mixin large {
  @media only screen and (min-width: $media-max-width-lg) {
    @content;
  }
}

@mixin transform($property) {
  -webkit-transform: $property;
  -moz-transform: $property;
  -ms-transform: $property;
  -o-transform: $property;
  transform: $property;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

@mixin sidebar-lg {
  @media only screen and (max-width: $media-max-with-sidebar-lg) {
    @content;
  }
}

@mixin sidebar-xs {
  @media only screen and (max-width: $media-max-with-sidebar-xs) {
    @content;
  }
}

@mixin sidebar-sm {
  @media only screen and (max-width: $media-max-with-sidebar-sm) {
    @content;
  }
}

@mixin sidebar-xl {
  @media only screen and (max-width: $media-max-with-sidebar-xl) {
    @content;
  }
}
