.tooltip {
  &__custom {
  }
  &__informative {
    text-align: left !important;
  }
  &__container {
    padding: 1rem !important;
    width: 3rem !important;
    width: 100% !important;
  }
  &__info {
    width: 100%;
    color: #553ab6;
    background-color: #f1edfe;
    font-size: $font-size-subheading;
    border-radius: 4px;
  }
}

//Detalle a tienda

.store__detail__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include tablet {
    justify-content: center;
  }
  @include phone {
    justify-content: start;
  }
}

.check__container {
  display: flex;
  align-items: center !important;
  @include phone {
    padding: 0.3rem !important;
  }
}

.tooltip__container {
  display: flex;
  max-width: fit-content;
  align-items: center;
  margin-top: 200rem;
  padding: 8px !important;
}

.store__detail__tooltip__one {
  display: flex;
  align-items: center;
  justify-content: center;
}
.store__detail__tooltip__two {
  display: flex;
  align-items: center;
  justify-content: center;
}

.store__detail__text {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $color-primary;
}
