// FONT SIZES DESKTOP ---------------------------------------------------------------

$font-size-x-large: 2.825rem;
$font-size-large: 1.95rem;
$font-size-medium: 1.825rem;
//Original 2rem but reduced to 1.6rem
$font-size-small: 1.45rem;
$font-size-heading: 1.2rem;
$font-size-subheading: 1.325rem;
$font-size-label: 1.2rem;
$font-size-button: 1.075rem;
$font-size-body: 0.95rem;
$font-size-caption: 0.95rem;
$font-size-link: 0.95rem;

// FONT LINE HEIGHTS DESKTOP ---------------------------------------------------------

$line-height-x-large: 2.85rem;
$line-height-large: 2.1rem;
$line-height-medium: 2.1rem;
$line-height-small: 1.85rem;
$line-height-heading: 1.6rem;
$line-height-subheading: 1.1rem;
$line-height-label: 1.6rem;
$line-height-button: 1.35rem;
$line-height-body: 1.35rem;
$line-height-caption: 1.1rem;
$line-height-link: 1.1rem;

// FONT SIZES MOBILE ---------------------------------------------------------------

$font-size-x-large-mobile: 1.688rem;
$font-size-large-mobile: 1.5rem;
$font-size-medium-mobile: 1.313rem;
//Original 2rem but reduced to 1.6rem
$font-size-small-mobile: 1rem;
$font-size-heading-mobile: 1.063rem;
$font-size-subheading-mobile: 0.813em;
$font-size-label-mobile: 1rem;
$font-size-button-mobile: 0.938rem;
$font-size-body-mobile: 0.938rem;
$font-size-caption-mobile: 0.813rem;
$font-size-link-mobile: 0.75rem;

// FONT LINE HEIGHTS MOBILE ---------------------------------------------------------

$line-height-x-large-mobile: 2.25rem;
$line-height-large-mobile: 1.75rem;
$line-height-medium-mobile: 1.75rem;
$line-height-small-mobile: 1.5rem;
$line-height-heading-mobile: 1.5rem;
$line-height-subheading-mobile: 1rem;
$line-height-label-mobile: 1.5rem;
$line-height-button-mobile: 1.25rem;
$line-height-body-mobile: 1.25rem;
$line-height-caption-mobile: 1.25rem;
$line-height-link-mobile: 1rem;

// FONT WEIGHTS --------------------------------------------------------------

$weight-medium: 500;
$weight-regular: 400;
$weight-bold: 700;

// FONT FAMILIES -------------------------------------------------------------

$font-family-text: "Roboto", sans-serif;

// MEDIA SIZE -------------------------------------------------------------

$media-min-width-sm: 576px; // Small devices (landscape phones, 576px and up)
$media-min-width-md: 768px; // Medium devices (tablets, 768px and up)
$media-min-width-lg: 992px; // Large devices (desktops, 992px and up)
$media-min-width-xl: 1200px; // Extra large devices (large desktops, 1200px and up)

$media-max-width-xs: 598px;
$media-max-width-sm: 767.98px; // Small devices (landscape phones, less than 768px)
$media-max-width-md: 991.98px; // Medium devices (tablets, less than 992px)
$media-max-width-lg: 1199.98px; // Large devices (desktops, less than 1200px)

// MEDIA SIZE  SIDEBAR
$media-max-with-sidebar-xl: 1920px;
$media-max-with-sidebar-lg: 1535px;
$media-max-with-sidebar-sm: 768px;
$media-max-with-sidebar-xs: 375px;

// OLD FONT SIZES -------------------------------------------------------------

$font-size-lg: 42px;
$font-size-default: 16px;
$font-size-regular: 20px;
$font-size-md: 28px;
$font-size-lg: 18px;
$font-size-sm: 14px;
$font-size-xs: 12px;
$font-size-xxs: 11px;

$font-size-heading1: 24px;
$font-size-heading3: 1.6rem;
