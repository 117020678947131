.modalEditOptions {
  &__form {
    align-items: left;
    text-align: left;

    @include tablet {
      align-items: center;
      text-align: center;
    }

    &-responsive-box {
      display: flex;
      text-align: left;
    }

    &-responsive--label {
      display: none;

      text-align: left;
      width: 263px;
    }

    &-errorText {
      color: $errorColor;
      text-align: left;
      font-size: $font-size-sm;
    }

    &-title {
      color: $color-primary;
      font-size: $font-size-md !important;
      font-family: $font-family-text;
      font-weight: bolder !important;
      text-align: left;
      padding-top: 1.5rem;
      padding-bottom: 3rem;

      @include tablet {
        text-align: center;
        font-size: 2rem !important;
      }
    }

    &-subtitle {
      color: $grey-color;
      font-size: $font-size-sm;
    }

    &-input {
      transition: all 0.2s !important;
      border: 1px solid $grey-color !important;
      border-radius: 8px !important;
      height: 4rem;
      width: 23rem;

      margin: 1.8rem 4rem 1rem 0 !important;

      @include tablet {
        width: 60rem !important;
        margin: 1.5rem 4rem 1.5rem 0 !important;
      }

      @include phone {
        width: 20rem !important;
      }

      &--error {
        @extend .modalEditOptions__form-input;
        border-color: $errorColor !important;
      }
    }

    &-description {
      transition: all 0.2s !important;
      border: 1px solid $grey-color !important;
      border-radius: 8px !important;
      height: 4rem;
      width: 100%;
      margin: 1.8rem 4rem 1rem 0 !important;
      font-size: $font-size-xs !important;

      @include tablet {
        width: 60rem !important;
        margin: 1.5rem 4rem 1.5rem 0 !important;
      }

      @include phone {
        width: 20rem !important;
      }

      &--error {
        @extend .modalEditOptions__form-description;
        border-color: $errorColor !important;
      }
    }

    &-select {
      @extend .modalCreateOptions__form-input;
      transition: all 0.2s !important;
      border: 1px solid $grey-color !important;
      border-radius: 8px;
      padding-left: 1.5rem;
      margin: 1rem 1rem 1.5rem 0;
      font-size: $font-size-xs !important;

      @include tablet {
        width: 20rem;
        margin: 0.5rem 4rem 1.5rem 0 !important;
      }

      &--error {
        @extend .recoverPassword__form-input;
        @extend .modalEditOptions__form-input;
        border-color: $errorColor !important;
      }
    }

    :disabled {
      background-color: #e6e9ef !important;
      color: black !important;
    }

    &__actions {
      text-align: center !important;
      padding-top: 2rem !important;

      &-buttonFilled {
        width: 15rem;
        height: 3.5rem;
        background: $color-primary !important;
        padding: 8px 24px;
        font-family: $font-family-text;
        color: $white !important;
        font-size: 12px !important;
        font-weight: bold;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.4s;
        border: 1px solid $color-primary !important;
        text-transform: none !important;

        @include tablet {
          width: 20rem;
          margin: 0rem 4rem 1.5rem 0 !important;
        }
      }

      &-buttonOutline {
        width: 15rem;
        height: 3.5rem;
        background: transparent !important;
        padding: 8px 24px;
        font-family: $font-family-text;
        color: $color-primary !important;
        font-size: 14px !important;
        font-weight: bold;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.4s;
        border: 1px solid $color-primary !important;
        margin-right: 1rem !important;
        text-transform: none !important;

        @include tablet {
          width: 20rem;
          margin: 0.5rem 4rem 1.5rem 0 !important;
        }
      }
    }

    .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
      color: rgba(0, 0, 0, 0.6) !important;
    }

    &__inputLabel {
      font-size: 13px !important;
      margin: 5px 0 0 -10px;
      padding: 0 0 0 5px !important;
      color: $grey !important;
    }
  }
}
