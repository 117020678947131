.img {
  &__noInfo {
    width: 65%;
    @include tablet {
      width: 80%;
    }
  }
  &__noInfoDR {
    width: 100%;
    @include tablet {
      width: 80%;
    }
  }
}
