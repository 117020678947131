.validateCredentials {
  &__wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100% !important;
    background: #fff;

    @include tablet {
      display: flex;
      flex-wrap: wrap !important;
      flex-direction: column-reverse;
      justify-content: start !important;
      padding: 4rem 2rem;
    }
    @include phone {
      flex-direction: column-reverse;
      flex-wrap: nowrap !important    ;
      width: 100% !important;
      padding: 2rem 1rem;
    }
  }

  &__leftSide {
    width: 50%;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: space-between;
    padding: 4rem !important;
    background-color: $login-background-color;
    background-image: url("../../assets/common/gridImage.svg");
    background-size: 98% !important;
    @include tablet {
      width: 90% !important;
      height: auto !important;
      padding: 2rem !important;
      margin: 5% !important;
      border-radius: 10px !important;
    }
    @include phone {
      width: 90% !important;
      padding: 1rem !important;
      margin: 0 5% !important;
      border-radius: 10px !important;
    }
    @media (min-width: 1367px) {
      padding: 4rem !important;
    }
    &-instructions {
      color: $heading-color;
      font-size: $font-size-default;
      font-family: $font-family-text;
      text-decoration: none !important;
      line-height: 32px;
      width: 20% !important;
      padding-left: 0px !important;
      padding-top: 0px !important;

      @include tablet {
        padding: 0px;
      }
      &--mainTitle {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 1.5rem;
      }

      &--instructionSet {
        width: 20% !important;
        padding: 2rem 0;
        margin-left: auto !important;
        margin-right: auto !important;
      }
    }

    &-required {
      display: flex;
      align-items: center;
      font-size: 24px;
      color: $bright_orange !important;
      @media (min-width: 1367px) {
        font-size: 28.75px;
      }
    }

    &-check {
      display: flex;
      align-items: center;
      font-size: 24px;
      color: $green-500 !important;
      @media (min-width: 1367px) {
        font-size: 28.75px;
      }
    }
  }

  &__rightSide {
    width: 50%;
    max-height: 100%;
    background: #fff !important;
    display: flex;
    align-items: start !important;
    justify-content: center;
    background-color: $background-color;
    padding: 4rem 2rem !important;

    @include tablet {
      align-self: start !important;
      padding: 5rem 0rem !important;
      width: 90%;
      // height: 50% !important;
      margin: 0% 5%;
      border-bottom-right-radius: 0;
    }
    @include phone {
      width: 95%;
      margin: 0 2.5%;
      padding: 1rem 2rem !important;
      // height: 50%;
    }
    @media (min-width: 1367px) {
      padding: 6rem 4rem !important;
    }
  }

  &__head-container {
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    align-self: start !important;
    width: 100%;
    padding-bottom: 4.8rem !important;
    @include tablet {
      justify-content: space-between !important;
      padding: 0 2rem;
      padding-bottom: 18rem;
    }
    @include phone {
      padding: 0;
      padding-bottom: 3rem;
    }
    @media (min-width: 1367px) {
      padding-bottom: 4.8rem !important;
    }
  }

  &__title {
    text-align: left;
    padding-bottom: 1.5rem !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    @media (min-width: 1367px) {
      padding-bottom: 2.5rem !important;
      font-size: 42px !important;
      line-height: 44px !important;
    }
    @include tablet {
      font-size: 47px !important;
      line-height: 58px;
      padding-bottom: 1.5rem !important;
    }
    @include phone {
      line-height: 32px;
      font-size: 28px !important;
      padding-bottom: 1rem !important;
    }
  }

  &__instructions {
    text-align: left;
    font-size: 16px !important;
    font-weight: 400;
    color: black;
    padding-bottom: 1rem;
    line-height: 19px !important;
    @include tablet {
      line-height: 24px !important;
      font-size: 16px;
      padding-bottom: 1rem;
    }
    @include phone {
      font-size: 14px;
      line-height: 20px !important;

      padding-bottom: 1rem !important;
    }
  }

  &__form {
    padding: 1rem;
    justify-content: center !important;
    //  justify-items: center !important;
    align-items: center;
    justify-self: center !important;
    //text-align: center;
    width: 85% !important;

    @media (min-width: 1367px) {
      width: 65% !important;
    }

    @include tablet {
      width: 80% !important;
    }

    @include phone {
      width: 100% !important;
      padding: 1rem 0 !important;
    }

    &-container {
      width: 100% !important;
      padding-top: 0px !important;
      @include phone {
        padding-left: 0px !important;
        padding-top: 0px !important;
      }
    }
    &-responsive-box {
      display: flex;
      text-align: left;
    }

    &-responsive--label {
      display: none;

      text-align: left;
      width: 263px;
    }

    &-errorText {
      color: $errorColor;
      text-align: left;
      font-size: $font-size-sm;
      padding: 0 1.5rem;
      @include tablet {
        padding: 0 !important;
      }
    }

    &-input {
      &:not(:last-child) {
        margin-bottom: 1.5rem !important;
      }

      text-align: left;
      align-content: center;
      transition: all 0.2s !important;
      background-color: white !important;
      padding: 2.5rem 0.5rem !important;
      height: 42px !important;
      width: 95% !important;
      border: 1.5px solid $grey !important;
      border-radius: 8px !important;
      margin: 1.8rem 0 0.5rem 0 !important;
      font-size: $font-size-sm !important;
      width: 100% !important;
      &:focus {
        border: 1px solid $color-primary !important;
      }
      @include tablet {
        width: 100% !important;
        padding: 2.6rem 0.5rem !important;
      }
      @include phone {
        width: 100% !important;
        margin: 2rem 0 0rem 0 !important;
        padding: 0rem 0.2rem !important;
        margin: 1.8rem 0 0.5rem 1 !important;
      }
      @media (min-width: 1920px) {
        height: 45px;
        width: 90%;
        margin: 1.8rem 0 0rem 0 !important;
        padding-bottom: 3rem !important;
      }

      &--error {
        @extend .login__form-input;
        border-color: $errorColor !important;
      }
      &--focus {
        @extend .login__form-input;
        border-color: #553ab6 !important;
      }
    }

    &-button {
      width: 65%;
      height: 48px !important;
      background: $color-primary;
      padding: 8px 24px;
      font-family: $font-family-text;
      color: white;
      font-size: 14px;
      font-weight: bold;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.4s;
      border: 2px solid transparent;

      @include tablet {
        width: 65% !important;
      }

      @include phone {
        width: 100% !important;
      }
      &--disabled {
        @extend .validateCredentials__form-button;
        background: $grey-disable !important;
        color: $grey !important;
        cursor: default;
      }
    }

    &-backText {
      font-family: $font-family-text;
      font-weight: $weight-bold;
      line-height: $line-height-small;
      font-size: $font-size-default !important;
      color: $text-info_blue !important;
      @include tablet {
        font-size: $font-size-sm;
      }
    }
  }
}
.recaptcha_container_support > div > div {
  padding-top: 0rem;
  padding-left: 8rem;
  @include phone {
    padding-left: 71px;
  }
}

.recaptcha_container > div > div {
  padding-top: 2rem;

  @include phone {
    padding-left: 0rem;
  }
}

.inner-container {
  justify-content: flex-start;
  align-items: flex-start;
  width: 100% !important;

  @include phone {
    padding-left: 0%;
    justify-content: center !important;
    align-items: center !important;
  }
}

.captcha-container {
  justify-content: center;
  align-items: flex-start;

  &__captcha-only {
    @include phone {
      justify-content: center !important;
      align-items: center !important;
      padding-left: 5rem;
      left: 30% !important;
    }
  }
}

.wrap-text {
  @include phone {
    font-size: 11.5px !important;
    padding-left: 15%;
    padding-right: 10%;
  }

  &__large {
    @include large {
      padding-left: 15%;
      padding-right: 10%;
    }
  }
}

.instructions-container {
  @include phone {
    flex-direction: column !important;
  }
  &__column {
    width: 50% !important;

    @include phone {
      width: 100% !important;
    }
  }
}

.newPasswordImage {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  margin-top: 1.5rem;
  height: 30% !important;

  &_title {
    @extend .heading-secondary;
    text-align: center;
    color: white;
    padding-bottom: 1.5rem !important;
    font-size: 23px;
    font-weight: 700;
    @media (min-width: 1920px) {
      font-size: 36px;
    }
  }

  &_title-container {
    @extend .subHeading-primary;
    text-align: center;
    width: 30rem;
    @media (min-width: 1920px) {
      width: 50rem;
    }
  }

  &_instruction {
    @extend .default-text_black__bold;
    margin-top: 1.5rem;
  }

  &_img {
    height: 454px !important;
    // height: 20% !important;
    z-index: 2;
    @include tablet {
      width: 65%;
    }

    @include tablet {
      display: none;
    }
    @media (min-width: 1367px) {
      height: 562px !important;
    }

    // @media (max-height: 768px) {
    //   height: 400px !important;
    // }
  }

  &_img-container {
    display: flex;
    max-height: 60% !important;
    width: 100%;

    @include tablet {
      display: none !important;
    }
  }

  &_pattern {
    position: absolute !important;
    top: 51.5%;
    right: 78%;
    z-index: 1;
    width: 17%;
    @media (min-width: 1920px) {
      top: 50%;
      right: 76.5%;
    }
  }
}

.instruction-text {
  font-family: $font-family-text;
  font-weight: $weight-regular;
  font-size: $font-size-default;
  line-height: 24px;
  color: white;
  padding: 4.5px 0 !important;
  @media (min-width: 1367px) {
    font-size: $font-size-large !important;
    line-height: 32px;
    padding: 4px 0 !important;
  }
  @include tablet {
    padding: 1rem 0 !important;
    font-size: $font-size-default;
    margin: 0px !important;
    line-height: 24px;
  }
  &__title {
    @extend .instruction-text;
    font-weight: $weight-bold !important;
    line-height: 32px !important;
    text-align: center !important;
    padding: 2rem 0px !important;
    @include tablet {
      padding: 1rem !important;
    }
    @include phone {
      text-align: left !important;
    }
    @media (min-width: 1367px) {
      font-size: $font-size-large !important;
    }
  }

  &__container {
    display: flex;
    padding: 0 !important;
  }
}

.back-containerCredentials {
  padding-bottom: 3rem !important;

  @include phone {
    margin-top: 5% !important;
    margin-bottom: 2% !important;
    padding-bottom: 1rem !important;
  }
}
