.card-text-align-right {
  text-align: right;
}

.doc-num {
  color: #553ab6;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}
.text-doc-num {
  color: #1f2937;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.box-content {
  text-align: center;
  &__doughnut {
    @extend .box-content;
    width: 70%;
    height: 80%;
  }

  &__bar {
    @extend .box-content;
    width: 100%;
    height: 100%;
  }

  @include tablet {
    &__doughnut {
      width: 35%;
    }
  }

  @include phone {
    &__doughnut {
      width: 85%;
    }
  }
}

.card-content {
  display: flex;
  justify-content: center;

  @include tablet {
    flex-direction: column;
    align-items: center;
  }
}

.table-items {
  &__center {
    text-align: center !important;
  }
}

.box-container {
  width: 100%;
  height: 100%;
  padding-bottom: 5%;
}

.body-dataTable {
  padding: 2%;
  border-radius: 24px;
}

.custom-accordion-summary {
  flex-direction: row-reverse;
  padding: 0px;
  &__icon {
    color: $color-primary;
  }

  &__dates {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: fit-content;
  }
}

.custom-accordion-detail {
  border: none !important;
  background-color: white !important;
  padding: 0 !important;
  text-align: center;

  &__hidden {
    display: none;
  }
}

.button-charts,
.button-transmission {
  border-radius: 8px !important;
  color: $white !important;
  font-weight: 500 !important;
  text-transform: none !important;
  font-size: $font-size-xxs !important;
}

.button-charts {
  background-color: $tertiary-color-third !important;
}

.button-transmission {
  background-color: $color-primary !important;
}

.button-transmission-adminCompanies {
  margin-top: 30px !important;
  margin-bottom: 18px !important;
}

.audit-transmission {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5% 0;

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
  }

  .div-transmission {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 70%;

    @include tablet {
      width: 100%;
    }

    @include phone {
      flex-direction: column;
      gap: 10px;

      .button-transmission {
        width: 100%;
      }
    }
  }

  .div-transmission-right {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 70%;

    @include tablet {
      width: 100%;
    }

    @include phone {
      flex-direction: column;
      gap: 10px;

      .button-transmission {
        width: 100%;
      }
    }
  }

  .div-transmission-center {
    display: flex;
    justify-content: space-around;
    align-items: center;

    @include tablet {
      width: 100%;
    }

    @include phone {
      flex-direction: column;
      gap: 10px;

      .button-transmission {
        width: 100%;
      }
    }
  }
}

// .audit-transmission p {
//   font-weight: 500;
//   font-size: $font-size-default;
// }

// Contenedor de las cards
.css-4zu06q-MuiGrid-root,
.css-11lxg2u {
  width: 100%;
}

// .css-1osj8n2-MuiGrid-root {
//   width: 100%;
// }

// Colores para el kpi de las gráficas de barras
.error {
  color: $errorColor;
}

.success {
  color: $text-successful;
}

// CoachMark
.react-joyride__tooltip button {
  background-color: transparent !important;
  border-radius: 0px !important;
  padding: 0px !important;
  font-weight: $weight-medium;
  text-transform: capitalize;
}

.react-joyride__tooltip .button-last,
.react-joyride__tooltip .button-back,
.react-joyride__tooltip .button-next {
  border-radius: 8px !important;
  padding: 8px 16px !important;
  font-size: $font-size-xxs;
}

.react-joyride__tooltip .button-last,
.react-joyride__tooltip .button-next {
  background-color: $color-primary !important;
  color: $white-color !important;
}

.react-joyride__tooltip .button-back {
  color: $color-primary !important;
  border: 1px solid $color-primary !important;
}

.react-joyride__tooltip .button-skip {
  color: $orange !important;
  font-size: $font-size-sm;
}

.container-joyride {
  padding-bottom: 5%;
  border-bottom: 1px solid $very_light_gray;
}

.steps-joyride,
.parragraph-joyride {
  font-weight: $weight-regular;
}

.steps-joyride,
.title-joyride {
  text-align: left;
  color: $color-primary;
}

.title-joyride {
  font-weight: $weight-bold;
  font-size: $font-size-lg;
}

.steps-joyride {
  font-size: $font-size-xs;
}

.parragraph-joyride {
  font-size: $font-size-sm;
  text-align: justify;
}

// Responsive design
.card-text-align-center {
  @include tablet {
    text-align: center;
  }
}

.tblFiscalCEN {
  .tss-1h9t3sl-MUIDataTableHeadCell-sortAction,
  .tss-1cn2w2t-MUIDataTableBodyCell-root {
    font-size: 14px !important;
  }
}

.card-docs {
  height: 95px !important;
}

.donut-height {
  height: 250px;
}

.donut-container {
  padding-top: 16px;
}

.header-transmission {
  display: flex;
  justify-content: space-between;
}

.home-title {
  font-size: 20px !important;
  @include phone {
    font-size: 18px !important;
  }
}
.home-text {
  padding: 0 2rem;
  font-size: 16px !important;
  @include tablet {
    padding: 1rem 1rem 1rem 0;
  }
  @include phone {
    font-size: 14px !important;
  }
}

.filters-header {
  gap: 3rem !important;
  margin-top: 20px !important;

  @media (min-width: 1367px) {
    gap: 4rem !important;
    margin-top: 20px !important;
  }

  @include tablet {
    padding-bottom: 2rem;
  }

  @include phone {
    padding-bottom: 0rem;
  }

  &__button-container {
    margin-left: 20px !important;

    @include tablet {
      margin-left: 0px !important;
    }
  }
}

.doughnut-total-text {
  width: 15 !important;
  height: 10 !important;
  font-size: $font-size-sm;
  font-weight: 500;
  text-align: left !important;
  color: $very_dark_grayish_blue !important;

  @include phone {
    font-size: $font-size-sm;
  }

  &__container {
    margin-top: -0.5rem !important;
    margin-bottom: 1rem !important;
  }
}
