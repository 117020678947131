.react-datepicker {
  border: none !important;
  font-family: $font-family-text !important;
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  text-align: center !important;
}
.react-datepicker__month-container {
  font-size: 16px !important;

  width: 30rem !important;
}

.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker__day--selected {
  background-color: #ffa836 !important;
  border-radius: 12px !important;
  color: white !important;
}
.react-datepicker__day {
  color: $color-primary !important;

  margin-left: 1rem !important;
  margin-right: 1rem !important;
  margin-top: 1rem !important;
  height: 2rem !important;
  width: 2rem !important;
}
.react-datepicker__day--disabled {
  color: #ccc !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #ffa836 !important;

  border-radius: 5px !important;
  color: white !important;
}
.react-datepicker__day-name {
  margin: 1rem !important;
  color: #5c40ff !important;
}
.react-datepicker__current-month {
  font-size: 16px !important;
  color: #543ab4 !important;
  margin-bottom: 2rem !important;
}
.react-datepicker__header {
  background-color: white !important;
  border-bottom: none !important;
}

.react-datepicker__navigation-icon::before {
  color: #ffa836 !important;

  border-color: #ffa836 !important;
}
.dayOfMonth {
  font-size: 11px;
  color: $color-primary;

  &__selected {
    font-size: 11px;
    color: white !important;
  }
}
.grid_day {
  :hover {
    background-color: #ffa836;
    color: white;
    border-radius: 6px;
    cursor: pointer;
  }

  &__selected {
    background-color: #ffa836;
    color: white !important;
    border-radius: 17px;
  }
}
.calendar__container {
  box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
